import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "unit";

export const getUnits = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_UNITS",
      payload: result,
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const addUnit = async (token, dispatch, unit) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, body: unit, token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Data submited successfully!",
        act: "expeSubmited",
      },
    });
    getUnits(token, dispatch);
  }
  dispatch({ type: "END_LOADING" });
  if (result === 1) return result;
};
