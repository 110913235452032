import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";

function AdmittedStudents({
  students,
  columns,
  loading,
  GridToolbar,
  studentViewPage,
}) {
  return (
    <DataGrid
      rows={students}
      columns={columns}
      loading={loading}
      getRowId={(row) => row.studentId}
      components={{ Toolbar: GridToolbar }}
      onRowClick={(rows) => studentViewPage(rows.id)}
      InputProps={{
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="secondary" size={20} /> : null}
          </React.Fragment>
        ),
      }}
    />
  );
}

export default AdmittedStudents;
