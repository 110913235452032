import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  Button,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useValue } from "../../../../context/contextProvider";
import moment from "moment/moment";
import { Send } from "@mui/icons-material";
import { addStudent } from "../../../../actions/student";
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../../../progress/backdrop";

export default function Confirmation() {
  const navigate = useNavigate();
  const {
    state: {
      studentsRegistrationForm: { personalInfo, courseInfo },

      currentUser,
      loading,
    },
    dispatch,
  } = useValue();
  const {
    fullNames,
    mobileNo,
    email,
    gender,
    nationality,
    maritalStatus,
    idNo,
    DOB,
    guardian,
    relationShip,
    tel,
  } = personalInfo;
  const {
    courseCode,
    courseName,
    courseLevel,
    classTitle,
    sponsorship,
    nameOfSponsor,
    telOfSponsor,
    modeOfStudy,
  } = courseInfo;
  const personalInfoData = [
    { field: "Full Name", value: fullNames },
    { field: "ID/PassPort", value: idNo },
    { field: "Gender", value: gender },
    { field: "Date of Birth", value: moment(DOB).format("DD-MM-YYYY") },
    { field: "Nationality", value: nationality },
    { field: "Mobile number", value: mobileNo },
    { field: "Email", value: email || "N/A" },
    { field: "Marital status", value: maritalStatus },
    { field: "Guardian/Kin name", value: guardian },
    { field: "Relationship", value: relationShip },
    { field: "Telephone", value: tel },
  ];
  const tempCourseInfoData =
    sponsorship === "self"
      ? [{ field: "Sponsorship", value: sponsorship }]
      : [
          { field: "Sponsorship", value: sponsorship },
          { field: "Name of Sponsor", value: nameOfSponsor },
          { field: "Telephone", value: telOfSponsor },
        ];
  const courseInfoData = [
    { field: "Course Name", value: courseName },
    { field: "Course Code", value: courseCode },
    { field: "Course Level", value: courseLevel },
    { field: "Intake Class", value: classTitle },
    ...tempCourseInfoData,
    { field: "Mode of Study", value: modeOfStudy },
  ];

  const updateConfirmDialog = (act) => {
    const message =
      act === "clear"
        ? "You are about to clear the form. Are you sure you want to proceed?"
        : "You are about to register this student. Are you sure you want to proceed?";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: "Student Registration",
        message: message,
        action: act,
        func: handleConfirmedAction,
      },
    });
  };
  const handleConfirmedAction = async (act) => {
    const student = { ...personalInfo, ...courseInfo };
    if (act === "submit") await addStudent(currentUser.token, student, dispatch, navigate);
    if (act === "clear")
      dispatch({
        type: "RESET_STUDENT_FORM",
        payload: 0,
      });
    return;
  };

  return (
    <Box sx={{ maxHeight: "600px", overflow: "auto" }}>
      {loading && <SimpleBackdrop />}
      <Paper elevation={5} sx={{ p: 2, background: "inherit", m: "10px 90px" }}>
        <List>
          <Typography fontWeight="Bold" variant="h5">
            PERSONAL INFORMATION
          </Typography>
          {personalInfoData.map((data, i) => (
            <Box key={data.field}>
              <ListItem>
                <ListItemText
                  primary={data.field}
                  secondary={data.value}
                  sx={{
                    textTransform:
                      data.field !== "Email" ? "capitalize" : undefined,
                  }}
                />
              </ListItem>
              {i !== personalInfoData.length - 1 && (
                <Divider variant="middle" />
              )}
            </Box>
          ))}
        </List>
      </Paper>
      <Paper elevation={5} sx={{ p: 2, background: "inherit", m: "10px 90px" }}>
        <List>
          <Typography fontWeight="Bold" variant="h5">
            COURSE INFORMATION
          </Typography>
          {courseInfoData.map((data, i) => (
            <Box key={data.field}>
              <ListItem>
                <ListItemText
                  primary={data.field}
                  secondary={data.value}
                  sx={{
                    textTransform:
                      data.field !== "Course Code" ? "capitalize" : undefined,
                  }}
                />
              </ListItem>
              {i !== courseInfoData.length - 1 && <Divider variant="middle" />}
            </Box>
          ))}
        </List>
      </Paper>
      {/* <Paper elevation={5} sx={{ p: 2, background: "inherit", m: "10px 90px" }}>
        <List>
          <Typography fontWeight="Bold" variant="h5">
            UPLOADS
          </Typography>
          <Box>
            <ListItem>
              <ListItemText
                primary="Uploaded Certificate"
                secondary={attachedCert ? attachedCert.name : "No uploads"}
              />
            </ListItem>
          </Box>
        </List>
      </Paper> */}
      <Box
        display="flex"
        justifyContent="space-between"
        m="20px 100px 50px 100px">
        <Button
          title="Clear the form"
          color="warning"
          variant="contained"
          onClick={() => updateConfirmDialog("clear")}>
          RESET FORM
        </Button>{" "}
        <Button
          title="Submit the form"
          type="submit"
          color="primary"
          variant="contained"
          endIcon={<Send />}
          onClick={() => updateConfirmDialog("submit")}>
          SUBMIT
        </Button>
      </Box>
    </Box>
  );
}
