// import * as React from "react";
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import { Box } from "@mui/material";
// import { tokens } from "../../theme";
// import Header from "../../components/Header";
// import { useTheme } from "@mui/material";
// import InboxList from "../../components/emails/inbox";
// import SentList from "../../components/emails/sent";
// import DraftList from "../../components/emails/draft";
// // import React, { useCallback } from "react";
// // import { useNavigate } from "react-router-dom";

// //   const navigate = useNavigate();
// //   const studentViewPage = useCallback(
// //     () => navigate("/studentView", { replace: true }),
// //     [navigate]
// //   );

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     "aria-controls": `vertical-tabpanel-${index}`,
//   };
// }

// export default function Emails() {
//   const [value, setValue] = React.useState(0);
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <Box m="20px">
//       <Header title="MAIL BOX" subtitle="Manage All Emails" />

//       <Box
//         sx={{
//           flexGrow: 1,
//           bgcolor: colors.primary[400],
//           display: "flex",
//           height: 524,
//         }}
//       >
//         <Tabs
//           orientation="vertical"
//           variant="scrollable"
//           value={value}
//           onChange={handleChange}
//           aria-label="Vertical tabs example"
//           sx={{ borderRight: 1, borderColor: "divider", color: "white" }}
//         >
//           <Tab label="Inbox" {...a11yProps(0)} />
//           <Tab label="Sent" {...a11yProps(1)} />
//           <Tab label="Draft" {...a11yProps(2)} />
//         </Tabs>
//         <TabPanel value={value} index={0}>
//           Inbox
//           <InboxList />
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//           Sent
//           <SentList />
//         </TabPanel>
//         <TabPanel value={value} index={2}>
//           Draft
//           <DraftList />
//         </TabPanel>
//       </Box>
//     </Box>
//   );
// }
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function Asynchronous() {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...topFilms]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Asynchronous"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topFilms = [{}];
