import React from "react";
import { Box, useTheme, Typography, Paper, Stack } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CourseList from "../../components/course/courseList";
import AddCourseForm from "../../components/course/addCourseForm";
import LinearColor from "../../components/progress";
import { useValue } from "../../context/contextProvider";
import { useCallback } from "react";
import { useEffect } from "react";
import { getCourses } from "../../actions/course";
import { useState } from "react";

const Course = () => {
  const {
    state: { currentUser, courses, loading },
    dispatch,
  } = useValue();
  const [fetched, setFetched] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const fetchCourses = useCallback(async () => {
    if (currentUser && courses.length === 0 && !fetched) {
      await getCourses(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [currentUser, dispatch, courses, fetched]);
  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);
  return (
    <Box m="20px">
      {/* HEADER */}

      <Header title="COURSE" subtitle="Add & View Course" />

      <Box
        display="grid"
        gap="20px"
        px={4}
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="180px">
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 4",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "630px",
            p: 1,
          }}>
          {" "}
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
              Course Form
            </Typography>
          </Stack>{" "}
          <Box backgroundColor={colors.primary[400]} height="600px">
            <AddCourseForm />
          </Box>
        </Paper>
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 8",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "630px",
            p: 1,
          }}>
          {" "}
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
              List Of Courses
            </Typography>
          </Stack>{" "}
          <Box backgroundColor={colors.primary[400]}>
            {loading && <LinearColor />}
            <CourseList courses={courses} loading={loading} />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Course;
