import * as React from "react";
import { Box, useTheme, Typography, Paper, Stack } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import ExpendHistoryList from "../../components/expenditure/expendHistoryList";
import RecordExpForm from "../../components/expenditure/recordExpForm";
import LinearColor from "../../components/progress";
import { useValue } from "../../context/contextProvider";
import { useEffect } from "react";
import { useCallback } from "react";
import { getExpenditures } from "../../actions/expenditure";
import { useState } from "react";

const Expenditure = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [fetched, setFetched] = useState(false);
  const {
    state: { currentUser, loading, expenditures },
    dispatch,
  } = useValue();
  const fetchExpenditures = useCallback(async () => {
    if (currentUser && expenditures.length === 0 && !fetched) {
      await getExpenditures(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [currentUser, expenditures, dispatch, fetched]);

  useEffect(() => {
    fetchExpenditures();
  }, [fetchExpenditures]);

  return (
    <Box m="20px">
      {/* HEADER */}

      <Header title="EXPENDITURE" subtitle="Record & Manage Expenditures" />

      <Box
        mx={4}
        display="grid"
        gap="20px"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="180px">
        <Paper
          // elevation={4}
          sx={{
            gridColumn: "span 4",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "650px",
            p: 1,
          }}>
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
               Expenditure Form
            </Typography>
          </Stack>

          <RecordExpForm />
        </Paper>
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 8",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "630px",
            p: 1,
          }}>
          {" "}
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
              List Of Recorded Expenditure
            </Typography>
          </Stack>
          <Box backgroundColor={colors.primary[400]} maxHeight="630px">
            {loading && <LinearColor />}
            <ExpendHistoryList
              otherExpenditures={expenditures}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Expenditure;
