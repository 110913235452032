import * as React from "react";
import {
  Box,
  useTheme,
  Typography,
  Button,
  AccordionActions,
  Badge,
  Stack,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useState } from "react";
import AddClassesForm from "./classes/addClassForm";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { useNavigate } from "react-router-dom";
import { useValue } from "../../context/contextProvider";
import { useEffect } from "react";
import { useCallback } from "react";
import { getIntakeClasses } from "../../actions/intakeClass";
import { getIntakeClassSesCourses } from "../../actions/intakeClassSession";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ClassessPage = () => {
  const {
    state: { currentUser, intakeClasses, loading, intakeClassSession },
    dispatch,
  } = useValue();
  const [expanded, setExpanded] = useState(false);
  const [fetched, setFetched] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [chipData, setChipData] = React.useState([]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleClick = (data) => {
    alert("You clicked the Chip.");
  };
  const fetchIntakeClassSession = async (cid) => {
    if (currentUser !== null && currentUser.token) {
      // setLoading(true);
      const result = await getIntakeClassSesCourses(
        cid,
        dispatch,
        currentUser.token
      );
      // setLoading(false);
      return result;
    }
  };
  const handleChange = (panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      const intakeClassSes = intakeClassSession.filter(
        (ints) => ints.classId === panel
      );
      if (!intakeClassSes.length) {
        const result = await fetchIntakeClassSession(panel);
        setChipData([...result]);
      } else {
        setChipData(intakeClassSes);
      }
    }
  };
  const fetchIntakeClasses = useCallback(async () => {
    if (currentUser && intakeClasses.length === 0 && !fetched) {
      await getIntakeClasses(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [currentUser, intakeClasses, dispatch, fetched]);

  useEffect(() => {
    fetchIntakeClasses();
  }, [fetchIntakeClasses]);
  return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="CLASSES" subtitle="Add & View Class" />

      <Box
        display="grid"
        gap="20px"
        px={4}
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="180px">
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 4",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "450px",
            p: 1,
          }}>
          {" "}
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
              Intake Class Form
            </Typography>
          </Stack>{" "}
          <Box backgroundColor={colors.primary[400]}>
            <AddClassesForm />
          </Box>
        </Paper>
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 8",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "630px",
            p: 1,
          }}>
          {" "}
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
              List Of Classes
            </Typography>
          </Stack>{" "}
          <Box backgroundColor={colors.primary[400]} height="570px" py={4}>
            <Box
              width="100%"
              overflow="auto"
              maxHeight="500px"
              md="100px"
              // sx={{
              //   "&:: -webkit-scrollbar": {
              //     display: "none",
              //   },
              // }}
            >
              {intakeClasses.length
                ? intakeClasses.map((dt) => {
                    return (
                      <Box
                        key={dt.id}
                        sx={{
                          pl: "60px",
                          pr: "60px",
                          // "& :: -webkit-scrollbar": {
                          //   display: "none",
                          // },
                        }}>
                        <Accordion
                          expanded={expanded === dt.id}
                          onChange={handleChange(dt.id)}
                          sx={{ bgcolor: colors.primary[400] }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Box pl="15px" pr="15px">
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                color={colors.blueAccent[700]}>
                                Title
                              </Typography>

                              <Typography variant="h6" color={colors.grey[200]}>
                                {dt.classTitle}
                              </Typography>
                            </Box>
                            <Box pr="15px">
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                color={colors.blueAccent[700]}>
                                Reporting Date
                              </Typography>
                              <Box display="flex" gap="10px">
                                <Typography
                                  variant="h6"
                                  color={colors.grey[200]}>
                                  {dt.reportingDate}
                                </Typography>
                                <ModeEditOutlineOutlinedIcon
                                  fontSize="small"
                                  color="secondary"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            List Of Courses{" "}
                            <Badge
                              sx={{ ml: 2, mb: 2 }}
                              color="info"
                              badgeContent={chipData.length}
                              max={99}
                            />
                            <Paper
                              elevation={2}
                              sx={{
                                bgcolor: colors.primary[400],
                                display: "flex",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                listStyle: "none",
                                p: 2,
                                m: 0,
                              }}
                              component="ul">
                              {chipData.map((data) => {
                                let icon;

                                if (data.courseName === "React") {
                                  icon = <TagFacesIcon />;
                                }

                                return (
                                  <ListItem key={data.key}>
                                    <Chip
                                      icon={icon}
                                      label={data.courseName}
                                      onClick={() => handleClick(data)}
                                      onDelete={
                                        data.courseName === "React"
                                          ? undefined
                                          : handleDelete(data)
                                      }
                                    />
                                  </ListItem>
                                );
                              })}
                            </Paper>
                          </AccordionDetails>
                          <AccordionActions>
                            {" "}
                            <Button
                              onClick={() => navigate("/class/" + dt.id)}
                              sx={{
                                ml: "1px",

                                fontWeight: "bold",
                                color: colors.greenAccent[500],
                                "&:hover": {
                                  backgroundColor: colors.blueAccent[500],
                                },
                              }}>
                              Manage Class
                            </Button>
                          </AccordionActions>
                        </Accordion>
                        <br />
                      </Box>
                    );
                  })
                : !loading
                ? "No Record Found"
                : ""}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ClassessPage;
