import * as React from "react";
import { Box, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import { useValue } from "../../context/contextProvider";
import { updateUserPsd } from "../../actions/user";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 410,
  height: 460,
  border: "1px solid #0ba2de",
  boxShadow: 14,
  pl: 3,
  borderRadius: "3px",
  overflow: "auto",
};

export default function ProfileModal() {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    state: { currentUser, loading, alert },
    dispatch,
  } = useValue();

  const checkoutSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .required("Please enter your old password.")
      .min(6, "Your password is too short."),
    newPassword: yup
      .string()
      .required("Please enter your new password.")
      .min(6, "Your password is too short."),
    confirmPassword: yup
      .string()
      .required("Please confirm your password.")
      .oneOf([yup.ref("newPassword")], "Your passwords do not match."),
  });

  const handleFormSubmit = async (values) => {
    await updateUserPsd(currentUser, values, dispatch);
  };
  useEffect(() => {
    if (
      (!currentUser || alert.message === "Password changed successfully") &&
      open
    )
      handleClose();
  }, [currentUser, alert, open]);
  return (
    <Box alignItems="center" justifyContent="center">
      <Button
        onClick={handleOpen}
        sx={{
          ml: 2,
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          "&:hover": {
            borderBottom: 1,
            borderColor: "red",
            backgroundColor: colors.greenAccent[500],
          },
        }}>
        CHANGE PASSWORD
      </Button>
      <Modal
        open={open && currentUser}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <Box sx={style} bgcolor={colors.primary[400]}>
          <Button
            onClick={handleClose}
            sx={{
              color: colors.grey[100],
              position: "absolute",
              fontSize: 20,
              fontWeight: "bold",
              top: 3,
              right: 0,
              "&:hover": {
                color: colors.greenAccent[500],
              },
            }}>
            <CloseOutlinedIcon />
          </Button>

          <Typography
            textAlign="center"
            fontSize="20px"
            fontWeight="bold"
            sx={{ color: colors.blueAccent[700], mt: 5, mb: 2 }}>
            CHANGE PASSWORD
          </Typography>

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}>
            {({
              resetForm,
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="25px"
                  pl={3}
                  // justifyContent="center"
                  gridTemplateColumns="repeat(12, 1fr)"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Old Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.oldPassword}
                    name="oldPassword"
                    error={!!touched.oldPassword && !!errors.oldPassword}
                    helperText={touched.oldPassword && errors.oldPassword}
                    sx={{
                      gridColumn: "span 10",
                      "& .Mui-focused": {
                        color: "#f2f0f0 !important",
                        input: {
                          color: "#f2f0f0 !important",
                        },
                      },
                      "& .Mui-focused.Mui-error": {
                        color: "#f44336 !important",
                      },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                        {
                          borderBottom: "2px solid #0ba2de !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after":
                        {
                          borderBottom: "2px solid #f5079e !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                        {
                          borderBottom: "#f44336 !important",
                        },
                    }}
                  />

                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="filled"
                    type="password"
                    label="New Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword}
                    name="newPassword"
                    error={!!touched.newPassword && !!errors.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    sx={{
                      gridColumn: "span 10",
                      //  gridRow:"span 2",
                      "& .Mui-focused": {
                        color: "#f2f0f0 !important",
                        input: {
                          color: "#f2f0f0 !important",
                        },
                      },
                      "& .Mui-focused.Mui-error": {
                        color: "#f44336 !important",
                      },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                        {
                          borderBottom: "2px solid #0ba2de !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after":
                        {
                          borderBottom: "2px solid #f5079e !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                        {
                          borderBottom: "#f44336 !important",
                        },
                    }}
                  />
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Confirm Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    error={
                      !!touched.confirmPassword && !!errors.confirmPassword
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    sx={{
                      gridColumn: "span 10",
                      //  gridRow:"span 2",
                      "& .Mui-focused": {
                        color: "#f2f0f0 !important",
                        input: {
                          color: "#f2f0f0 !important",
                        },
                      },
                      "& .Mui-focused.Mui-error": {
                        color: "#f44336 !important",
                      },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                        {
                          borderBottom: "2px solid #0ba2de !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after":
                        {
                          borderBottom: "2px solid #f5079e !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                        {
                          borderBottom: "#f44336 !important",
                        },
                    }}
                  />
                </Box>
                <LoadingButton
                  type="submit"
                  loading={loading}
                  color="secondary"
                  sx={{ ml: 3, mt: 3 }}
                  loadingPosition="start"
                  startIcon=""
                  // loadingIndicator="Loading…"
                  variant="contained">
                  <span>SUBMIT</span>
                </LoadingButton>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
}
const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};
