import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "department";

export const getDepartments = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  dispatch({ type: "END_LOADING" });
  if (result) {
    dispatch({
      type: "UPDATE_DEPARTMENTS",
      payload: result,
    });
    return result;
  }
};
export const addDepartment = async (token, dispatch, department) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, body: department, token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Data submited successfully!",
        act: "expeSubmited",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
  if (result.id) return result;
};
export const deleteDepartment = async (token, dispatch, id) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${id}`, method: "DELETE", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Department deleted successfully!",
        act: "expeSubmited",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
  if (result === 1) return result;
};
export const updateDepartment = async (token, dispatch, department) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    {
      url: `${url}/${department.id}`,
      method: "PUT",
      body: department,
      token: token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Department updated successfully!",
        act: "expeSubmited",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
  if (result.id) return result;
};
