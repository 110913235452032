import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  Button,
  ListItemText,
  Paper,
  Typography,
  Stack,
} from "@mui/material";
import { useValue } from "../../../../context/contextProvider";
import moment from "moment/moment";
import { Send } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../../../progress/backdrop";
import { addStaff } from "../../../../actions/staff";

export default function Confirmation() {
  const navigate = useNavigate();
  const {
    state: {
      staffsRegistrationForm: { personalInfo, otherInfo },

      currentUser,
      loading,
    },
    dispatch,
  } = useValue();
  const {
    fullName,
    phone,
    email,
    sgender,
    snationality,
    maritalStatus,
    idNumber,
    DOB,
    county,
    location,
    placeOfBirth,
  } = personalInfo;
  const { fieldName, nameTitle, category } = otherInfo;
  const personalInfoData = [
    { field: "Full Name", value: fullName },
    { field: "ID/PassPort", value: idNumber },
    { field: "Gender", value: sgender },
    { field: "Date of Birth", value: moment(DOB).format("DD-MM-YYYY") },
    { field: "Place Of Birth", value: placeOfBirth },
    { field: "County", value: county },
    { field: "Location", value: location },
    { field: "Nationality", value: snationality },
    { field: "Mobile number", value: phone },
    { field: "Email", value: email || "N/A" },
    { field: "Marital status", value: maritalStatus },
  ];
  const tempOtherInfoData =
    category === "teacher" ? { field: "Field", value: fieldName } : "";
  const otherInfoData = [
    { field: "Designation", value: nameTitle },
    { field: "Category", value: category },

    tempOtherInfoData,
  ];

  const updateConfirmDialog = (act) => {
    const message =
      act === "clear"
        ? "You are about to clear the form. Are you sure you want to proceed?"
        : "You are about to register this staff. Are you sure you want to proceed?";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: "Staff Registration",
        message: message,
        action: act,
        func: handleConfirmedAction,
      },
    });
  };
  const handleConfirmedAction = async (act) => {
    const staff = { ...personalInfo, ...otherInfo };
    if (act === "submit") await addStaff(currentUser.token, staff, dispatch, navigate);
    if (act === "clear")
      dispatch({
        type: "RESET_STAFF_FORM",
        payload: 0,
      });
    return;
  };

  return (
    <Box sx={{ maxHeight: "600px", overflow: "auto" }}>
      {loading && <SimpleBackdrop />}
      <Paper elevation={5} sx={{ p: 2, background: "inherit", m: "10px 90px" }}>
        <List>
          <Typography fontWeight="Bold" variant="h5">
            PERSONAL INFORMATION
          </Typography>
          {personalInfoData.map((data, i) => (
            <Box key={data.field}>
              <ListItem>
                <ListItemText
                  primary={data.field}
                  secondary={data.value}
                  sx={{
                    textTransform:
                      data.field !== "Email" ? "capitalize" : undefined,
                  }}
                />
              </ListItem>
              {i !== personalInfoData.length - 1 && (
                <Divider variant="middle" />
              )}
            </Box>
          ))}
        </List>
      </Paper>
      <Paper elevation={5} sx={{ p: 2, background: "inherit", m: "10px 90px" }}>
        <List>
          <Typography fontWeight="Bold" variant="h5">
            OTHER INFORMATION
          </Typography>
          {otherInfoData.map((data, i) => (
            <Box key={data.field}>
              <ListItem>
                <ListItemText
                  primary={data.field}
                  secondary={data.value}
                  sx={{
                    textTransform:
                      data.field !== "Course Code" ? "capitalize" : undefined,
                  }}
                />
              </ListItem>
              {i !== otherInfoData.length - 1 && <Divider variant="middle" />}
            </Box>
          ))}
        </List>
      </Paper>
      {/* <Paper elevation={5} sx={{ p: 2, background: "inherit", m: "10px 90px" }}>
        <List>
          <Typography fontWeight="Bold" variant="h5">
            UPLOADS
          </Typography>
          <Box>
            <ListItem>
              <ListItemText
                primary="Uploaded Certificate"
                secondary={attachedCert ? attachedCert.name : "No uploads"}
              />
            </ListItem>
          </Box>
        </List>
      </Paper> */}
      <Stack direction="row" justifyContent="space-between" py={4} px={9}>
        <Button
          title="Clear the form"
          color="warning"
          variant="contained"
          onClick={() => updateConfirmDialog("clear")}>
          RESET FORM
        </Button>{" "}
        <Button
          title="Submit the form"
          type="submit"
          color="primary"
          variant="contained"
          endIcon={<Send />}
          onClick={() => updateConfirmDialog("submit")}>
          SUBMIT
        </Button>
      </Stack>
    </Box>
  );
}
