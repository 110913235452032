import * as React from "react";
import {
  Box,
  useTheme,
  Typography,
  TextField,
  Paper,
  Stack,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState } from "react";
import RecordSalaryForm from "../../components/salary/recordSalaryForm";
import SalaryHistoryList from "../../components/salary/salaryHistoryList";
import LinearColor from "../../components/progress";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { useValue } from "../../context/contextProvider";
import { useCallback } from "react";
import { getSalary } from "../../actions/salary";
import { useEffect } from "react";
import { searchStaff } from "../../actions/staff";

const Salary = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [next, setNext] = useState({
    nameTitle: "",
    dob: "",
    fullName: "",
    id: "",
    idNumber: "",
    sgender: "",
    snationality: "",
  });
  const [searchedStaffs, setSearchedStaffs] = useState([]);
  const [searchStaffsLoading, setSearchStaffsLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const {
    state: { currentUser, loading, salary },
    dispatch,
  } = useValue();

  const searchStaffs = async (e) => {
    const staffIdNo = e.target.value;
    if (!staffIdNo || staffIdNo === "") return setSearchedStaffs([]);
    setSearchStaffsLoading(true);
    const staffs = await searchStaff(staffIdNo, dispatch, currentUser.token);
    if (staffs && staffs.length) {
      setSearchedStaffs(staffs);
    } else {
      setSearchedStaffs([]);
    }

    setSearchStaffsLoading(false);
  };

  const resetSalaryState = () => {
    setNext({
      nameTitle: "",
      dob: "",
      id: "",
      idNumber: "",
      sgender: "",
      snationality: "",
      fullName: "",
    });
    setSearchedStaffs([]);
  };

  const fetchSalary = useCallback(async () => {
    if (currentUser && salary.length === 0 && !fetched) {
      await getSalary(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [currentUser, salary, dispatch, fetched]);

  useEffect(() => {
    fetchSalary();
  }, [fetchSalary]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Box m="20px">
      {/* HEADER */}

      <Header title="SALARY" subtitle="Record & Manage Salary" />

      <Box
        display="grid"
        gap="20px"
        px={4}
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="180px">
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 4",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "630px",
            p: 1,
          }}>
          {" "}
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
              Salary Form
            </Typography>
          </Stack>{" "}
          <Box backgroundColor={colors.primary[400]} p={4} height="600px">
            <Autocomplete
              limitTags={5}
              id="checkboxes-tags-demo"
              options={searchedStaffs}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              value={next}
              onChange={(event, value) =>
                value ? setNext(value) : resetSalaryState()
              }
              loading={searchStaffsLoading}
              getOptionLabel={(option) => option.idNumber}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />

                  {option.fullName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  color="info"
                  name="field"
                  {...params}
                  onChange={(event) => searchStaffs(event)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {searchStaffsLoading ? (
                          <CircularProgress color="info" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  label="Enter Staff ID Number"
                  placeholder="Staff ID Number"
                  sx={{
                    // gridColumn: "span 2",
                    "& .Mui-focused": {
                      color: "#f2f0f0 !important",
                      input: {
                        color: "#f2f0f0 !important",
                      },
                    },
                    "& .Mui-focused.Mui-error": {
                      color: "#f44336 !important",
                    },
                    "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root::before, .css-n88uca::before":
                      {
                        borderBottom: "2px solid #0ba2de !important",
                      },
                    "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root::after, .css-n88uca::after":
                      {
                        borderBottom: "2px solid #f5079e !important",
                      },
                    "& .Mui-error.css-1rv476z-MuiInputBase-input-MuiFilledInput-input":
                      {
                        color: "#f5079e !important",
                      },
                    "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                      {
                        borderBottom: "#f44336 !important",
                      },
                  }}
                />
              )}
            />

            {next.idNumber && (
              <RecordSalaryForm
                next={next}
                resetSalaryState={resetSalaryState}
              />
            )}
          </Box>
        </Paper>
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 8",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "630px",
            p: 1,
          }}>
          {" "}
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
              Salary Transaction Records
            </Typography>
          </Stack>{" "}
          <Box backgroundColor={colors.primary[400]}>
            {loading && <LinearColor />}
            <SalaryHistoryList salaryHistry={salary} loading={loading} />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Salary;
