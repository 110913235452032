import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "resource";

export const getResources = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, token: token, method: "GET" },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_RESOURCES",
      payload: result,
    });
  }
  dispatch({ type: "END_LOADING" });
};

export const uploadResource = async (token, resource, dispatch) => {
  const result = await fetchData(
    { url, body: resource, token: token, type: "file" },
    dispatch
  );
  if (result) {
    // ;
    dispatch({
      type: "ADD_RESOURCE",
      payload: result,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Resource uploaded successfully!",
      },
    });

    return result;
  }
};
export const deleteResource = async (token, resourceId, dispatch) => {
  const result = await fetchData(
    { url: url + "/" + resourceId, token: token, method: "DELETE" },
    dispatch
  );
  if (result) {
    dispatch({
      type: "DELETE_RESOURCE",
      payload: result,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Resource deleted successfully!",
      },
    });

    return result;
  }
};
