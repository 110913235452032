import axios from "axios";

// export const axiosReq = axios.create({
//   baseURL: process.env.REACT_APP_SERVER_URL,
//   withCredentials: true,
// });

export const axiosReq = axios.create({
  baseURL: process.env.REACT_APP_SERVER_PROURL,
  // withCredentials: true,

});
