import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { Select } from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  useGridApiContext,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { useValue } from "../../../context/contextProvider";
import {
  addDepartment,
  deleteDepartment,
  getDepartments,
  updateDepartment,
} from "../../../actions/department";
import { useCallback } from "react";
import { useEffect } from "react";
import { getFields } from "../../../actions/field";

function EditToolbar(props) {
  const { updateRows, setRowModesModel, departments } = props;

  const handleClick = () => {
    const department = departments.find((dept) => dept.isNew === true);
    if (department) return;
    const id = randomId();
    updateRows([
      ...departments,
      { id, departName: "", descr: "", fieldId: "", isNew: true },
    ]);
    setRowModesModel({
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "departName" },
    });
  };

  return (
    <GridToolbarContainer>
      <Button startIcon={<AddIcon />} onClick={handleClick}>
        Add New Department
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  updateRows: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
};

function SelectEditInputCell(props) {
  const { id, value, field, fieldsData } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ height: 1 }}
      native
      autoFocus>
      <option>Select School</option>
      {fieldsData
        .filter((fld) => fld.isNew !== true)
        .map((opt) => {
          return (
            <option key={opt.id} value={opt.id}>
              {opt.fieldName}
            </option>
          );
        })}
    </Select>
  );
}

SelectEditInputCell.propTypes = {
  /**
   * The column field of the cell that triggered the event.
   */
  field: PropTypes.string.isRequired,
  /**
   * The grid row id.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The cell value.
   * If the column has `valueGetter`, use `params.row` to directly access the fields.
   */
  value: PropTypes.any,
  // opts: PropTypes.array.isRequired,
};

const renderSelectEditInputCell = (params) => {
  return <SelectEditInputCell {...params} />;
};

export default function Departments({ value }) {
  const {
    state: { currentUser, departments, fieldsData, loading },
    dispatch,
  } = useValue();

  const [fetched, setFetched] = React.useState(false);
  // const [loading1, setLoading] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const updateRows = (newRows) => {
    dispatch({
      type: "UPDATE_DEPARTMENTS",
      payload: newRows,
    });
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    // console.log([...rowModesModel]);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    const res = await deleteDepartment(currentUser.token, dispatch, id);
    if (res === 1) {
      const newRows = departments.filter((row) => row.id !== id);
      updateRows(newRows);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = departments.find((row) => row.id === id);
    if (editedRow.isNew) {
      const newRows = departments.filter((row) => row.id !== id);
      updateRows(newRows);
    }
  };

  const processRowUpdate = async (newRow) => {
    const { departName, fieldId } = newRow;
    if (departName === "" || fieldId === "") {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Error, Department Name or School is required!",
          act: "expeSubmited",
        },
      });
    }
    const res = newRow.isNew
      ? await addDepartment(currentUser.token, dispatch, newRow)
      : await updateDepartment(currentUser.token, dispatch, newRow);
    if (!res) return;
    const updatedRow = { ...newRow, isNew: false, ...res };
    const newRows = departments.map((row) =>
      row.id === newRow.id ? updatedRow : row
    );
    updateRows(newRows);
    return updatedRow;
  };
  const fetchDepartments = useCallback(async () => {
    if (currentUser && fieldsData.length === 0 && value === 1) {
      await getFields(currentUser.token, dispatch);
    }
    if (departments.length) {
      const id = departments.find((dept) => dept.isNew === true)?.id;
      if (id) {
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: "departName" },
        }));
      }
    }
    if (currentUser && departments.length === 0 && value === 1 && !fetched) {
      // setLoading(true);
      setFetched(true);
      await getDepartments(currentUser.token, dispatch);
      // setLoading(false);
    }
    return;
  }, [currentUser, departments, fieldsData, value, dispatch, fetched]);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  const columns = [
    { field: "departName", headerName: "Name", width: 200, editable: true },
    { field: "descr", headerName: "Description", width: 250, editable: true },
    {
      field: "dateCreated",
      headerName: "Date Created",
      // type: "date",
      width: 100,
      editable: false,
    },
    {
      field: "fieldId",
      headerName: "Field",
      renderEditCell: (params) =>
        renderSelectEditInputCell({ ...params, fieldsData }),
      width: 180,
      editable: true,
      // preProcessEditCellProps: (value, params) => {
      //   const hasError = value === "";

      //   return { ...params.props, error: hasError };
      // },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "secondary",
        },
        "& .textPrimary": {
          color: "primary",
        },
      }}>
      <DataGrid
        rows={departments}
        columns={columns}
        editMode="row"
        loading={loading}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        // onCellKeyDown={(params, event) => {
        //   console.log(params.row);
        // }}
        // onCellEditStart={(params) => {
        //   console.log(params);
        // }}
        onProcessRowUpdateError={(error) => {
          return console.log(error);
        }}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { updateRows, setRowModesModel, departments },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
