// import axios from "axios";
import fetchData from "./utils/fetchData";
// import { saveAs } from "file-saver";

const url = process.env.REACT_APP_SERVER_PROURL + "students";
// const downloadsUrl = process.env.REACT_APP_SERVER_PROURL + "downloads";

export const addStudent = async (token, student, dispatch, navigate) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, body: student, token: token },
    dispatch
  );
  if (result) {
    getStudents(token, dispatch);
    navigate("/students/" + result.insertId);
    dispatch({
      type: "RESET_STUDENT_FORM",
      payload: 0,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Student registered successfully!",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const getStudents = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_STUDENTS",
      payload: result,
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const studentSearch = async (sid, dispatch, token) => {
  const result = await fetchData(
    { url: `${url}/search/${sid}`, method: "GET", token: token },
    dispatch
  );
  if (result) {
    return result;
  }
};
export const getStudent = async (sid, dispatch, token, navigate) => {
  dispatch({ type: "START_LOADING" });

  const result = await fetchData(
    { url: `${url}/${sid}`, method: "GET", token: token },
    dispatch
  );
  if (result) {
    if (result.studentId === parseInt(sid)) {
      dispatch({
        type: "UPDATE_STUDENT",
        payload: result,
      });
    } else {
      dispatch({
        type: "UPDATE_STUDENT",
        payload: {},
      });
      navigate("/students/" + sid + "/notFound");
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "User not found!",
        },
      });
    }
  }

  dispatch({ type: "END_LOADING" });
};
export const deleteStudent = async (sid, dispatch, token, navigate) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${sid}`, method: "DELETE", token: token },
    dispatch
  );
  if (result) {
    navigate("/students");
    dispatch({
      type: "DELETE_STUDENT",
      payload: sid,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Student deleted successfully",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const updateStudentStatus = async (sid, dispatch, token) => {
  dispatch({ type: "START_SINGLE_LOADING" });
  const result = await fetchData(
    { url: `${url}/updateStudentStatus/${sid}`, method: "PUT", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_STUDENT_STATUS",
      payload: { id: sid, data: { studentStatus: "viewed" } },
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Viewed",
      },
    });
  }
  dispatch({ type: "END_SINGLE_LOADING" });
};

export const updateStudentAdmission = async (sid, dispatch, token, admNo) => {
  dispatch({ type: "START_SINGLE_LOADING" });
  const result = await fetchData(
    {
      url: `${url}/updateStudentAdmission/${sid}`,
      body: admNo,
      method: "PUT",
      token: token,
    },
    dispatch
  );
  dispatch({ type: "END_SINGLE_LOADING" });
  if (result) {
    dispatch({
      type: "UPDATE_STUDENT_STATUS",
      payload: {
        id: sid,
        data: result,
      },
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Student admitted Successfully",
      },
    });
    return result;
  }
};
export const updateStudentPersonalInfo = async (
  sid,
  dispatch,
  token,
  studentInfo
) => {
  const result = await fetchData(
    {
      url: `${url}/updateStudentPersonalInfo/${sid}`,
      body: studentInfo,
      method: "PUT",
      token: token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_STUDENT_STATUS",
      payload: {
        id: sid,
        data: result,
      },
    });

    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Student updated Successfully",
      },
    });
  }
};
export const updateStudentUploads = async (sid, dispatch, token, doc, act) => {
  dispatch({ type: "START_FILE_UPLOAD_LOADING" });
  const result = await fetchData(
    {
      url: `${url}/updateStudentUploads/${sid}`,
      body: doc,
      method: "PUT",
      token: token,
    },
    dispatch
  );
  if (result) {
    const message =
      act === "delete"
        ? "File deleted successfully"
        : "File uploaded Successfully";
    if (act === "add")
      dispatch({
        type: "UPDATE_STUDENT_FORM_UPLOADS",
        payload: { file: "", fileTitle: "" },
      });

    dispatch({
      type: "UPDATE_STUDENT_STATUS",
      payload: {
        id: sid,
        data: { docInfo: result },
      },
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: message,
      },
    });
  }
  dispatch({ type: "END_FILE_UPLOAD_LOADING" });
};
export const downloadFile = async (token, dispatch) => {
  const res = await fetchData(
    {
      url: `${url}/uploading/file_doc`,
      method: "GET",
      token: token,
      // type: "fil",
      // headers: {
      //   // "Content-Type": "application/pdf",
      //   authorization: `Bearer ${token}`,
      // },
    },
    dispatch
  );
  return res;
};
