import * as React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useValue } from "../../../context/contextProvider";
import { addUnit } from "../../../actions/unit";
const AddUnitsForm1 = () => {
  const {
    dispatch,
    state: { currentUser },
  } = useValue();
  let resetFormState = null;
  const recordUnit = async (Data) => {
    const result = await addUnit(currentUser.token, dispatch, Data);
    if (result === 1) handleFormReset("unitSubmited");
  };
  const handleFormReset = (resetForm) => {
    if (resetForm === "unitSubmited") {
      resetFormState();
    } else {
      resetForm();
    }
    resetFormState = null;
  };
  const updateConfirmDialog = (act) => {
    const message =
      "You are about to reset this form all information will be deleted. Are you sure you want to proceed? Be Careful !";
    const title = "Form Reset";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: handleFormReset,
      },
    });
  };
  const updateConfirmDialogWithList = (act) => {
    const message =
      "You are about to submit the following record, Please confirm before submiting!";
    const title = "Record Unit";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG_WITH_LIST",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: recordUnit,
        formData: initialValues,
      },
    });
  };

  const handleFormSubmit = (values) => {
    updateConfirmDialogWithList(values);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          resetForm,
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {isSubmitting && resetFormState === null
              ? (resetFormState = resetForm)
              : ""}
            <Stack
              direction="column"
              spacing={4}
              sx={{
                p: 2,

                height: "370px",
              }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                name="code"
                error={!!touched.code && !!errors.code}
                helperText={touched.code && errors.code}
                color="info"
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                color="info"
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                multiline
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                color="info"
              />
              <Stack direction="row" justifyContent="space-between">
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => updateConfirmDialog(resetForm)}>
                  RESET FORM
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                  SUBMIT
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  code: yup.string().required("required"),
  title: yup.string().required("required"),
  // department: yup.string().required("required"),
});

const initialValues = {
  code: "",
  title: "",
  department: "",
  description: "",
};

export default AddUnitsForm1;
