import React from "react";
import {
  Box,
  Chip,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Cancel, Save, UploadFileOutlined } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { TransitionGroup } from "react-transition-group";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { DownloadOutlined } from "@mui/icons-material";
import { useValue } from "../../../context/contextProvider";
import { useState } from "react";
import { useEffect } from "react";
import {
  uploadingFiles,
  fileDownloadingFunc,
  fileDeletingdingFunc,
} from "../../../actions/fileUploading";
import { LoadingButton } from "@mui/lab";
function renderItem({
  doc,
  updateConfirmDialog,
  fileDownloading,
  deletingDoc,
}) {
  return (
    <ListItem
      secondaryAction={
        <LoadingButton
          size="small"
          loading={deletingDoc === doc.filename}
          color="error"
          title={`Delete ${doc.fileTitle}`}
          variant="outlined"
          onClick={() => updateConfirmDialog(doc)}>
          <DeleteIcon sx={{ fontSize: 15 }} />
        </LoadingButton>
      }>
      <ListItemText
        primary={
          <Chip
            disabled={deletingDoc === doc.filename}
            title={`Download ${doc.fileTitle}`}
            size="small"
            color="primary"
            variant="filled"
            onClick={() => fileDownloading(doc)}
            icon={<DownloadOutlined size="small" />}
            label={doc.fileTitle}
            sx={{
              flexWrap: "noWrap",
              width: "170px",
              cursor: "pointer",
              justifyContent: "flex-start",
              px: 1,
            }}
          />
        }
      />
    </ListItem>
  );
}

export default function FileUploads({ data, id, user, attachedDoc }) {
  const [deletingDoc, setDeletingDoc] = useState(null);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const {
    state: { currentUser, fileUploadLoading },
    dispatch,
  } = useValue();
  const UPDATE_FORM =
    user === "staff"
      ? "UPDATE_STAFF_FORM_UPLOADS"
      : user === "students"
      ? "UPDATE_STUDENT_FORM_UPLOADS"
      : "";
  const fileDownloading = (doc) => {
    const path = user + "/download/" + id + "/" + doc.filename;
    fileDownloadingFunc(currentUser.token, doc, path, dispatch);
  };
  const fileDeleting = async (doc) => {
    setDeletingDoc(doc.filename);
    const path = user + "/deleteFile/" + id + "/" + doc.filename;
    await fileDeletingdingFunc(currentUser.token, path, user, id, dispatch);
    setDeletingDoc(null);
  };
  const handleChange = (e) => {
    const allowedFile = new RegExp(/[^\s]+(.*?).(pdf)$/);
    if (!allowedFile.test(e.target.files[0].name)) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Error: Only pdf file is allowed",
        },
      });
    } else {
      dispatch({
        type: UPDATE_FORM,
        payload: { file: e.target.files[0] },
      });
    }
  };
  const removeFile = () => {
    dispatch({
      type: UPDATE_FORM,
      payload: { file: "", fileTitle: "" },
    });
  };
  const updateFileTitle = (e) => {
    dispatch({
      type: UPDATE_FORM,
      payload: { fileTitle: e.target.value },
    });
  };
  const handleSaveFile = async () => {
    // e.preventDefault();
    // dispatch({ type: "START_FILE_UPLOAD_LOADING" });
    if (!attachedDoc.file || !attachedDoc.fileTitle)
      return alert("Please select a file and title");
    const formdata = new FormData();
    formdata.append("doc", attachedDoc.file);
    formdata.append("fileTitle", attachedDoc.fileTitle);
    const path = user + "/updateUploads/" + id;
    await uploadingFiles(
      id,
      currentUser.token,
      formdata,
      dispatch,
      setProgress,
      path,
      user
    );
  };
  const updateConfirmDialog = (act) => {
    // setDeletedDoc(doc);
    const message =
      "You are about to delete this file. Are you sure you want to proceed?";
    const title = "File deleting";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: fileDeleting,
      },
    });
  };

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(0);
        setBuffer(10);
      }, 500000);
    } else {
      setBuffer(progress + 10);
    }
  }, [progress]);
  return (
    <Box maxHeight={100}>
      {fileUploadLoading && (
        <LinearProgress
          sx={{ mt: -3, mb: 3 }}
          color="info"
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
        />
      )}

      <form method="POST">
        {" "}
        <label htmlFor="uploadsCert">
          <input
            accept="application/pdf,.pdf"
            id="uploadsCert"
            disabled={fileUploadLoading}
            type="file"
            value={attachedDoc.file && attachedDoc.file.filename}
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <UploadFileOutlined
            titleAccess="Click to upload"
            sx={{ color: "#0ba2de", cursor: "pointer" }}
          />
          {attachedDoc.file ? (
            <Chip
              size="small"
              color="success"
              variant="outlined"
              // icon={<CancelIcon />}
              label={attachedDoc.file && attachedDoc.file.name}
              // onDelete={}
              sx={{ flexWrap: "noWrap", width: "230px", cursor: "pointer" }}
            />
          ) : (
            <Typography
              title="Click to upload"
              sx={{ color: "#0ba2de", cursor: "pointer" }}>
              Upload document here....
            </Typography>
          )}
        </label>
        {attachedDoc.file && (
          <>
            <TextField
              required
              color="info"
              disabled={fileUploadLoading}
              autoFocus={attachedDoc.file}
              size="small"
              type="text"
              value={attachedDoc.fileTitle}
              label="Enter title..."
              sx={{ width: 140, ml: 2 }}
              onChange={updateFileTitle}
            />
            <IconButton
              sx={{ ml: 2 }}
              title="Save file"
              type="submit"
              disabled={
                !attachedDoc.fileTitle || !attachedDoc.file || fileUploadLoading
              }
              color="info"
              onClick={handleSaveFile}>
              <Save />
            </IconButton>
            <IconButton
              disabled={!attachedDoc.file || fileUploadLoading}
              color="error"
              title="Remove file"
              onClick={removeFile}>
              <Cancel />
            </IconButton>
          </>
        )}
      </form>
      <br />
      <List
        sx={{
          maxHeight: 350,
          overflow: "auto",
          ml: 2,
          //   display: "flex",
          pr: 5,
          width: 370,
        }}>
        {data && data.docInfo.length ? (
          <TransitionGroup>
            {data.docInfo.map((doc) => (
              <Collapse key={doc.filename}>
                {renderItem({
                  doc,
                  updateConfirmDialog,
                  fileDownloading,
                  deletingDoc,
                })}
              </Collapse>
            ))}
          </TransitionGroup>
        ) : (
          "No Uploads"
        )}
      </List>
    </Box>
  );
}
