import React, { useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useValue } from "../../../../context/contextProvider";
// import FormField from "../../../utils/textFields/FormField";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getDesignations } from "../../../../actions/designation";
import { getFields } from "../../../../actions/field";

export default function OtherInfo({ handlePage, handlePagePrev }) {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {
    state: {
      currentUser,
      staffsRegistrationForm: { otherInfo },
      designations,
      fieldsData,
    },
    dispatch,
  } = useValue();
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedValue, setSelectedValue] = useState([
    {
      id: otherInfo.designation,
      nameTitle: otherInfo.nameTitle,
      category: otherInfo.category,
    },
    {
      id: otherInfo.field,
      fieldName: otherInfo.fieldName,
    },
  ]);
  const handleFormSubmit = (values) => {
    handlePage({
      otherInfo: {
        ...values,
      },
    });
  };

  const handleSelectedValue = async (value, setFieldValue) => {
    await setFieldValue("designation", value?.id ?? "", "true");
    await setFieldValue("nameTitle", value?.nameTitle ?? "", "true");
    await setFieldValue("category", value?.category ?? "", "true");

    setSelectedValue([
      {
        nameTitle: value?.nameTitle ?? "",
        category: value?.category ?? "",
        id: value?.id ?? "",
      },
      selectedValue[1],
    ]);
  };
  const updateDesignations = async () => {
    if (designations.length === 0) {
      setLoading(true);
      const result = await getDesignations(currentUser.token, dispatch);
      if (result?.length === 0) {
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message:
              "No designation added, please go to the designation menu to add one!",
          },
        });
      }
      setLoading(false);
    }
  };
  const updateFields = async () => {
    if (fieldsData.length === 0) {
      setSearching(true);
      const result = await getFields(currentUser.token, dispatch);
      if (result?.length === 0) {
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: "No field added, please go to the field menu to add one!",
          },
        });
      }
      setSearching(false);
    }
  };
  return (
    <>
      <Box m="20px 70px 0 70px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={otherInfo}
          validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValidating,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                // width="100%"
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(12, 1fr)"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  "& .Mui-focused": {
                    color: "#f5079e !important",
                    input: {
                      color: "#0ba2de !important",
                    },
                  },
                  "& .Mui-focused.Mui-error": {
                    color: "#f44336 !important",
                  },
                  "& .css-uc0k7-MuiInputBase-root-MuiFilledInput-root:before": {
                    borderBottom: "2px solid #0ba2de !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
                    borderBottom: "2px solid #f5079e !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                    {
                      borderBottom: "#f44336 !important",
                    },
                }}>
                <Autocomplete
                  id="combo-box-demo"
                  loading={loading}
                  onFocus={() => updateDesignations()}
                  onChange={(event, value) => {
                    handleSelectedValue(value, setFieldValue);
                  }}
                  options={designations}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  value={selectedValue[0]}
                  getOptionLabel={(option) => option.nameTitle}
                  sx={{
                    gridColumn: "span 7",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="info"
                      label="Designation"
                      onBlur={handleBlur}
                      name="designation"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="info" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={!!touched.designation && !!errors.designation}
                      helperText={touched.designation && errors.designation}
                    />
                  )}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  disabled
                  label="Category"
                  onBlur={handleBlur}
                  value={selectedValue[0].category}
                  name="category"
                  error={!!touched.category && !!errors.category}
                  helperText={touched.category && errors.category}
                  sx={{
                    gridColumn: "span 5",
                  }}
                />
                {selectedValue[0]?.category === "teacher" && (
                  <Autocomplete
                    id="combo-box-demo"
                    loading={searching}
                    onFocus={updateFields}
                    onChange={(event, value) => {
                      setSelectedValue([
                        selectedValue[0],
                        {
                          id: value?.id ?? "",
                          fieldName: value?.fieldName ?? "",
                        },
                      ]);
                      setFieldValue("fieldName", value?.fieldName ?? "", true);
                      setFieldValue("field", value?.id ?? "", true);
                    }}
                    disabled={selectedValue[0].id === ""}
                    options={fieldsData}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    value={selectedValue[1]}
                    getOptionLabel={(option) => option.fieldName}
                    sx={{
                      gridColumn: "span 7",
                    }}
                    renderInput={(params) => (
                      <TextField
                        color="info"
                        {...params}
                        label="Field"
                        onBlur={handleBlur}
                        name="field"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {searching ? (
                                <CircularProgress color="info" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        error={!!touched.field && !!errors.field}
                        helperText={touched.field && errors.field}
                      />
                    )}
                  />
                )}

                {/* <FormField
                  err={!!touched.sponsorship && !!errors.sponsorship}
                  errorMessage={touched.sponsorship && errors.sponsorship}
                  mainProps={{
                    name: "sponsorship",
                    value: values.sponsorship,
                  }}
                  formLabel="SponsorShip"
                  optionalProps={{
                    style: { gridColumn: "span 12", gridRow: "span 2" },
                    onChange: handleChange,
                  }}
                  radioBtn={[
                    { value: "self", label: "Self Sponsored" },
                    { value: "parent", label: "Parent / Guardian" },
                    { value: "organization", label: "Organization" },
                  ]}
                />
                {(values.sponsorship === "parent" ||
                  values.sponsorship === "organization") && (
                  <>
                    {" "}
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Name Of Sponsor"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.nameOfSponsor}
                      name="nameOfSponsor"
                      error={!!touched.nameOfSponsor && !!errors.nameOfSponsor}
                      helperText={touched.nameOfSponsor && errors.nameOfSponsor}
                      sx={{
                        gridColumn: "span 5",
                        gridRow: "span 2",
                      }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Telephone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.telOfSponsor}
                      name="telOfSponsor"
                      error={!!touched.telOfSponsor && !!errors.telOfSponsor}
                      helperText={touched.telOfSponsor && errors.telOfSponsor}
                      sx={{
                        gridColumn: "span 4",
                        gridRow: "span 2",
                      }}
                    />
                  </>
                )}

                <FormField
                  err={!!touched.modeOfStudy && !!errors.modeOfStudy}
                  errorMessage={touched.modeOfStudy && errors.modeOfStudy}
                  mainProps={{
                    name: "modeOfStudy",
                    value: values.modeOfStudy,
                  }}
                  optionalProps={{
                    style: { gridColumn: "span 12" },
                    onChange: handleChange,
                  }}
                  formLabel="Mode Of Study"
                  radioBtn={[
                    { value: "morning", label: "Morning" },
                    { value: "fullTime", label: "fullTime" },
                    { value: "evening", label: "Evening" },
                    { value: "online", label: "Online" },
                  ]}
                /> */}
              </Box>
              <Box display="flex" justifyContent="space-between" mt="50px">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    handlePagePrev({
                      otherInfo: {
                        ...values,
                      },
                    })
                  }>
                  PREVIOUS
                </Button>{" "}
                <Button type="submit" color="secondary" variant="contained">
                  NEXT
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
}
const checkoutSchema = yup.object().shape({
  field: yup.string().when("category", {
    is: "teacher",
    then: yup.string().required("required"),
    otherwise: yup.string().optional(),
  }),
  fieldName: yup.string().optional(),
  designation: yup.string().required("required"),
  nameTitle: yup.string().optional(),
  category: yup.string().optional(),
});
