import * as React from "react";
import { Box, Button, TextField, Modal, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddOutlined } from "@mui/icons-material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useValue } from "../../context/contextProvider";
import { addDesignations } from "../../actions/designation";
import { useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 610,
  height: 460,
  border: "1px solid #0ba2de",
  boxShadow: 14,
  p: "10px 50px ",
  overflow: "auto",
};

export default function DesignationModal() {
  const {
    state: { designationFormFields, currentUser, loading },
    dispatch,
  } = useValue();
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(undefined);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleFormSubmit = async (values) => {
    dispatch({
      type: "UPDATE_DESIGNATION_FORM_FIELD",
      payload: values,
    });
    await addDesignations(currentUser.token, values, dispatch, setSuccess);
  };

  useEffect(() => {
    if (success && open) {
      setOpen(false);
      setSuccess(undefined);
    }
  }, [success, open]);
  const checkoutSchema = yup.object().shape({
    title: yup.string().required("required"),
    salary: yup.string().required("required"),
    category: yup.string().required("required"),
  });
  const categoryOptions = [
    { value: "teacher", label: "Teacher" },
    { value: "other", label: "Other" },
  ];
  return (
    <div>
      <Button
        startIcon={<AddOutlined />}
        onClick={handleOpen}
        sx={{
          position: "absolute",
          right: "20%",
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontWeight: "bold",
          "&:hover": {
            bordrBottoem: 1,
            borderColor: "red",
            backgroundColor: colors.greenAccent[500],
          },
        }}>
        ADD
      </Button>
      <Modal
        // keepMounted
        open={open}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <Box sx={style} bgcolor={colors.primary[400]}>
          <Button
            onClick={handleClose}
            sx={{
              color: colors.grey[100],
              position: "absolute",
              right: 0,
              "&:hover": {
                color: colors.greenAccent[500],
              },
            }}>
            <CloseOutlinedIcon />
          </Button>

          <Typography
            textAlign="center"
            fontSize="23px"
            fontWeight="bold"
            sx={{
              mt: 4,
              borderBottom: "2px solid #f5079e",
              color: colors.blueAccent[700],
            }}>
            Add Positions for the Staff Members
          </Typography>
          <Box mt="50px">
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={designationFormFields}
              validationSchema={checkoutSchema}>
              {({
                resetForm,
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="20px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                      "& .Mui-focused": {
                        color: "#f2f0f0 !important",
                        input: {
                          color: "#f2f0f0 !important",
                        },
                      },
                      "& .Mui-focused.Mui-error": {
                        color: "#f44336 !important",
                      },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                        {
                          borderBottom: "2px solid #0ba2de !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after":
                        {
                          borderBottom: "2px solid #f5079e !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                        {
                          borderBottom: "#f44336 !important",
                        },
                    }}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      name="title"
                      error={!!touched.title && !!errors.title}
                      helperText={touched.title && errors.title}
                      sx={{
                        gridColumn: "span 2",
                      }}
                    />

                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Salary (Monthly)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.salary}
                      name="salary"
                      error={!!touched.salary && !!errors.salary}
                      helperText={touched.salary && errors.salary}
                      sx={{
                        gridColumn: "span 2",
                      }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      select
                      label="Select Category"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.category}
                      name="category"
                      sx={{
                        gridColumn: "span 4",
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      error={!!touched.category && !!errors.category}
                      helperText={touched.category && errors.category}>
                      {" "}
                      <option></option>
                      {categoryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                  <Stack direction="row" mt="50px" spacing={3}>
                    <Button
                      color="error"
                      variant="contained"
                      disabled={loading}>
                      RESETFORM
                    </Button>
                    <LoadingButton
                      size="large"
                      type="submit"
                      loading={loading}
                      color="secondary"
                      loadingPosition="start"
                      // loadingIndicator="Loading…"
                      variant="contained">
                      <span>SUBMIT</span>
                    </LoadingButton>
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
