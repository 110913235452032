import * as React from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MpesaForm from "./mpesa";
import BankForm from "./bank";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { studentSearch } from "../../../actions/student";
import { useValue } from "../../../context/contextProvider";
import { getFeeStatement } from "../../../actions/fees";

const CollectFeesForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [method, setMethod] = useState("");
  const [next, setNext] = useState();
  const [searchedStudents, setSearchedStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    state: { currentUser },
    dispatch,
  } = useValue();

  const fetchFeeStatement = async (value) => {
    const studentId = value?.id;
    if (!studentId || studentId === "") return;
    setLoading(true);
    const feeStatement = await getFeeStatement(
      studentId,
      dispatch,
      currentUser.token
    );
    if (feeStatement) {
      setNext({ ...value, ...feeStatement });
    }

    setLoading(false);
  };
  const searchStudents = async (e) => {
    const studentIdNo = e.target.value;
    if (!studentIdNo || studentIdNo === "") return setSearchedStudents([]);
    setLoading(true);
    const students = await studentSearch(
      studentIdNo,
      dispatch,
      currentUser.token
    );
    if (students && students.length) {
      setSearchedStudents(students);
    } else {
      setSearchedStudents([]);
    }

    setLoading(false);
  };

  const handleFormSubmit = (values) => {
    alert("submitted");
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <>
      <Box ml="20px" mr="40px" mt="20px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={Object.assign(initialValues)}
          validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}>
                <FormControl
                  sx={{
                    gridColumn: "span 12",
                    "& .css-w3lmqu-MuiFormLabel-root.Mui-focused": {
                      color: "#f2f0f0 !important",
                    },
                    "& .css-1a9y42x-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#0ba2de !important" },
                  }}
                  error={!!touched.duration && !!errors.duration}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Method Of Payment
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="methodOfPayment"
                    onBlur={handleBlur}
                    onChange={(setMethod(values.methodOfPayment), handleChange)}
                    value={values.methodOfPayment}>
                    <FormControlLabel
                      value="mpesa"
                      control={<Radio />}
                      label="M-pesa"
                      disabled
                    />
                    <FormControlLabel
                      value="bank"
                      control={<Radio />}
                      label="Bank"
                    />
                  </RadioGroup>
                  <FormHelperText color="error">
                    {touched.methodOfPayment && errors.methodOfPayment}
                  </FormHelperText>
                </FormControl>

                <Autocomplete
                  limitTags={5}
                  id="checkboxes-tags-demo"
                  options={searchedStudents}
                  disabled={method === ""}
                  onChange={(event, value) => fetchFeeStatement(value)}
                  loading={loading}
                  autoFocus={values.methodOfPayment === "bank"}
                  getOptionLabel={(option) => option.idNo}
                  sx={{
                    gridColumn: "span 3",
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />

                      {option.fullNames}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      color="info"
                      name="studentAdm"
                      {...params}
                      onChange={(event) => searchStudents(event)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="info" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      label="Student Id/Admission Number"
                      placeholder="Student Id/Admission Number"
                      sx={{
                        // gridColumn: "span 2",
                        "& .Mui-focused": {
                          color: "#f2f0f0 !important",
                          input: {
                            color: "#f2f0f0 !important",
                          },
                        },
                        "& .Mui-focused.Mui-error": {
                          color: "#f44336 !important",
                        },
                        "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root::before, .css-n88uca::before":
                          {
                            borderBottom: "2px solid #0ba2de !important",
                          },
                        "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root::after, .css-n88uca::after":
                          {
                            borderBottom: "2px solid #f5079e !important",
                          },
                        "& .Mui-error.css-1rv476z-MuiInputBase-input-MuiFilledInput-input":
                          {
                            color: "#f5079e !important",
                          },
                        "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                          {
                            borderBottom: "#f44336 !important",
                          },
                      }}
                    />
                  )}
                />
                {next && (
                  <>
                    <TextField
                      fullWidth
                      disabled
                      variant="filled"
                      type="text"
                      label="Full name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={next ? next.fullNames : ""}
                      name="fullNames"
                      error={!!touched.fullNames && !!errors.fullNames}
                      helperText={touched.fullNames && errors.fullNames}
                      sx={{
                        gridColumn: "span 4",
                        "& .Mui-focused": {
                          color: "#f2f0f0 !important",
                          input: {
                            color: "#f2f0f0 !important",
                          },
                        },
                        "& .Mui-focused.Mui-error": {
                          color: "#f44336 !important",
                        },
                        "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                          {
                            borderBottom: "2px solid #0ba2de !important",
                          },
                        "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after":
                          {
                            borderBottom: "2px solid #f5079e !important",
                          },
                        "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                          {
                            borderBottom: "#f44336 !important",
                          },
                      }}
                    />

                    <TextField
                      fullWidth
                      disabled
                      variant="filled"
                      type="text"
                      label="Course"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={next?.courseName}
                      name="course"
                      error={!!touched.course && !!errors.course}
                      helperText={touched.course && errors.course}
                      sx={{
                        gridColumn: "span 4",
                        "& .Mui-focused": {
                          color: "#f2f0f0 !important",
                          input: {
                            color: "#f2f0f0 !important",
                          },
                        },
                        "& .Mui-focused.Mui-error": {
                          color: "#f44336 !important",
                        },
                        "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:before":
                          {
                            borderBottom: "2px solid #0ba2de !important",
                          },
                        "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:after":
                          {
                            borderBottom: "2px solid #f5079e !important",
                          },
                        "& .Mui-error.css-1rv476z-MuiInputBase-input-MuiFilledInput-input":
                          {
                            color: "#f5079e !important",
                          },
                        "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                          {
                            borderBottom: "#f44336 !important",
                          },
                      }}
                    />
                    <TextField
                      fullWidth
                      disabled
                      variant="filled"
                      type="number"
                      label="Fee Balance"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={next?.feeBalance}
                      name="feeBalance"
                      error={!!touched.feeBalance && !!errors.feeBalance}
                      helperText={touched.feeBalance && errors.feeBalance}
                      sx={{
                        gridColumn: "span 3",
                        "& .Mui-focused": {
                          color: "#f2f0f0 !important",
                          input: {
                            color: "#f2f0f0 !important",
                          },
                        },
                        "& .Mui-focused.Mui-error": {
                          color: "#f44336 !important",
                        },
                        "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:before":
                          {
                            borderBottom: "2px solid #0ba2de !important",
                          },
                        "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:after":
                          {
                            borderBottom: "2px solid #f5079e !important",
                          },
                        "& .Mui-error.css-1rv476z-MuiInputBase-input-MuiFilledInput-input":
                          {
                            color: "#f5079e !important",
                          },
                        "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                          {
                            borderBottom: "#f44336 !important",
                          },
                      }}
                    />
                  </>
                )}
              </Box>
            </form>
          )}
        </Formik>
        {next && method === "mpesa" && <MpesaForm />}

        {next && method === "bank" && <BankForm {...next} setNext={setNext} />}
      </Box>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  // course: yup.string().required("required"),
  // fullNames: yup.string().required("required"),
  // feeBalance: yup.string().required("required"),
  // methodOfPayment: yup.string().required("Required"),
  // studentAdm: yup.string().required("Required"),
});

const initialValues = {
  course: "",
  fullNames: "",
  feeBalance: "",
  methodOfPayment: "",
  studentAdm: "",
};

export default CollectFeesForm;
