import { useEffect } from "react";
import { useValue } from "../context/contextProvider";
import jwtDecode from "jwt-decode";

const useCheckToken = () => {
  const {
    state: { currentUser },
    dispatch,
  } = useValue();

  useEffect(() => {
    if (currentUser !== null && currentUser.token) {
      const decodedToken = jwtDecode(currentUser.token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        dispatch({ type: "LOGOUT_USER", payload: null });
      }
    }
  }, [currentUser, dispatch]);
};

export default useCheckToken;
