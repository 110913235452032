import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "otherExpenditure";

export const getExpenditures = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_EXPENDITURES",
      payload: result,
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const addExpenditure = async (token, dispatch, expenditure) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, body: expenditure, token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Data submited successfully!",
        act: "expeSubmited",
      },
    });
    getExpenditures(token, dispatch);
  }
  dispatch({ type: "END_LOADING" });
  if (result === 1) return result;
};

export const getExpenditure = async (id, dispatch, token) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${id}`, method: "GET", token: token },
    dispatch
  );
  if (result) {
    if (result) {
      dispatch({
        type: "UPDATE_EXPENDITURE",
        payload: result,
      });
    }
  }

  dispatch({ type: "END_LOADING" });
};
