import React, { useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useValue } from "../../../../context/contextProvider";
import FormField from "../../../utils/textFields/FormField";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getCourses } from "../../../../actions/course";
import { getAvailableCourseIntakeClassSes } from "../../../../actions/intakeClassSession";

export default function CourseInfo({ handlePage, handlePagePrev }) {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {
    state: {
      currentUser,
      studentsRegistrationForm: { courseInfo },
      courses,
    },
    dispatch,
  } = useValue();
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [availableClass, setAvailableClass] = useState([]);
  const [selectedValue, setSelectedValue] = useState([
    {
      id: courseInfo.courseId,
      courseName: courseInfo.courseName,
      courseCode: courseInfo.courseCode,
      courseLevel: courseInfo.courseLevel,
    },
    {
      id: courseInfo.intakeClassSes,
      classTitle: courseInfo.classTitle,
      currentSession: courseInfo.currentSession,
    },
  ]);
  const handleFormSubmit = (values) => {
    handlePage({
      courseInfo: {
        ...values,
      },
    });
  };

  const fetchAvailableCourseIntakeClassSes = async (value) => {
    if (value && value.id !== "") {
      setSearching(true);
      const availableClasses = await getAvailableCourseIntakeClassSes(
        value.id,
        dispatch,
        currentUser.token
      );
      if (availableClasses?.length === 0) {
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message:
              "No available intake Class for this course, please go to the class menu to add one!",
          },
        });
      }
      setAvailableClass(availableClasses);
      setSearching(false);
    } else {
      setSelectedValue([
        selectedValue[0],
        {
          id: "",
          classTitle: "",
          currentSession: "",
        },
      ]);
    }
  };
  const handleSelectedValue = async (value, setFieldValue) => {
    await setFieldValue("courseId", value?.id ?? "", "true");
    await setFieldValue("courseName", value?.courseName ?? "", "true");
    await setFieldValue("courseCode", value?.courseCode ?? "", "true");
    await setFieldValue("courseLevel", value?.courseLevel ?? "", "false");

    setSelectedValue([
      {
        courseName: value?.courseName ?? "",
        courseCode: value?.courseCode ?? "",
        courseLevel: value?.courseLevel ?? "",
        id: value?.id ?? "",
      },
      {
        id: "",
        classTitle: "",
        currentSession: "",
      },
    ]);
    setAvailableClass([]);
    if (value === null) {
      await setFieldValue("intakeClassSes", "", "true");
      await setFieldValue("classTitle", "", "true");
      await setFieldValue("currentSession", "", "true");

      return;
    }
    await fetchAvailableCourseIntakeClassSes(value);
  };
  const updateCourses = async () => {
    if (courses.length === 0) {
      setLoading(true);
      const result = await getCourses(currentUser.token, dispatch);
      if (result?.length === 0) {
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message:
              "No course added, please go to the course menu to add one!",
          },
        });
      }
      setLoading(false);
    }
  };
  return (
    <>
      <Box m="20px 70px 0 70px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={courseInfo}
          validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValidating,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                // width="100%"
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(12, 1fr)"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  "& .Mui-focused": {
                    color: "#f5079e !important",
                    input: {
                      color: "#0ba2de !important",
                    },
                  },
                  "& .Mui-focused.Mui-error": {
                    color: "#f44336 !important",
                  },
                  "& .css-uc0k7-MuiInputBase-root-MuiFilledInput-root:before": {
                    borderBottom: "2px solid #0ba2de !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
                    borderBottom: "2px solid #f5079e !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                    {
                      borderBottom: "#f44336 !important",
                    },
                }}>
                <Autocomplete
                  id="combo-box-demo"
                  loading={loading}
                  onFocus={() => updateCourses()}
                  onChange={(event, value) => {
                    handleSelectedValue(value, setFieldValue);
                  }}
                  options={courses}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  value={selectedValue[0]}
                  getOptionLabel={(option) => option.courseName}
                  sx={{
                    gridColumn: "span 7",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Applied"
                      onBlur={handleBlur}
                      name="courseName"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="info" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={!!touched.courseName && !!errors.courseName}
                      helperText={touched.courseName && errors.courseName}
                    />
                  )}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  disabled
                  label="Course Code"
                  onBlur={handleBlur}
                  value={selectedValue[0].courseCode}
                  name="courseCode"
                  error={!!touched.courseCode && !!errors.courseCode}
                  helperText={touched.courseCode && errors.courseCode}
                  sx={{
                    gridColumn: "span 5",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  disabled
                  label="Course Level"
                  onBlur={handleBlur}
                  value={selectedValue[0].courseLevel}
                  name="courseLevel"
                  error={!!touched.courseLevel && !!errors.courseLevel}
                  helperText={touched.courseLevel && errors.courseLevel}
                  sx={{
                    gridColumn: "span 5",
                  }}
                />
                <Autocomplete
                  id="combo-box-demo"
                  loading={searching}
                  onFocus={() =>
                    fetchAvailableCourseIntakeClassSes(selectedValue[0])
                  }
                  onChange={(event, value) => {
                    setSelectedValue([
                      selectedValue[0],
                      {
                        id: value?.id ?? "",
                        classTitle: value?.classTitle ?? "",
                        currentSession: value?.currentSession ?? "",
                      },
                    ]);
                    setFieldValue("classTitle", value?.classTitle ?? "", true);
                    setFieldValue(
                      "currentSession",
                      value?.currentSession ?? "",
                      true
                    );
                    setFieldValue("intakeClassSes", value?.id ?? "", true);
                  }}
                  disabled={selectedValue[0].courseCode === ""}
                  options={availableClass}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  value={selectedValue[1]}
                  getOptionLabel={(option) => option.classTitle}
                  sx={{
                    gridColumn: "span 7",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Intake Class"
                      onBlur={handleBlur}
                      name="intakeClassSes"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {searching ? (
                              <CircularProgress color="info" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={
                        !!touched.intakeClassSes && !!errors.intakeClassSes
                      }
                      helperText={
                        touched.intakeClassSes && errors.intakeClassSes
                      }
                    />
                  )}
                />
                <FormField
                  err={!!touched.sponsorship && !!errors.sponsorship}
                  errorMessage={touched.sponsorship && errors.sponsorship}
                  mainProps={{
                    name: "sponsorship",
                    value: values.sponsorship,
                  }}
                  formLabel="SponsorShip"
                  optionalProps={{
                    style: { gridColumn: "span 12", gridRow: "span 2" },
                    onChange: handleChange,
                  }}
                  radioBtn={[
                    { value: "self", label: "Self Sponsored" },
                    { value: "parent", label: "Parent / Guardian" },
                    { value: "organization", label: "Organization" },
                  ]}
                />
                {(values.sponsorship === "parent" ||
                  values.sponsorship === "organization") && (
                  <>
                    {" "}
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Name Of Sponsor"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.nameOfSponsor}
                      name="nameOfSponsor"
                      error={!!touched.nameOfSponsor && !!errors.nameOfSponsor}
                      helperText={touched.nameOfSponsor && errors.nameOfSponsor}
                      sx={{
                        gridColumn: "span 5",
                        gridRow: "span 2",
                      }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Telephone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.telOfSponsor}
                      name="telOfSponsor"
                      error={!!touched.telOfSponsor && !!errors.telOfSponsor}
                      helperText={touched.telOfSponsor && errors.telOfSponsor}
                      sx={{
                        gridColumn: "span 4",
                        gridRow: "span 2",
                      }}
                    />
                  </>
                )}

                <FormField
                  err={!!touched.modeOfStudy && !!errors.modeOfStudy}
                  errorMessage={touched.modeOfStudy && errors.modeOfStudy}
                  mainProps={{
                    name: "modeOfStudy",
                    value: values.modeOfStudy,
                  }}
                  optionalProps={{
                    style: { gridColumn: "span 12" },
                    onChange: handleChange,
                  }}
                  formLabel="Mode Of Study"
                  radioBtn={[
                    { value: "morning", label: "Morning" },
                    { value: "fullTime", label: "fullTime" },
                    { value: "evening", label: "Evening" },
                    { value: "online", label: "Online" },
                  ]}
                />
              </Box>
              <Box display="flex" justifyContent="space-between" mt="50px">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    handlePagePrev({
                      courseInfo: {
                        ...values,
                        courseLevel: selectedValue.courseLevel,
                      },
                    })
                  }>
                  PREVIOUS
                </Button>{" "}
                <Button type="submit" color="secondary" variant="contained">
                  NEXT
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
}
const checkoutSchema = yup.object().shape({
  courseCode: yup.string().required("required"),
  courseId: yup.number().required("required"),
  nameOfSponsor: yup.string().when("sponsorship", {
    is: "self",
    then: yup.string().optional(),
    otherwise: yup.string().required("required"),
  }),
  telOfSponsor: yup.string().when("sponsorship", {
    is: "self",
    then: yup.string().optional(),
    otherwise: yup.string().required("required"),
  }),
  courseLevel: yup.string().required("required"),
  courseName: yup.string().required("required"),
  sponsorship: yup.string().required("You must select from one of the buttons"),
  modeOfStudy: yup.string().required("You must select from one of the buttons"),
  intakeClassSes: yup.number().required("required"),
  currentSession: yup.string().optional(),
});
