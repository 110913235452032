import React from "react";
import { Box, useTheme, TextField, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../../theme";
import AddFeeStructureDialog from "../addFeeStructureModal";
import { useState } from "react";
import { useValue } from "../../../context/contextProvider";
import { LoadingButton } from "@mui/lab";
import { updateFeeStructure } from "../../../actions/feestructure";

export default function CourseFeeStructure({ course }) {
  const {
    state: { courseFeeStructure, currentUser, tempCourseFeeStructure },
    dispatch,
  } = useValue();
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (panel) => (event, isExpanded) => {
    if (course?.feeStructureStatus === "incomplete" && disabled) return;
    setExpanded(isExpanded ? panel : false);
  };

  const handleCancelChanges = () => {
    if (courseFeeStructure?.edited)
      dispatch({
        type: "UPDATE_FEESTRUCTURE",
        payload: {
          ...tempCourseFeeStructure,
        },
      });
    setDisabled(true);
    setExpanded(false);
    return;
  };
  const handleSetFeeStructure = () => {
    if (courseFeeStructure?.edited) return;
    if (course?.feeStructureStatus === "null") {
      setOpen(true);
    } else {
      if (!expanded) setExpanded("panel1");
      setDisabled(false);
    }
    return;
  };
  const handleUpdateFeeStructure = async () => {
    setLoading(true);
    const result = await updateFeeStructure(
      dispatch,
      currentUser.token,
      courseFeeStructure
    );
    if (result) {
      dispatch({
        type: "UPDATE_TEMPFEESTRUCTURE",
        payload: null,
      });
      setDisabled(true);
      setExpanded(false);
    }
    setLoading(false);
  };
  //   months
  const totalFees = (fees) => {
    let total = 0;
    fees.map((fee) => {
      total = total + fee.amount;
      return total;
    });
    return total;
  };
  const handleTermChange = (e, data, dt) => {
    if (tempCourseFeeStructure === null)
      dispatch({
        type: "UPDATE_TEMPFEESTRUCTURE",
        payload: {
          ...courseFeeStructure,
          edited: false,
        },
      });
    const value = Number(e.target.value);
    const newTerm = data.fees.map((fee) => {
      const termFees = fee.term === dt.term ? { ...dt, amount: value } : fee;
      return termFees;
    });
    const total = totalFees(newTerm);
    const newData = { ...data, fees: newTerm, total: total };
    const newCourseFeeStructure = courseFeeStructure.feeStructure.map(
      (feeStruct) => {
        const yearFees = feeStruct.year === data.year ? newData : feeStruct;
        return yearFees;
      }
    );
    dispatch({
      type: "UPDATE_FEESTRUCTURE",
      payload: {
        ...courseFeeStructure,
        feeStructure: newCourseFeeStructure,
        edited: true,
      },
    });
  };
  return (
    <Box display="flex" flexDirection="column" gap="30px">
      <AddFeeStructureDialog {...{ setOpen, open, course }} />
      <Box display="flex" gap="40px">
        {courseFeeStructure?.edited && (
          <Button
            onClick={handleCancelChanges}
            color="info"
            variant="contained">
            CANCEL CHANGES
          </Button>
        )}

        {!courseFeeStructure?.edited && (
          <Button
            disabled={!disabled || loading || courseFeeStructure?.edited}
            onClick={handleSetFeeStructure}
            // sx={{ color: "#fff" }}
            color="info"
            variant="contained">
            {course?.feeStructureStatus === "null" && "ADD FEE STRUCTURE"}
            {course?.feeStructureStatus === "completed" && "EDIT FEE STRUCTURE"}
            {course?.feeStructureStatus === "incomplete" &&
              "COMPLETE FEE STRUCTURE"}
          </Button>
        )}

        {courseFeeStructure?.edited && (
          <LoadingButton
            type="submit"
            disabled={!courseFeeStructure?.edited}
            loading={loading}
            variant="contained"
            onClick={handleUpdateFeeStructure}
            // color="secondary"
            // loadingIndicator="Loading…"
            sx={{ color: "info" }}>
            <span>
              {" "}
              {course?.feeStructureStatus === "completed" && "SAVE CHANGES"}
              {course?.feeStructureStatus === "incomplete" &&
                "SAVE FEE STRUCTURE"}
            </span>
          </LoadingButton>
        )}
      </Box>
      <Box overflow="auto" ml="-10px" mr="-10px">
        <Box backgroundColor={colors.primary[500]}>
          <Box p="10px 10px 10px 10px">
            {courseFeeStructure?.id &&
              courseFeeStructure.feeStructure.length > 0 &&
              courseFeeStructure.feeStructure.map((data) => {
                return (
                  <Accordion
                    disabled={loading}
                    expanded={expanded === "panel" + data.year}
                    onChange={handleChange("panel" + data.year)}
                    sx={{ bgcolor: colors.primary[500] }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ flexShrink: 0 }}>
                        {"Year " + data.year} -{" "}
                        {"Total Fees :  kshs " + data.total}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {data.fees.map((dt) => {
                        return (
                          <Box key={dt.term} mb={2} pl={7}>
                            {" "}
                            <span>Term {dt.term} : </span>
                            <TextField
                              color="info"
                              disabled={loading || disabled}
                              required
                              type="number"
                              name="term"
                              value={dt.amount > 0 && dt.amount}
                              onChange={(e) => handleTermChange(e, data, dt)}
                              sx={{ width: 250 }}
                              label="Total amount"
                            />
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
