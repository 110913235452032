import * as React from "react";
import { Box, Button, Stack, TextField, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { useValue } from "../../../context/contextProvider";
import { addSalary } from "../../../actions/salary";
import { tokens } from "../../../theme";

const RecordSalaryForm = ({ next, resetSalaryState }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    dispatch,
    state: { currentUser },
  } = useValue();
  const handleFormSubmit = (values) => {
    updateConfirmDialogWithList(values);
  };
  const recordSalary = async (salaryData) => {
    await addSalary(currentUser.token, dispatch, salaryData);
    resetSalaryState();
  };
  const handleFormReset = (resetForm) => {
    resetSalaryState();
    resetForm();
  };
  const updateConfirmDialog = (act) => {
    const message =
      "You are about to reset this form all information will be deleted. Are you sure you want to proceed? Be Careful !";
    const title = "Form Reset";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: handleFormReset,
      },
    });
  };
  const updateConfirmDialogWithList = (act) => {
    const message =
      "You are about to submit the following record, Please confirm before submiting!";
    const title = "Record Salary";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG_WITH_LIST",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: recordSalary,
        formData: initialValues,
      },
    });
  };

  //   monthsData
  // const months = [
  //   {
  //     value: "January",
  //     label: "January",
  //   },
  //   {
  //     value: "February",
  //     label: "February",
  //   },
  //   {
  //     value: "March",
  //     label: "March",
  //   },
  //   {
  //     value: "April",
  //     label: "April",
  //   },
  //   {
  //     value: "May",
  //     label: "May",
  //   },
  //   {
  //     value: "June",
  //     label: "June",
  //   },
  //   {
  //     value: "July",
  //     label: "July",
  //   },
  //   {
  //     value: "August",
  //     label: "August",
  //   },
  //   {
  //     value: "September",
  //     label: "September",
  //   },
  //   {
  //     value: "October",
  //     label: "October",
  //   },
  //   {
  //     value: "November",
  //     label: "November",
  //   },
  //   {
  //     value: "December",
  //     label: "December",
  //   },
  // ];

  return (
    <Box
      py={3}
      // px={1}
      backgroundColor={colors.primary[400]}
      borderRadius={1}
      height="400px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, ...next }}
        validationSchema={checkoutSchema}>
        {({
          resetForm,
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack
              direction="column"
              spacing={4}
              sx={{
                px: 1,
                overflow: "auto",
                maxHeight: "400px",
                backgroundColor: colors.primary[400],
              }}>
              <TextField
                fullWidth
                disabled
                variant="filled"
                type="text"
                label="Full Names"
                onBlur={handleBlur}
                onChange={handleChange}
                value={next?.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                color="info"
              />

              <TextField
                fullWidth
                disabled
                variant="filled"
                type="text"
                label="Position"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameTitle}
                name="nameTitle"
                error={!!touched.nameTitle && !!errors.nameTitle}
                helperText={touched.nameTitle && errors.nameTitle}
                color="info"
              />

              <FormControl
                sx={{
                  "& .css-w3lmqu-MuiFormLabel-root.Mui-focused": {
                    color: "#f2f0f0 !important",
                  },
                  "& .css-1a9y42x-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                    { color: "#0ba2de !important" },
                }}
                error={!!touched.duration && !!errors.duration}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Method Of Payment
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="methodOfPayment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.methodOfPayment}>
                  <FormControlLabel
                    value="cash"
                    control={<Radio />}
                    label="Cash"
                  />
                  <FormControlLabel
                    value="mpesa"
                    control={<Radio />}
                    label="M-pesa"
                  />
                  <FormControlLabel
                    value="bank"
                    control={<Radio />}
                    label="Bank"
                  />
                </RadioGroup>
                <FormHelperText>
                  {touched.methodOfPayment && errors.methodOfPayment}
                </FormHelperText>
              </FormControl>

              {values.methodOfPayment !== "" && (
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={`Enter The Receipt No (${values.methodOfPayment})`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.receiptNo}
                  name="receiptNo"
                  error={!!touched.receiptNo && !!errors.receiptNo}
                  helperText={touched.receiptNo && errors.receiptNo}
                  color="info"
                />
              )}
              {values.methodOfPayment !== "" && (
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label={`Enter The Amount Paid (Kshs)`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  error={!!touched.amount && !!errors.amount}
                  helperText={touched.amount && errors.amount}
                  color="info"
                />
              )}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                multiline
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                color="info"
              />
              <Stack direction="row" justifyContent="space-between">
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => updateConfirmDialog(resetForm)}>
                  RESET FORM
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                  SUBMIT
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  receiptNo: yup.string().required("required"),
  amount: yup.number().required("required"),
  methodOfPayment: yup.string().required("Select one of the buttons above"),
  nameTitle: yup.string().required("Required"),
  fullName: yup.string().required("Required"),
});

const initialValues = {
  id: "",
  fullName: "",
  nameTitle: "",
  receiptNo: "",
  methodOfPayment: "",
  amount: "",
  description: "",
};

export default RecordSalaryForm;
