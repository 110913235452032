import * as React from "react";
import { Box, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Link, useLocation } from "react-router-dom";
import ManageCourseInClass from "../../components/class/manageCourses";
import ClassCourseList from "../../components/class/classCourseList";
import { useValue } from "../../context/contextProvider";
import { useEffect } from "react";
import { useCallback } from "react";
// import { getIntakeClass } from "../../actions/intakeClass";
import { useState } from "react";
import { getIntakeClassSesCourses } from "../../actions/intakeClassSession";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ClassView = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState();
  const {
    state: { currentUser, intakeClass },
    dispatch,
  } = useValue();
  const cid = useLocation().pathname.split("/")[2];

  // const fetchIntakeClass = async () => {
  //   if (cid) {
  //     if (currentUser !== null && currentUser.token) {
  //       setLoading(true);
  //       await getIntakeClass(cid, dispatch, currentUser.token);
  //       setLoading(false);
  //     }
  //   }
  // };
  // const fetchIntakeClassSession = async () => {
  //   if (cid) {
  //     if (currentUser !== null && currentUser.token) {
  //       setLoading(true);
  //       await getIntakeClassSesCourses(cid, dispatch, currentUser.token);
  //       setLoading(false);
  //     }
  //   }
  // };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // if (!intakeClass) fetchIntakeClass();
    // if (newValue === 0) fetchIntakeClassSession();
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const fetchIntakeClassSes = useCallback(async () => {
    if (cid) {
      if (currentUser !== null && currentUser.token) {
        setLoading(true);
        await getIntakeClassSesCourses(cid, dispatch, currentUser.token);
        setLoading(false);
      }
    }
  }, [cid, currentUser, dispatch]);

  useEffect(() => {
    fetchIntakeClassSes();
  }, [fetchIntakeClassSes]);
  return (
    <Box m="20px">
      <Tooltip title="Back">
        <Link
          to="/class"
          style={{ textDecoration: "none", color: colors.grey[100] }}>
          <ArrowBackOutlinedIcon sx={{ fontSize: 20, mt: -4 }} />
        </Link>
      </Tooltip>
      <Header title="CLASS VIEW" subtitle="Manage Class & Course" />
      <Box
        m="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="180px"
        gap="1px">
        <Box gridColumn="span 8">
          <AppBar
            position="static"
            sx={{ bgcolor: colors.primary[400], ml: 10 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example">
              <Tab
                label="Manage Courses"
                {...a11yProps(0)}
                sx={{
                  color:
                    value === 0 ? "#0ba2de !important" : "#f1f1f1 !important",
                  fontWeight: value === 0 ? "bold !important" : undefined,
                }}
              />

              <Tab
                disabled
                //   label="Transaction History"
                {...a11yProps(1)}
                sx={{
                  color:
                    value === 1 ? "#0ba2de !important" : "#f1f1f1 !important",
                  fontWeight: value === 1 ? "bold !important" : undefined,
                }}
              />
              <Tab
                disabled
                //   label="Fee Statement"
                {...a11yProps(2)}
                sx={{
                  color:
                    value === 2 ? "#0ba2de !important" : "#f1f1f1 !important",
                  fontWeight: value === 2 ? "bold !important" : undefined,
                }}
              />
            </Tabs>
          </AppBar>
          <Box
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box
                m="0 0 0 0"
                height="75vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}>
                <Typography color="#0ba2de" fontWeight="bold" fontSize="17px">
                  LIST OF COURSES
                </Typography>
                {!loading && intakeClass && (
                  <ManageCourseInClass
                    // classData={classData}
                    // searchedCourses={searchedCourses}
                    isLoading={loading}
                  />
                )}
                <ClassCourseList {...{ loading }} />
              </Box>
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <Box
                m="0 0 0 0"
                height="75vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}>
                {/* <Typography color="#0ba2de" fontWeight="bold" fontSize="17px">
                TRANSACTION HISTORY
              </Typography> */}
              </Box>
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
              <Box
                m="0 0 0 0"
                height="75vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}>
                {/* <Typography color="#0ba2de" fontWeight="bold" fontSize="17px">
                  PROCESS FEE STATEMENT
                </Typography> */}
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClassView;
