import * as React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useValue } from "../../../context/contextProvider";
import { login } from "../../../actions/user";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Login() {
  const {
    state: { loginLoading, currentUser },
    dispatch,
  } = useValue();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleFormSubmit = async (values) => {
    await login(values, dispatch);
  };

  const style = { backgroundColor: colors.primary[500] };
  return (
    <>
      <Dialog
        open={!currentUser}
        style={{ backgroundColor: colors.primary[600] }}
        keepMounted>
        <DialogTitle style={style}>
          {" "}
          <Typography textAlign="center" fontSize="19px">
            Login Form
          </Typography>
        </DialogTitle>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} method="POST">
              {" "}
              <DialogContent style={style} dividers>
                <Box
                  display="grid"
                  gap="25px"
                  gridTemplateColumns="repeat(12, 1fr)"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    variant="filled"
                    type="text"
                    color="info"
                    label="userName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userName}
                    name="userName"
                    error={!!touched.userName && !!errors.userName}
                    helperText={touched.userName && errors.userName}
                    sx={{
                      gridColumn: "span 12",
                      "& .Mui-focused": {
                        color: "#f2f0f0 !important",
                        input: {
                          color: "#f2f0f0 !important",
                        },
                      },
                      "& .Mui-focused.Mui-error": {
                        color: "#f44336 !important",
                      },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                        {
                          borderBottom: "2px solid #0ba2de !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after":
                        {
                          borderBottom: "2px solid #f5079e !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                        {
                          borderBottom: "#f44336 !important",
                        },
                    }}
                  />

                  <TextField
                    fullWidth
                    autoComplete="off"
                    variant="filled"
                    type="password"
                    label="Password"
                    color="info"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{
                      gridColumn: "span 12",
                      "& .Mui-focused": {
                        color: "#f2f0f0 !important",
                        input: {
                          color: "#f2f0f0 !important",
                        },
                      },
                      "& .Mui-focused.Mui-error": {
                        color: "#f44336 !important",
                      },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                        {
                          borderBottom: "2px solid #0ba2de !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after":
                        {
                          borderBottom: "2px solid #f5079e !important",
                        },
                      "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                        {
                          borderBottom: "#f44336 !important",
                        },
                    }}
                  />
                </Box>
              </DialogContent>
              <DialogActions style={style}>
                <LoadingButton
                  type="submit"
                  size="large"
                  fullWidth
                  loading={loginLoading}
                  color="success"
                  startIcon=""
                  loadingPosition="start"
                  variant="contained">
                  <span>{loginLoading ? "Loading" : "LOGIN"} </span>
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
const checkoutSchema = yup.object().shape({
  userName: yup.string().required("required"),
  password: yup.string().required("required"),
});
const initialValues = {
  userName: "",
  password: "",
};
