import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useValue } from "../context/contextProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Confirmation() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    state: { confirmDialog },
    dispatch,
  } = useValue();

  const handleClose = () => {
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: { open: false, title: " ", message: "", action: "", func: null },
    });
  };
  const handleProceed = async () => {
    handleClose();
    await confirmDialog.func(confirmDialog.action);
  };
  const style = { backgroundColor: colors.primary[500], color: "#fff" };
  return (
    <div>
      <Dialog
        open={confirmDialog.open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={style}>{confirmDialog.title}</DialogTitle>
        <DialogContent sx={{ ...style, p: 4 }}>
          <DialogContentText id="alert-dialog-slide-description">
            {confirmDialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ ...style, px: 10, justifyContent: "space-between" }}>
          <Button onClick={handleClose}>
            <Typography color="white !important">Cancel</Typography>
          </Button>
          <Button onClick={handleProceed}>
            <Typography color="white !important">Proceed</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
