import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Tab, Tabs, AppBar } from "@mui/material";
import PersonalInfo from "./registrationForms/PersonalInfo";
import { useValue } from "../../../context/contextProvider";
import CourseInfo from "./registrationForms/CourseInfo";
import { tokens } from "../../../theme";
import Confirmation from "./registrationForms/Confirmation";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function StudentFormTab() {
  const {
    dispatch,
    state: {
      studentsRegistrationForm: { studentFormTab },
    },
  } = useValue();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const submitStudent = (values) => {
    dispatch({
      type: "UPDATE_STUDENT_FORM",
      payload: values,
    });
  };
  const handlePage = (values) => {
    // e.preventDefault();
    const newValue = studentFormTab.value + 1;
    handleChangeIndex(newValue, newValue, values);
  };

  const handlePagePrev = (values) => {
    const newValue = studentFormTab.value - 1;
    handleChangeIndex(newValue, studentFormTab.page, values);
  };

  const handleChange = (event, newValue) => {
    // e.preventDefault();
    handleChangeIndex(newValue, studentFormTab.page);
  };

  const handleChangeIndex = (index, newPage, values = "") => {
    const newValues = values
      ? { ...values, studentFormTab: { value: index, page: newPage } }
      : { studentFormTab: { value: index, page: newPage } };
    submitStudent(newValues);
  };
  return (
    <Box
      sx={{
        bgcolor: "inherit",
        m: "20px 6%",
        border: `2px solid  ${colors.primary[300]}`,
        borderRadius: "5px",
      }}>
      <AppBar position="static" sx={{ bgcolor: "inherit" }}>
        <Tabs
          value={studentFormTab.value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example">
          <Tab label="Personal Information" {...a11yProps(0)} />
          <Tab
            label="Course Information"
            disabled={
              studentFormTab.page < 1 ||
              (studentFormTab.page > 0 && studentFormTab.value === 0)
            }
            {...a11yProps(1)}
          />
          {/* <Tab
            label="Uploads"
            disabled={page < 2 || (page > 1 && value < 2)}
            {...a11yProps(2)}
          /> */}
          <Tab
            label="Comfirmation"
            disabled={studentFormTab.value < 2}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <Box
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={studentFormTab.value}>
        <TabPanel value={studentFormTab.value} index={0} dir={theme.direction}>
          <PersonalInfo handlePage={handlePage} />
        </TabPanel>
        <TabPanel value={studentFormTab.value} index={1} dir={theme.direction}>
          <CourseInfo handlePage={handlePage} handlePagePrev={handlePagePrev} />
        </TabPanel>
        {/* <TabPanel value={value} index={2} dir={theme.direction}>
          <Uploads handlePage={handlePage} handlePagePrev={handlePagePrev} />
        </TabPanel> */}
        <TabPanel value={studentFormTab.value} index={2} dir={theme.direction}>
          <Confirmation />
        </TabPanel>
      </Box>
    </Box>
  );
}
