import React from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link, useLocation } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Tooltip from "@mui/material/Tooltip";
import SimpleBackdrop from "../../components/progress/backdrop";
import { useValue } from "../../context/contextProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import EditableDisplayField from "../../components/utils/textFields/editableDisplayField.js";
import useCheckToken from "../../hooks/useCheckToken";
import { useCallback } from "react";
import { deleteStaff, getStaff } from "../../actions/staff";
import StaffProfileTab from "../../components/staff/staffProfile/staffProfileTab";

const StaffView = () => {
  useCheckToken();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    state: { loading, staff, currentUser },
    dispatch,
  } = useValue();
  const navigate = useNavigate();
  const sid = useLocation().pathname.split("/")[2];

  const updateConfirmDialog = (act) => {
    const message =
      "You are about to delete this staff. Are you sure you want to proceed?";

    const title = "Staff delete";

    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: handleConfirmedAction,
      },
    });
  };
  const handleConfirmedAction = async (act) => {
    if (act === "delete")
      await deleteStaff(staff.staffId, dispatch, currentUser.token, navigate);
    return;
  };
  const fetchStaff = useCallback(async () => {
    if (sid) {
      if (currentUser !== null && currentUser.token) {
        await getStaff(sid, dispatch, currentUser.token, navigate);
      }
    }
  }, [sid, currentUser, dispatch, navigate]);
  useEffect(() => {
    fetchStaff();
  }, [fetchStaff]);
  const tempStaffInfo =
    staff.fieldId !== 39 ? { formLabel: "Field", value: staff.fieldName } : "";
  const staffInfo = [
    { formLabel: "Designation", value: staff.nameTitle },
    { formLabel: "Category", value: staff.category },
    { formLabel: "Salary", value: staff.salary },
    tempStaffInfo,
  ];
  return (
    <>
      {loading ? <SimpleBackdrop {...{ loading }} /> : null}
      {!loading && staff && staff.staffId && (
        <Box m="20px">
          <Tooltip title="Return Back">
            <Link
              to="/staff"
              style={{ textDecoration: "none", color: colors.grey[100] }}>
              <ArrowBackOutlinedIcon sx={{ fontSize: 20, mt: -4 }} />
            </Link>
          </Tooltip>

          {/* HEADER */}

          <Header
            title="STAFF VIEW"
            subtitle="Edit and Manage Staff Information"
          />

          {/* GRID  */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="auto"
            px={10}
            mt={8}
            gap="20px">
            {/* ROW 1 */}
            <Paper
              elevation={4}
              sx={{
                gridColumn: "span 5",
                bgcolor: colors.primary[400],
                justifyContent: "center",
                alignItems: "center",
                p: 4,
              }}>
              <Avatar
                alt="user-default-profile-pic"
                src={process.env.REACT_APP_DEFAULT_PROFILE_PROURL}
                sx={{ cursor: "pointer", width: 95, height: 95 }}
              />
            </Paper>
            <Paper
              elevation={4}
              sx={{
                gridColumn: "span 7",
                bgcolor: colors.primary[400],
                justifyContent: "center",
                alignItems: "center",
                p: 4,
              }}>
              <Typography
                variant="h4"
                fontWeight="600"
                color={colors.grey[100]}
                textTransform="uppercase">
                {staff && staff.fullName}
              </Typography>
              <Typography
                textTransform="capitalize"
                variant="h5"
                fontWeight="bold"
                color={colors.greenAccent[500]}>
                {staff && staff.snationality}
              </Typography>

              <Stack
                direction="column"
                sx={{
                  pt: "17px",
                  justifyContent: "space-between",
                }}>
                {staff?.staffStatus !== "admin" && (
                  <Button
                    disabled={staff?.staffStatus === "admin"}
                    size="small"
                    sx={{ width: "16%" }}
                    onClick={() => updateConfirmDialog("delete")}
                    color="error"
                    title="Delete this staff"
                    variant="outlined"
                    startIcon={<Delete />}>
                    Delete
                  </Button>
                )}
              </Stack>
            </Paper>

            {/* ROW 2 */}
            <Paper
              elevation={4}
              sx={{
                gridColumn: "span 5",
                bgcolor: colors.primary[400],
                justifyContent: "center",
                alignItems: "center",
                p: 4,
              }}>
              <Typography
                textTransform="capitalize"
                variant="h5"
                fontWeight="Bold"
                color={colors.grey[100]}>
                Staff Position
              </Typography>
              <Divider variant="inset" sx={{ opacity: 0.6 }} />
              <List>
                {staffInfo.map((info, i) => (
                  <EditableDisplayField
                    key={i}
                    formLabel={info.formLabel}
                    mainProps={{ value: info.value }}
                    optionalProps={{
                      primary: {
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: colors.blueAccent[700],
                      },
                      secondary: {
                        variant: "h6",
                        color: colors.grey[200],
                        textTransform: "capitalize",
                      },
                    }}
                  />
                ))}
              </List>
            </Paper>

            <Paper
              elevation={4}
              sx={{
                gridColumn: "span 7",
                gridRow: "span 1",
                bgcolor: colors.primary[400],
                justifyContent: "center",
                alignItems: "center",
                p: 1,
              }}>
              <StaffProfileTab staff={staff} />
            </Paper>
          </Box>
        </Box>
      )}
    </>
  );
};

export default StaffView;
