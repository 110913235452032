import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "./reducer";
import jwtDecode from "jwt-decode";

const initialState = {
  currentUser: null,
  // tempCourses: [
  //   { name: "Diploma in Community Health and Development", code: "DCHD" },
  //   { name: "Diploma in Social Work and Community Development", code: "DSWCD" },
  //   { name: "Diploma in Counseling, Guiding and Psychology", code: "DCGP" },
  //   {
  //     name: "Diploma in Development Studies with Entrepreneurship Skills",
  //     code: "DDSES",
  //   },
  //   {
  //     name: "Certificate in Community Health and Development",
  //     code: "CCHD",
  //   },
  //   {
  //     name: "Certificate in Social Work and Community Development ",
  //     code: "CSWCD",
  //   },
  //   {
  //     name: "Certificate in Counseling, Guiding and Psychology ",
  //     code: "CCGP",
  //   },
  //   {
  //     name: "Certificate in Development Studies with Entrepreneurship Skills",
  //     code: "CDSES",
  //   },
  //   { name: "Diploma in Information Communication Technology ", code: "DICT" },
  //   { name: "Diploma in Information Technology	", code: "DIT" },
  //   { name: "Diploma in Journalism Media", code: "DJM" },
  //   { name: " Diploma in Broadcasting Journalism & Media", code: "DBJM" },
  //   {
  //     name: "Certificate in Information Communication Technology",
  //     code: "CICT",
  //   },
  //   { name: "Certificate in Information Technology ", code: "DIT" },
  //   { name: " Certificate in Journalism and Media Broadcasting", code: "CJMB" },
  //   { name: " Certificate in Journalism Radio and TV ", code: "CJRTV" },
  //   { name: "Certificate in Photography", code: "CP" },
  //   { name: "Certificate in Broadcasting Journalism & Media", code: "CBJM" },
  //   { name: " Artisan in Secretarial Studies", code: "ASS" },
  //   { name: " Artisan in Storekeeping", code: "ASK" },
  //   { name: " Artisan in Salesmanship", code: "AS" },
  //   { name: " Certificate in Business Management", code: "CBM" },
  //   { name: " Certificate in Human Resource Management", code: "CHRM" },
  //   { name: " Certificate in Supply Chain Management", code: "CSCM" },
  //   { name: " Certificate in Accountancy", code: " CA" },
  //   { name: " Certificate in Sales and Marketing", code: "CSM" },
  //   { name: " Certificate in Computerized Secretarial Studies", code: "CCSS" },
  //   { name: " Certificate in Banking and Finance", code: "CBF" },
  //   { name: " Certificate in Project Management", code: "CPM" },
  //   { name: " Certificate in Entrepreneurship Development", code: "CED" },
  //   { name: " Certificate in Clerical Operations", code: "CCO" },
  //   { name: " Diploma in Business Management", code: "DBM" },
  //   { name: " Diploma in Human Resource Management", code: "DHRM" },
  //   { name: " Diploma in Entrepreneurship Development", code: "DED" },
  //   { name: " Diploma in Project Management", code: "DPM" },
  //   { name: " Diploma in Banking and Finance", code: "DBF" },
  //   { name: " Diploma in Computerized Secretarial Studies", code: "DCSS" },
  //   { name: " Diploma in Sales and Marketing", code: "DSM" },
  //   { name: " Diploma in Accountancy", code: "DA" },
  //   { name: " Diploma in Supply Chain Management", code: "DSCM" },
  //   { name: " Diploma in Business Administration", code: "DBA" },
  //   { name: " Certified Public Accountants", code: "CPA" },
  //   { name: " Certified Secretaries", code: "CS" },
  //   { name: " Certified Information Communication Technology", code: "CICT" },
  //   { name: " Accounting Technicians Diploma", code: "ATD" },
  //   { name: " Diploma in Information Communication Technology", code: "DICT" },
  //   { name: " Diploma in Credit Management", code: "DCM" },
  //   {
  //     name: " Certificate in Accounting and Management Skills ",
  //     code: "CAMS",
  //   },
  //   { name: " Computer Packages	", code: "CP" },
  //   { name: " Accounting Packages(QuickBooks, Pastel, Sage)", code: "APS" },
  //   {
  //     name: " Graphic Design (Corel Draw, Photoshop Adobe Illustrator) ",
  //     code: "GD",
  //   },
  //   { name: " Video Editing and Photography	", code: "VEP" },
  // ],

  /// staffs ///
  staffs: [],
  staff: {},
  staffsPage: { index: 1 },
  staffsRegistrationForm: {
    staffFormTab: { value: 0, page: 0 },
    otherInfo: {
      selectedValue: {
        name: "",
        code: "",
      },
      field: "",
      fieldName: "",
      designation: "",
      nameTitle: "",
      category: "",
    },
    personalInfo: {
      fullName: "",
      phone: "",
      email: "",
      sgender: "",
      snationality: "",
      maritalStatus: "",
      idNumber: "",
      DOB: new Date().toISOString(),
      county: "",
      location: "",
      placeOfBirth: "",
    },
  },

  /// students ///
  students: [],
  student: {},
  studentsPage: { index: 0 },
  studentsRegistrationForm: {
    studentFormTab: { value: 0, page: 0 },
    courseInfo: {
      selectedValue: {
        name: "",
        code: "",
      },
      courseId: "",
      courseCode: "",
      courseName: "",
      courseLevel: "",
      intakeClassSes: "",
      classTitle: "",
      sponsorship: "",
      nameOfSponsor: "",
      telOfSponsor: "",
      modeOfStudy: "",
    },
    personalInfo: {
      fullNames: "",
      mobileNo: "",
      email: "",
      gender: "",
      nationality: "",
      maritalStatus: "",
      idNo: "",
      DOB: new Date().toISOString(),
      guardian: "",
      relationShip: "",
      tel: "",
    },
  },
  attachedCert: { file: null, fileTitle: "" },
  staffAttachedCert: { file: null, fileTitle: "" },
  currentForm: { index: 0 },
  editableFieldOpen: null,
  // educationBackground: {
  //   studyInfo: [],
  //   attachedCert: null,
  //   tc: "",
  // },
  alert: { open: false, severity: "info", message: "", act: "" },
  confirmDialog: {
    open: false,
    title: "",
    message: "",
    action: "",
    func: null,
  },
  confirmDialogWithList: {
    open: false,
    title: "",
    message: "",
    action: "",
    func: null,
    formData: null,
  },
  errors: [],
  loading: false,
  singleLoading: false,
  fileUploadLoading: false,
  loginLoading: false,
  designations: [],
  designationFormFields: { title: "", salary: "", category: "" },
  resources: {},
  salary: [],
  staffSalary: {},
  salaryHistry: [],
  expenditures: [],
  expenditure: {},
  intakeClasses: [],
  intakeClass: {},
  intakeClassSession: [],
  units: [],
  fieldsData: [],
  departments: [],
  courses: [],
  course: {},
  courseFeeStructure: null,
  tempCourseFeeStructure: null,
  courseClassSessions: [],
  feesHistry: [],
};

const context = createContext(initialState);

export const useValue = () => {
  return useContext(context);
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const studentsPage = JSON.parse(localStorage.getItem("studentsPage"));
    const studentsRegistrationForm = JSON.parse(
      localStorage.getItem("studentsRegistrationForm")
    );
    const staffsPage = JSON.parse(localStorage.getItem("staffsPage"));
    const staffsRegistrationForm = JSON.parse(
      localStorage.getItem("staffsRegistrationForm")
    );
    if (currentUser) {
      const decodedToken = jwtDecode(currentUser.token);
      if (decodedToken.exp * 1000 > new Date().getTime()) {
        dispatch({ type: "UPDATE_USER", payload: currentUser });
      } else {
        dispatch({ type: "LOGOUT_USER", payload: null });
      }
    }
    if (studentsPage) {
      dispatch({ type: "UPDATE_STUDENTS_PAGE", payload: studentsPage });
    }
    if (studentsRegistrationForm) {
      dispatch({
        type: "UPDATE_STUDENT_FORM",
        payload: studentsRegistrationForm,
      });
    }
    if (staffsPage) {
      dispatch({ type: "UPDATE_STAFFS_PAGE", payload: staffsPage });
    }
    if (staffsRegistrationForm) {
      dispatch({
        type: "UPDATE_STAFF_FORM",
        payload: staffsRegistrationForm,
      });
    }
  }, []);
  return (
    <context.Provider value={{ state, dispatch }}>{children}</context.Provider>
  );
};
export default ContextProvider;
