import * as React from "react";
import { Box, Button, Stack, TextField, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { searchField } from "../../../actions/field";
import { useValue } from "../../../context/contextProvider";
import { addCourse } from "../../../actions/course";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";

const AddCourseForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const [responsFields, setResponsField] = useState([]);
  // const [dynamicDescrField, setDynamicDescrField] = useState(0);
  // const [fields, setFieldsField] = useState({});
  const [searchedFields, setSearchedFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    state: { currentUser },
    dispatch,
  } = useValue();

  const navigate = useNavigate();
  let resetFormState = null;

  const searchFields = async (e) => {
    const fieldName = e.target.value;
    if (!fieldName || fieldName === "") return setSearchedFields([]);
    setLoading(true);
    const data = await searchField(currentUser.token, dispatch, fieldName);
    if (data && data.length) {
      setSearchedFields(data);
    } else {
      setSearchedFields([]);
    }

    setLoading(false);
  };
  const handleFormReset = (resetForm) => {
    if (resetForm === "courseSubmited") {
      resetFormState();
    } else {
      resetForm();
    }
    resetFormState = null;
    setSearchedFields([]);
  };
  const updateConfirmDialog = (act) => {
    const message =
      "You are about to reset this form all information will be deleted. Are you sure you want to proceed? Be Careful !";
    const title = "Form Reset";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: handleFormReset,
      },
    });
  };
  const updateConfirmDialogWithList = (act) => {
    const message =
      "You are about to submit the following record, Please confirm before submiting!";
    const title = "Add Course";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG_WITH_LIST",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: submitCourse,
        formData: initialValues,
      },
    });
  };

  const handleFormSubmit = (values) => {
    updateConfirmDialogWithList(values);
  };
  const submitCourse = async (course) => {
    const result = await addCourse(
      currentUser.token,
      course,
      dispatch,
      navigate
    );
    if (result === 1) handleFormReset("courseSubmited");
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // const addDescrField = (e) => {
  //   e.preventDefault();
  //   setDynamicDescrField(dynamicDescrField + 1);
  //   let val = `qualification_${dynamicDescrField + 2}`;

  //   setResponsField([...responsFields, { id: dynamicDescrField, name: val }]);

  //   setFieldsField([...fields, { dynamicDescrField: "" }]);
  // };
  const levels = [
    { label: "Short Course", value: "short course" },
    { label: "Artisan", value: "artisan" },
    { label: "Certificate", value: "certificate" },
    { label: "Diploma", value: "diploma" },
    // { label: "Degree", value: "degree" },
  ];
  return (
    <Box
      py={3}
      px={1}
      backgroundColor={colors.primary[400]}
      borderRadius={1}
      height="550px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          resetForm,
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {isSubmitting && resetFormState === null
              ? (resetFormState = resetForm)
              : ""}
            <Stack
              direction="column"
              spacing={4}
              sx={{
                px: 1,
                overflow: "auto",
                maxHeight: "500px",
                backgroundColor: colors.primary[400],
              }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                name="code"
                error={!!touched.code && !!errors.code}
                helperText={touched.code && errors.code}
                color="info"
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                color="info"
              />

              <Autocomplete
                limitTags={5}
                id="checkboxes-tags-demo"
                options={searchedFields}
                loading={loading}
                value={
                  values.id
                    ? {
                        id: values.id,
                        fieldName: values.fieldName,
                      }
                    : null
                }
                onChange={(event, value) => {
                  setFieldValue("fieldName", value?.fieldName);
                  setFieldValue("id", value?.id);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.fieldName}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.fieldName}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    color="info"
                    name="field"
                    {...params}
                    onChange={(event) => searchFields(event)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="info" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    error={!!touched.field && !!errors.field}
                    helperText={touched.field && errors.field}
                    label="School"
                    placeholder="School"
                  />
                )}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Select Level"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.level}
                select
                name="level"
                SelectProps={{
                  native: true,
                }}
                error={!!touched.level && !!errors.level}
                helperText={touched.level && errors.level}
                color="info">
                <option></option>
                {levels.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <FormControl
                sx={{
                  "& .css-w3lmqu-MuiFormLabel-root.Mui-focused": {
                    color: "#f2f0f0 !important",
                  },
                  "& .css-1a9y42x-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                    { color: "#0ba2de !important" },
                }}
                error={!!touched.duration && !!errors.duration}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Duration
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="duration"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.duration}>
                  <FormControlLabel
                    value="months"
                    control={<Radio />}
                    label="Months"
                  />
                  <FormControlLabel
                    value="years"
                    control={<Radio />}
                    label="Years"
                  />
                </RadioGroup>
                <FormHelperText>
                  {touched.duration && errors.duration}
                </FormHelperText>
              </FormControl>

              {values.duration !== "" && (
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label={`Enter The Value (${values.duration})`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.durationValue}
                  name="durationValue"
                  error={!!touched.durationValue && !!errors.durationValue}
                  helperText={touched.durationValue && errors.durationValue}
                  color="info"
                />
              )}
              {/* <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  multiline
                  label="Qualification"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.qualification}
                  name="qualification"
                  error={!!touched.qualification && !!errors.qualification}
                  helperText={touched.qualification && errors.qualification}
                  sx={{
                    gridColumn: "span 2",
                    "& .Mui-focused": {
                      color: "#f2f0f0 !important",
                      input: {
                        color: "#f2f0f0 !important",
                      },
                    },
                    "& .Mui-focused.Mui-error": {
                      color: "#f44336 !important",
                    },
                    "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:before":
                      {
                        borderBottom: "2px solid #0ba2de !important",
                      },
                    "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:after":
                      {
                        borderBottom: "2px solid #f5079e !important",
                      },
                    "& .Mui-error.css-1rv476z-MuiInputBase-input-MuiFilledInput-input":
                      {
                        color: "#f5079e !important",
                      },
                    "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                      {
                        borderBottom: "#f44336 !important",
                      },
                  }}
                />
                {responsFields.length > 0 &&
                  responsFields.map((responsField, index) => (
                    <TextField
                      key={responsField.id}
                      fullWidth
                      variant="filled"
                      multiline
                      type="text"
                      label={responsField.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[responsField.name]}
                      name={responsField.name}
                      sx={{
                        gridColumn: "span 2",
                        "& .Mui-focused": {
                          color: "#f2f0f0 !important",
                          input: {
                            color: "#f2f0f0 !important",
                          },
                        },
                        "& .Mui-focused.Mui-error": {
                          color: "#f44336 !important",
                        },
                        "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:before":
                          {
                            borderBottom: "2px solid #0ba2de !important",
                          },
                        "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:after":
                          {
                            borderBottom: "2px solid #f5079e !important",
                          },
                        "& .Mui-error.css-1rv476z-MuiInputBase-input-MuiFilledInput-input":
                          {
                            color: "#f5079e !important",
                          },
                        "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                          {
                            borderBottom: "#f44336 !important",
                          },
                      }}
                    />
                  ))}
                <Box display="flex" mt="-55px" ml="260px">
                  <IconButton onClick={addDescrField}>
                    <Tooltip title="Add Qualification">
                      <Add cursor="pointer" />
                    </Tooltip>
                  </IconButton>
                </Box> */}
              <Stack direction="row" justifyContent="space-between">
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => updateConfirmDialog(resetForm)}>
                  RESET FORM
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                  SUBMIT
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  code: yup.string().required("required"),
  title: yup.string().required("required"),
  id: yup.string().required("required"),
  level: yup.string().required("required"),
  duration: yup.string().required("Select one of the buttons above"),
  durationValue: yup.string().required("Required"),
});

const initialValues = {
  code: "",
  title: "",
  id: "",
  fieldName: "",
  level: "",
  duration: "",
  durationValue: "",
  qualification: "",
};

export default AddCourseForm;
