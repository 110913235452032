import * as React from "react";
import { Box, Button, Stack, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useValue } from "../../../../context/contextProvider";
import FormField from "../../../utils/textFields/FormField";
import { useState } from "react";

const PersonalInfo = ({ handlePage }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {
    state: { staffsRegistrationForm },
  } = useValue();
  const [dateValue, setDateValue] = useState();

  const handleFormSubmit = (values) => {
    handlePage({ personalInfo: values });
  };
  const maritalStatusOptions = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
  ];
  // const relationShipOptions = [
  //   { value: "parent", label: "parent" },
  //   { value: "guardian", label: "Guardian" },
  //   { value: "brother", label: "Brother" },
  //   { value: "sister", label: "Sister" },
  //   { value: "cousin", label: "Cousin" },
  // ];
  return (
    <>
      <Box m="20px 60px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={staffsRegistrationForm.personalInfo}
          validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  "& .Mui-focused": {
                    color: "#f5079e !important",
                    input: {
                      color: "#0ba2de !important",
                    },
                  },
                  "& .Mui-focused.Mui-error": {
                    color: "#f44336 !important",
                  },
                  "& .css-uc0k7-MuiInputBase-root-MuiFilledInput-root:before": {
                    borderBottom: "2px solid #0ba2de !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
                    borderBottom: "2px solid #f5079e !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                    {
                      borderBottom: "#f44336 !important",
                    },
                }}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Full Names"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullName}
                  name="fullName"
                  error={!!touched.fullName && !!errors.fullName}
                  helperText={touched.fullName && errors.fullName}
                  sx={{
                    gridColumn: "span 5",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="ID/Passport"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.idNumber}
                  name="idNumber"
                  error={!!touched.idNumber && !!errors.idNumber}
                  helperText={touched.idNumber && errors.idNumber}
                  sx={{
                    gridColumn: "span 3",
                  }}
                />
                <FormField
                  err={!!touched.sgender && !!errors.sgender}
                  errorMessage={touched.sgender && errors.sgender}
                  formLabel="Gender"
                  mainProps={{
                    name: "sgender",
                    value: values.sgender,
                  }}
                  optionalProps={{
                    style: { gridColumn: "span 4" },
                    onChange: handleChange,
                  }}
                  radioBtn={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                  ]}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {" "}
                  <DatePicker
                    disableFuture
                    label="Date Of Birth"
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={dateValue}
                    name="DOB"
                    onChange={(newValue) => {
                      setDateValue(newValue);

                      values.DOB = newValue.$d;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          gridColumn: "span 4",
                        }}
                        error={!!touched.DOB && !!errors.DOB}
                        helperText={touched.DOB && errors.DOB}
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Place Of Birth"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.placeOfBirth}
                  name="placeOfBirth"
                  error={!!touched.placeOfBirth && !!errors.placeOfBirth}
                  helperText={touched.placeOfBirth && errors.placeOfBirth}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="County"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.county}
                  name="county"
                  error={!!touched.county && !!errors.county}
                  helperText={touched.county && errors.county}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Location"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                  name="location"
                  error={!!touched.location && !!errors.location}
                  helperText={touched.location && errors.location}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Nationality"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.snationality}
                  name="snationality"
                  error={!!touched.snationality && !!errors.snationality}
                  helperText={touched.snationality && errors.snationality}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Mobile No"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email (Optional)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  select
                  label="Select Marital Status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maritalStatus}
                  name="maritalStatus"
                  sx={{
                    gridColumn: "span 4",
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  error={!!touched.maritalStatus && !!errors.maritalStatus}
                  helperText={touched.maritalStatus && errors.maritalStatus}>
                  {" "}
                  <option></option>
                  {maritalStatusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Box>
              <Stack direction='column' maxWidth={100} mt="50px">
                <Button type="submit" color="secondary" variant="contained">
                  NEXT
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
// email: yup.string().email("invalid email").required("required"),

const checkoutSchema = yup.object().shape({
  fullName: yup.string().required("required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  email: yup.string().email(),
  // middleName: yup.string().required("required"),
  sgender: yup.string().required("You must choose from one the buttons"),
  snationality: yup.string().required("required"),
  maritalStatus: yup
    .string()
    .required("You must select from one of the options"),
  idNumber: yup.number().required("required"),
  DOB: yup.date().required("required"),
  placeOfBirth: yup.string().required("required"),
  county: yup.string().required("required"),
  location: yup.string().required("required"),
});

export default PersonalInfo;
