import * as React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useValue } from "../../../../context/contextProvider";
import FormField from "../../../utils/textFields/FormField";
import { useState } from "react";

const PersonalInfo = ({ handlePage }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {
    state: { studentsRegistrationForm },
  } = useValue();
  const [dateValue, setDateValue] = useState();

  const handleFormSubmit = (values) => {
    handlePage({ personalInfo: values });
  };
  const maritalStatusOptions = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
  ];
  const relationShipOptions = [
    { value: "parent", label: "parent" },
    { value: "guardian", label: "Guardian" },
    { value: "brother", label: "Brother" },
    { value: "sister", label: "Sister" },
    { value: "cousin", label: "Cousin" },
  ];
  return (
    <>
      <Box m="20px 60px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={studentsRegistrationForm.personalInfo}
          validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  "& .Mui-focused": {
                    color: "#f5079e !important",
                    input: {
                      color: "#0ba2de !important",
                    },
                  },
                  "& .Mui-focused.Mui-error": {
                    color: "#f44336 !important",
                  },
                  "& .css-uc0k7-MuiInputBase-root-MuiFilledInput-root:before": {
                    borderBottom: "2px solid #0ba2de !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
                    borderBottom: "2px solid #f5079e !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                    {
                      borderBottom: "#f44336 !important",
                    },
                }}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Full Names"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullNames}
                  name="fullNames"
                  error={!!touched.fullNames && !!errors.fullNames}
                  helperText={touched.fullNames && errors.fullNames}
                  sx={{
                    gridColumn: "span 5",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="ID/Passport"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.idNo}
                  name="idNo"
                  error={!!touched.idNo && !!errors.idNo}
                  helperText={touched.idNo && errors.idNo}
                  sx={{
                    gridColumn: "span 3",
                  }}
                />
                <FormField
                  err={!!touched.gender && !!errors.gender}
                  errorMessage={touched.gender && errors.gender}
                  formLabel="Gender"
                  mainProps={{
                    name: "gender",
                    value: values.gender,
                  }}
                  optionalProps={{
                    style: { gridColumn: "span 4" },
                    onChange: handleChange,
                  }}
                  radioBtn={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                  ]}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {" "}
                  <DatePicker
                    disableFuture
                    label="Date Of Birth"
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={dateValue}
                    name="DOB"
                    onChange={(newValue) => {
                      setDateValue(newValue);

                      values.DOB = newValue.$d;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          gridColumn: "span 4",
                        }}
                        error={!!touched.DOB && !!errors.DOB}
                        helperText={touched.DOB && errors.DOB}
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Nationality"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.nationality}
                  name="nationality"
                  error={!!touched.nationality && !!errors.nationality}
                  helperText={touched.nationality && errors.nationality}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Mobile No"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobileNo}
                  name="mobileNo"
                  error={!!touched.mobileNo && !!errors.mobileNo}
                  helperText={touched.mobileNo && errors.mobileNo}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email (Optional)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  select
                  label="Select Marital Status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maritalStatus}
                  name="maritalStatus"
                  sx={{
                    gridColumn: "span 4",
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  error={!!touched.maritalStatus && !!errors.maritalStatus}
                  helperText={touched.maritalStatus && errors.maritalStatus}>
                  {" "}
                  <option></option>
                  {maritalStatusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Guardian/Kin name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.guardian}
                  name="guardian"
                  error={!!touched.guardian && !!errors.guardian}
                  helperText={touched.guardian && errors.guardian}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  select
                  label="RelationShip"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.relationShip}
                  name="relationShip"
                  error={!!touched.relationShip && !!errors.relationShip}
                  helperText={touched.relationShip && errors.relationShip}
                  sx={{
                    gridColumn: "span 4",
                  }}
                  SelectProps={{
                    native: true,
                  }}>
                  {" "}
                  <option></option>
                  {relationShipOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Telephone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.tel}
                  name="tel"
                  error={!!touched.tel && !!errors.tel}
                  helperText={touched.tel && errors.tel}
                  sx={{
                    gridColumn: "span 4",
                  }}
                />
              </Box>
              <Box display="flex" mt="50px">
                <Button type="submit" color="secondary" variant="contained">
                  NEXT
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  fullNames: yup.string().required("required"),
  mobileNo: yup.number().required("required"),
  email: yup.string().email(),
  // middleName: yup.string().required("required"),
  gender: yup.string().required("You must choose from one the buttons"),
  nationality: yup.string().required("required"),
  maritalStatus: yup
    .string()
    .required("You must select from one of the options"),
  idNo: yup.number().required("required"),
  DOB: yup.date().required("required"),
  guardian: yup.string().required("required"),
  relationShip: yup
    .string()
    .required("You must select from one of the options"),
  tel: yup.number().required("required"),
});

export default PersonalInfo;
