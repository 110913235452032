import React from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link, useLocation } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Tooltip from "@mui/material/Tooltip";
import SimpleBackdrop from "../../components/progress/backdrop";
import { useValue } from "../../context/contextProvider";
import { useEffect } from "react";
import {
  deleteStudent,
  getStudent,
  updateStudentStatus,
} from "../../actions/student";
import StudentProfileTab from "../../components/students/studentProfile/studentProfileTab";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import StudentAdmitDialog from "../../components/students/studentAdmit/StudentAdmitDialog";
import EditableDisplayField from "../../components/utils/textFields/editableDisplayField.js";
import useCheckToken from "../../hooks/useCheckToken";
import { useCallback } from "react";

const StudentProfile = () => {
  useCheckToken();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openStudentAdmDialog, setOpenStudentAdmDialog] = useState(false);
  const {
    state: { loading, singleLoading, student, currentUser },
    dispatch,
  } = useValue();
  const navigate = useNavigate();
  const sid = useLocation().pathname.split("/")[2];

  const updateConfirmDialog = (act) => {
    const message =
      "You are about to delete this student. Are you sure you want to proceed?";

    const title = "Student delete";

    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: handleConfirmedAction,
      },
    });
  };
  const handleConfirmedAction = async (act) => {
    if (act === "delete")
      await deleteStudent(
        student.studentId,
        dispatch,
        currentUser.token,
        navigate
      );
    setOpenStudentAdmDialog(false);
    return;
  };
  const fetchStudent = useCallback(async () => {
    if (sid) {
      if (currentUser !== null && currentUser.token) {
        await getStudent(sid, dispatch, currentUser.token, navigate);
      }
    }
  }, [sid, currentUser, dispatch, navigate]);
  useEffect(() => {
    fetchStudent();
  }, [fetchStudent]);
  const tempCourseInfo = [
    { formLabel: "Level", value: student.courseLevel },
    { formLabel: "Code", value: student.courseCode },
    { formLabel: "Title", value: student.courseName },
    { formLabel: "Intake Class", value: student.classTitle },
    { formLabel: "Mode Of Study", value: student.modeOfStudy },
    { formLabel: "Sponsorship", value: student.sponsorship },
  ];
  const admittedStudent =
    student.studentStatus === "admitted" && student.admissionNumber !== "N/A"
      ? [
          { formLabel: "Date Of Admission", value: student.dateAdmitted },
          { formLabel: "Admission Number", value: student.admissionNumber },
        ]
      : "";
  const courseInfo =
    student.sponsorship === "self"
      ? [...tempCourseInfo, ...admittedStudent]
      : [
          ...tempCourseInfo,
          { formLabel: "Name Of Sponsor", value: student.nameOfSponsor },
          { formLabel: "Telephone", value: student.telOfSponsor },
          ...admittedStudent,
        ];
  return (
    <>
      {loading ? <SimpleBackdrop /> : null}
      {!loading && student && student.studentId && (
        <Box m="20px">
          <StudentAdmitDialog
            openStudentAdmDialog={openStudentAdmDialog}
            setOpenStudentAdmDialog={setOpenStudentAdmDialog}
            student={student}
          />
          <Tooltip title="Return Back">
            <Link
              to="/students"
              style={{ textDecoration: "none", color: colors.grey[100] }}>
              <ArrowBackOutlinedIcon sx={{ fontSize: 20, mt: -4 }} />
            </Link>
          </Tooltip>

          {/* HEADER */}

          <Header
            title="STUDENT VIEW"
            subtitle="Edit and Manage Student Information"
          />

          {/* GRID  */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="auto"
            px={10}
            mt={8}
            gap="20px">
            {/* ROW 1 */}
            <Paper
              elevation={4}
              sx={{
                gridColumn: "span 5",
                bgcolor: colors.primary[400],
                justifyContent: "center",
                alignItems: "center",
                p: 4,
              }}>
              <Avatar
                alt="user-default-profile-pic"
                src={process.env.REACT_APP_DEFAULT_PROFILE_PROURL}
                sx={{ cursor: "pointer", width: 95, height: 95 }}
              />
            </Paper>
            <Paper
              elevation={4}
              sx={{
                gridColumn: "span 7",
                bgcolor: colors.primary[400],
                justifyContent: "center",
                alignItems: "center",
                p: 4,
              }}>
              <Typography
                variant="h4"
                fontWeight="600"
                color={colors.grey[100]}
                textTransform="uppercase">
                {student && student.fullNames}
              </Typography>
              <Typography
                textTransform="capitalize"
                variant="h5"
                fontWeight="bold"
                color={colors.greenAccent[500]}>
                {student && student.nationality}
              </Typography>
              <Stack
                direction="row"
                sx={{
                  pt: "17px",
                  justifyContent: "space-between",
                }}>
                <LoadingButton
                  title={
                    student.studentStatus === "new"
                      ? "Mark this request viewed"
                      : student.studentStatus === "admitted"
                      ? "Student admission number"
                      : "Admit this student"
                  }
                  loading={singleLoading}
                  onClick={() =>
                    student.studentStatus === "new"
                      ? updateStudentStatus(
                          student.studentId,
                          dispatch,
                          currentUser.token
                        )
                      : student.studentStatus === "viewed" &&
                        student.admissionNumber === "N/A"
                      ? setOpenStudentAdmDialog(true)
                      : null
                  }
                  color={
                    student.studentStatus === "new"
                      ? "warning"
                      : student.studentStatus === "admitted"
                      ? "primary"
                      : "success"
                  }
                  variant="contained">
                  {" "}
                  <span>
                    {" "}
                    {student.studentStatus === "new"
                      ? " Mark Viewed"
                      : student.studentStatus === "admitted" &&
                        student.admissionNumber !== "N/A"
                      ? "Adm No : " + student.admissionNumber
                      : " Admit Student"}
                  </span>
                </LoadingButton>
                {student?.studentStatus === "admitted" &&
                  "Stage: " + student.stage}
                <Button
                  size="small"
                  onClick={() => updateConfirmDialog("delete")}
                  color="error"
                  title="Delete this student"
                  variant="outlined"
                  startIcon={<Delete />}>
                  Delete
                </Button>
              </Stack>
            </Paper>

            {/* ROW 2 */}
            <Paper
              elevation={4}
              sx={{
                gridColumn: "span 5",
                bgcolor: colors.primary[400],
                justifyContent: "center",
                alignItems: "center",
                p: 4,
              }}>
              <Typography
                textTransform="capitalize"
                variant="h5"
                fontWeight="Bold"
                color={colors.grey[100]}>
                Course Details
              </Typography>
              <Divider variant="inset" sx={{ opacity: 0.6 }} />
              <List>
                {courseInfo.map((info, i) => (
                  <EditableDisplayField
                    key={i}
                    formLabel={info.formLabel}
                    mainProps={{ value: info.value }}
                    optionalProps={{
                      primary: {
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: colors.blueAccent[700],
                      },
                      secondary: {
                        variant: "h6",
                        color: colors.grey[200],
                        textTransform: "capitalize",
                      },
                    }}
                  />
                ))}
              </List>
            </Paper>

            <Paper
              elevation={4}
              sx={{
                gridColumn: "span 7",
                gridRow: "span 1",
                bgcolor: colors.primary[400],
                justifyContent: "center",
                alignItems: "center",
                p: 1,
              }}>
              <StudentProfileTab student={student} />
            </Paper>
          </Box>
        </Box>
      )}
    </>
  );
};

export default StudentProfile;
