import axios from "axios";
import fetchData from "./utils/fetchData";
import { saveAs } from "file-saver";

const url = process.env.REACT_APP_SERVER_PROURL + "fees";

export const recordFeePayment = async (token, newFeePayment, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, body: newFeePayment, token: token },
    dispatch
  );
  if (result) {
    // dispatch({
    //   type: "ADD_COURSE",
    //   payload: result,
    // });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Fee payment submited Successful!",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const getFeePaymentHistry = async (dispatch, token) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_FEES",
      payload: result,
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const getPayment = async (sid, dispatch, token) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${sid}`, method: "GET", token: token },
    dispatch
  );
  if (result) {
    if (result.length) {
      dispatch({
        type: "UPDATE_FEES",
        payload: result,
      });
    } else {
      dispatch({
        type: "UPDATE_FEES",
        payload: [],
      });
    }
  }

  dispatch({ type: "END_LOADING" });
};
export const getFeeStatement = async (sid, dispatch, token) => {
  const result = await fetchData(
    { url: `${url}/statement/${sid}`, method: "GET", token: token },
    dispatch
  );
  if (result) {
    return result;
  }
};
export const getFeeStatementPDF = async (sid, dispatch, token) => {
  try {
    const result = await axios.get(`${url}/downloadFeeStatement/${sid}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });
    if (result) {
      return saveAs(result.data, "studentFeeStatement");
    }
  } catch (error) {
    const status = error.response.status;
    switch (status) {
      case 500:
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: "Something went wrong !!",
          },
        });
        break;
      case 401 || 404:
        dispatch({ type: "LOGOUT_USER", payload: null });
        break;
      default:
        break;
    }
    return error;
  }
};
