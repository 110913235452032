// import { validateStudent } from "../actions/utils/validator";

const reducer = (state, action) => {
  switch (action.type) {
    case "START_LOADING":
      return { ...state, loading: true };
    case "END_LOADING":
      return { ...state, loading: false };
    case "START_SINGLE_LOADING":
      return { ...state, singleLoading: true };
    case "END_SINGLE_LOADING":
      return { ...state, singleLoading: false };
    case "START_FILE_UPLOAD_LOADING":
      return { ...state, fileUploadLoading: true };
    case "END_FILE_UPLOAD_LOADING":
      return { ...state, fileUploadLoading: false };
    case "START_LOGIN_LOADING":
      return { ...state, loginLoading: true };
    case "END_LOGIN_LOADING":
      return { ...state, loginLoading: false };
    case "SET_EDITABLE_FIELD_OPEN":
      return { ...state, editableFieldOpen: action.payload };
    case "LOGOUT_USER":
      localStorage.removeItem("currentUser");
      return { ...state, currentUser: null };
    case "UPDATE_USER":
      localStorage.setItem("currentUser", JSON.stringify(action.payload));
      return { ...state, currentUser: action.payload };
    case "UPDATE_STAFFS":
      return {
        ...state,
        staffs: [...action.payload],
      };
    case "UPDATE_STAFFS_PAGE":
      localStorage.setItem("staffsPage", JSON.stringify(action.payload));
      return {
        ...state,
        staffsPage: { ...state.staffsPage, index: action.payload },
      };
    case "UPDATE_STAFF":
      return {
        ...state,
        staff: action.payload,
      };
    case "UPDATE_STAFF_STATUS":
      return {
        ...state,
        staff: { ...state.staff, ...action.payload.data },
        staffs: state.staffs.map((staff) =>
          staff.id === parseInt(action.payload.id)
            ? { ...staff, ...action.payload.data }
            : staff
        ),
      };
    case "UPDATE_STAFF_FORM":
      const value = {
        ...state.staffsRegistrationForm,
        ...action.payload,
      };
      localStorage.setItem("staffsRegistrationForm", JSON.stringify(value));
      return {
        ...state,
        staffsRegistrationForm: {
          ...state.staffsRegistrationForm,
          ...action.payload,
        },
      };
    case "RESET_STAFF_FORM":
      localStorage.removeItem("staffsRegistrationForm");
      return {
        ...state,
        staffsRegistrationForm: {
          staffFormTab: { value: 0, page: 0 },
          otherInfo: {
            selectedValue: {
              name: "",
              code: "",
            },
            field: "",
            fieldName: "",
            designation: "",
            nameTitle: "",
            category: "",
          },
          personalInfo: {
            fullName: "",
            phone: "",
            email: "",
            sgender: "",
            snationality: "",
            maritalStatus: "",
            idNumber: "",
            DOB: new Date().toISOString(),
            county: "",
            location: "",
            placeOfBirth: "",
          },
        },
      };
    case "UPDATE_STAFF_FORM_UPLOADS":
      return {
        ...state,
        staffAttachedCert: { ...state.staffAttachedCert, ...action.payload },
      };
    case "RESET_STAFF_FORM_TAB":
      return {
        ...state,
        resetStudentFormTab: false,
      };
    case "DELETE_STAFF":
      return {
        ...state,
        staffs: state.staffs.filter(
          (staff) => staff.id !== parseInt(action.payload)
        ),
      };
    case "CLEAR_STAFF":
      return {
        ...state,
        staff: action.payload,
      };
    case "UPDATE_STUDENTS":
      return {
        ...state,
        students: [...action.payload],
      };
    case "UPDATE_STUDENTS_PAGE":
      localStorage.setItem("studentsPage", JSON.stringify(action.payload));
      return {
        ...state,
        studentsPage: { ...state.studentsPage, index: action.payload },
      };
    case "UPDATE_STUDENT":
      return {
        ...state,
        student: action.payload,
      };
    case "UPDATE_STUDENT_FEEBALANCE":
      return {
        ...state,
        student: { ...state.student, ...action.payload },
      };
    case "UPDATE_STUDENT_STATUS":
      return {
        ...state,
        student: { ...state.student, ...action.payload.data },
        students: state.students.map((student) =>
          student.studentId === parseInt(action.payload.id)
            ? { ...student, ...action.payload.data }
            : student
        ),
      };
    case "UPDATE_STUDENT_FORM":
      const values = {
        ...state.studentsRegistrationForm,
        ...action.payload,
      };
      localStorage.setItem("studentsRegistrationForm", JSON.stringify(values));
      return {
        ...state,
        studentsRegistrationForm: {
          ...state.studentsRegistrationForm,
          ...action.payload,
        },
      };
    case "RESET_STUDENT_FORM":
      localStorage.removeItem("studentsRegistrationForm");
      return {
        ...state,
        studentsRegistrationForm: {
          studentFormTab: { value: 0, page: 0 },
          courseInfo: {
            selectedValue: {
              name: "",
              code: "",
            },
            courseCode: "",
            courseName: "",
            courseLevel: "",
            sponsorship: "",
            nameOfSponsor: "",
            telOfSponsor: "",
            modeOfStudy: "",
          },
          personalInfo: {
            fullNames: "",
            mobileNo: "",
            email: "",
            gender: "",
            nationality: "",
            maritalStatus: "",
            idNo: "",
            DOB: new Date().toISOString(),
            guardian: "",
            relationShip: "",
            tel: "",
          },
        },
      };
    case "UPDATE_STUDENT_FORM_UPLOADS":
      return {
        ...state,
        attachedCert: { ...state.attachedCert, ...action.payload },
      };
    case "RESET_STUDENT_FORM_TAB":
      return {
        ...state,
        resetStudentFormTab: false,
      };
    case "DELETE_STUDENT":
      return {
        ...state,
        students: state.students.filter(
          (student) => student.studentId !== parseInt(action.payload)
        ),
      };
    case "CLEAR_STUDENT":
      return {
        ...state,
        student: action.payload,
      };
    case "UPDATE_DESIGNATIONS":
      return {
        ...state,
        designations: [...action.payload],
      };
    case "RESET_DESIGNATION_FORM_FIELD":
      return {
        ...state,
        designationFormFields: { title: "", salary: "" },
      };
    case "UPDATE_DESIGNATION_FORM_FIELD":
      return {
        ...state,
        designationFormFields: {
          ...state.designationFormFields,
          ...action.payload,
        },
      };
    case "UPDATE_DESIGNATION":
      return {
        ...state,

        designations: state.designations.map((designation) =>
          designation.id === parseInt(action.payload.id)
            ? { ...designation, ...action.payload.data }
            : designation
        ),
      };
    case "UPDATE_RESOURCES":
      return {
        ...state,
        resources: [...action.payload],
      };
    case "ADD_RESOURCE":
      return {
        ...state,
        resources: [{ ...action.payload }, ...state.resources],
      };
    case "DELETE_RESOURCE":
      return {
        ...state,
        resources: [
          ...state.resources.filter(
            (doc) => parseInt(doc.id) !== parseInt(action.payload.id)
          ),
        ],
      };
    case "UPDATE_SALARY":
      return {
        ...state,
        salary: [...action.payload],
      };
    case "UPDATE_STAFF_SALARY":
      return {
        ...state,
        staffSalary: action.payload,
      };
    case "UPDATE_SALARY_HISTRY":
      return {
        ...state,
        salaryHistry: action.payload,
      };
    case "UPDATE_EXPENDITURES":
      return {
        ...state,
        expenditures: [...action.payload],
      };
    case "UPDATE_EXPENDITURE":
      return {
        ...state,
        expenditure: { ...action.payload },
      };
    case "UPDATE_INTAKECLASSES":
      return {
        ...state,
        intakeClasses: [...action.payload],
      };
    case "UPDATE_INTAKECLASS":
      return {
        ...state,
        intakeClass: { ...action.payload },
      };
    case "UPDATE_INTAKECLASS_SESSION":
      return {
        ...state,
        intakeClassSession: [...action.payload],
      };
    case "ADD_INTAKECLASS_SESSION":
      return {
        ...state,
        intakeClassSession: [action.payload, ...state.intakeClassSession],
      };
    case "UPDATE_UNITS":
      return {
        ...state,
        units: [...action.payload],
      };
    case "UPDATE_FIELDS":
      return {
        ...state,
        fieldsData: [...action.payload],
      };
    case "UPDATE_DEPARTMENTS":
      return {
        ...state,
        departments: [...action.payload],
      };
    case "UPDATE_COURSES":
      return {
        ...state,
        courses: [...action.payload],
      };
    case "UPDATE_COURSECLASSSES":
      return {
        ...state,
        courseClassSessions: [...action.payload],
      };
    case "ADD_COURSE":
      return {
        ...state,
        courses: [action.payload, ...state.courses],
      };
    case "UPDATE_COURSE":
      return {
        ...state,
        course: { ...action.payload },
      };
    case "UPDATE_FEESTRUCTURE":
      return {
        ...state,
        courseFeeStructure: { ...action.payload },
        course: {
          ...state.course,
          feeStructureStatus: action.payload.feeStructureStatus ?? "null",
        },
      };
    case "UPDATE_TEMPFEESTRUCTURE":
      return {
        ...state,
        tempCourseFeeStructure: action.payload,
      };
    case "UPDATE_COURSE_INFO":
      return {
        ...state,
        course: { ...state.course, ...action.payload.data },
        courses: state.courses.map((course) =>
          course.id === parseInt(action.payload.id)
            ? { ...course, ...action.payload.data }
            : course
        ),
      };
    case "DELETE_COURSE":
      return {
        ...state,
        courses: state.courses.filter(
          (course) => course.id !== parseInt(action.payload)
        ),
      };
    case "UPDATE_FEES":
      return {
        ...state,
        feesHistry: [...action.payload],
      };
    case "UPDATE_ERRORS":
      const newErrors = newStateErrors(state, action);

      return {
        ...state,
        errors: [...newErrors, ...action.payload],
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: state.errors.filter(
          (error) => error.fieldName !== action.payload.name
        ),
      };
    case "UPDATE_ALERT":
      return { ...state, alert: action.payload };
    case "UPDATE_CONFIRM_DIALOG":
      return { ...state, confirmDialog: action.payload };
    case "UPDATE_CONFIRM_DIALOG_WITH_LIST":
      return { ...state, confirmDialogWithList: action.payload };
    default:
      throw new Error("No matched action!");
  }
};

export default reducer;
const newStateErrors = (state, action) => {
  let newErrs = [...state.errors];
  action.payload.map((err) => {
    return (newErrs = newErrs.filter(
      (error) => error.fieldName !== err.fieldName
    ));
  });
  return newErrs;
};
// const setItemOnLocalStorage = (item, action, storageName) => {
//   localStorage.setItem(
//     storageName,
//     JSON.stringify({ ...item, ...action.payload })
//   );
// };
