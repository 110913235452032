import React from "react";
import {
  Box,
  useTheme,
  Typography,
  Tooltip,
  List,
  Divider,
} from "@mui/material";
import { tokens } from "../../theme";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import CourseViewCustomizedTabs from "../../components/course/courseViewVerticalTabs";
import SimpleBackdrop from "../../components/progress/backdrop";
import { useValue } from "../../context/contextProvider";
import useCheckToken from "../../hooks/useCheckToken";
import { useEffect } from "react";
import { useCallback } from "react";
import { getCourse, updateCourseInfo } from "../../actions/course";
import { useNavigate } from "react-router-dom";
import EditableDisplayField from "../../components/utils/textFields/editableDisplayField";
import { getCourseClassSessions } from "../../actions/intakeClassSession";
import { useState } from "react";

const CourseView = () => {
  const {
    state: { currentUser, course, loading },
    dispatch,
  } = useValue();
  useCheckToken();
  const [searching, setSearching] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const cid = useLocation().pathname.split("/")[2];
  const saveChanges = async (changes, initialData) => {
    const newCourse = { ...course, ...changes };
    await updateCourseInfo(course.id, dispatch, currentUser.token, newCourse);
  };
  const courseInfo = [
    {
      formLabel: "Field name",
      value: course.fieldName,
      editable: false,
      name: "courseField",
    },
    {
      formLabel: "Code",
      value: course.courseCode,
      editable: true,
      name: "courseCode",
    },
    {
      formLabel: "Title",
      value: course.courseName,
      editable: true,
      name: "courseName",
    },
    {
      formLabel: "Level",
      value: course.courseLevel,
      editable: false,
      name: "courseLevel",
    },
    {
      formLabel: "Duration",
      value: course.period,
      editable: true,
      name: "period",
      type: "number",
    },
    { formLabel: "Date Created", value: course.dateCreated, editable: false },
  ];
  const fetchCourse = useCallback(async () => {
    if (cid) {
      if (currentUser !== null && currentUser.token) {
        const result = await getCourse(
          cid,
          dispatch,
          currentUser.token,
          navigate
        );
        if (result) setSearching(true);
        await getCourseClassSessions(cid, currentUser.token, dispatch);
        setSearching(false);
      }
    }
  }, [cid, currentUser, dispatch, navigate]);
  useEffect(() => {
    fetchCourse();
  }, [fetchCourse]);
  return (
    <>
      {loading ? <SimpleBackdrop /> : null}
      {!loading && course && course.id && (
        <Box m="20px">
          <Tooltip title="Back">
            <Link
              to="/course"
              style={{ textDecoration: "none", color: colors.grey[100] }}>
              <ArrowBackOutlinedIcon sx={{ fontSize: 20, mt: -4 }} />
            </Link>
          </Tooltip>
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Header title="COURSE VIEW" subtitle="Manage Course Details" />
          </Box>

          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="auto"
            gap="5px">
            <Box
              gridColumn="span 4"
              p={3}
              backgroundColor={colors.primary[400]}>
              <Typography
                textTransform="capitalize"
                variant="h5"
                fontWeight="Bold"
                color={colors.grey[100]}>
                Course Details
              </Typography>
              <Divider variant="inset" sx={{ opacity: 0.6 }} />
              <List>
                {courseInfo.map((info) => (
                  <EditableDisplayField
                    formLabel={info.formLabel}
                    saveChanges={saveChanges}
                    mainProps={{
                      value: info.value,
                      name: info.name || "",
                      type: info.type || "",
                      key: course && "course" + course.id,
                    }}
                    optionalProps={{
                      editable: info.editable,
                      primary: {
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: colors.blueAccent[700],
                      },
                      secondary: {
                        variant: "h6",
                        color: colors.grey[200],
                        textTransform: "capitalize",
                      },
                    }}
                  />
                ))}
              </List>
            </Box>
            <Box gridColumn="span 8">
              {" "}
              <Box backgroundColor={colors.primary[400]}>
                <CourseViewCustomizedTabs {...{ course, searching }} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CourseView;
