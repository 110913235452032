import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { useValue } from "../../context/contextProvider";

export default function StaffMenu() {
    const {
      state: {
        staffsPage: { index },
      },
      dispatch,
    } = useValue();
      const updateStaffsPage = (index) => {
        dispatch({
          type: "UPDATE_STAFFS_PAGE",
          payload: index,
        });
      };
  const styles = {
    minWidth: 90,
    fontSize: 13,
    "&:hover": {
      color: "#0ba2de",
    },
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          gap: 4,
          alignItems: "center",
          textAlign: "center",
        }}>
        <Tooltip title="Register Staff">
          <Link
            onClick={() => updateStaffsPage(2)}
            style={{
              textDecoration: "none",
              borderBottom: index === 2 ? "2px solid #f5079e" : undefined,
            }}>
            <Typography
              sx={styles}
              color={index === 2 ? "#0ba2de" : "#f1f1f1"}
              fontWeight={index === 2 ? "bold" : undefined}>
              REGISTRATION FORM
            </Typography>
          </Link>
        </Tooltip>
        <Tooltip title="List Of Active Staffs">
          <Link
            onClick={() => updateStaffsPage(1)}
            style={{
              textDecoration: "none",
              borderBottom: index === 1 ? "2px solid #f5079e" : undefined,
            }}>
            <Typography
              sx={styles}
              color={index === 1 ? "#0ba2de" : "#f1f1f1"}
              fontWeight={index === 1 ? "bold" : undefined}>
              STAFFS LIST
            </Typography>
          </Link>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
}
