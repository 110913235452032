import * as React from "react";
import { Box, useTheme, Button, TextField } from "@mui/material";
import { tokens } from "../../../theme";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from "react-router-dom";
import { assignClassCourses } from "../../../actions/intakeClass";
import { useValue } from "../../../context/contextProvider";
import { getCourses } from "../../../actions/course";

export default function ManageCourse() {
  const {
    state: { currentUser, courses, intakeClassSession },
    dispatch,
  } = useValue();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [next, setNext] = useState([]);
  const [sending, setSending] = useState(false);
  const [unassignedCourses, setUnassignedCourses] = useState([]);

  const cid = useLocation().pathname.split("/")[2];

  const sendingData = async (token, disp, data) => {
    const result = await assignClassCourses(token, disp, data);
    if (result?.courseId) {
      setNext((prev) => [
        ...prev.filter((i) => i.id !== parseInt(result.courseId)),
      ]);
      setUnassignedCourses((prev) => [
        ...prev.filter((i) => i.id !== parseInt(result.courseId)),
      ]);
      dispatch({
        type: "ADD_INTAKECLASS_SESSION",
        payload: result,
      });
    }
  };
  const submitingCourseClass = async () => {
    // setSending(true);
    next.map((data, i) => {
      const courseClassData = {
        classId: cid,
        courseId: data.id,
        courseName: data.courseName,
        courseCode: data.courseCode,
        courseSessionId: 1,
        sessionStatus: "inActive",
      };
      sendingData(currentUser.token, dispatch, courseClassData);
      return data;
    });
  };
  const filterCourse = async (result) => {
    let newCourses = [...result].filter(
      (i) => i.feeStructureStatus === "completed"
    );
    intakeClassSession.map((course) => {
      newCourses = [
        ...newCourses.filter(
          (i) => parseInt(i.id) !== parseInt(course.courseId)
        ),
      ];
      return course;
    });
    setUnassignedCourses([...newCourses]);
    if (!newCourses.length && result.length) {
      const leng = result.length - intakeClassSession.length;
      if (leng > 0)
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "info",
            message: `There is ${leng} incomplete courses`,
            act: "expeSubmited",
          },
        });
    }
  };
  const updateCourses = async () => {
    if (courses.length && unassignedCourses.length === 0) filterCourse(courses);
    if (!courses.length && unassignedCourses.length === 0) {
      setSending(true);
      const result = await getCourses(currentUser.token, dispatch);
      setSending(false);
      if (result?.length === 0) {
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message:
              "No course added, please go to the course menu to add one!",
          },
        });
      }

      filterCourse(result);
    }
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Box m="20px">
      {/* HEADER */}

      <Box
        display="grid"
        gap="20px"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="180px">
        {/* <Box studentCourse,feestructure,courseclasssess
          gridColumn="span 6"
          alignItems="center"
          justifyContent="center"
          backgroundColor={colors.primary[400]}>
          <Typography textAlign="center" fontSize="20px" mt="20px">
            Record Salary Form
          </Typography>
          <br />
          <Box
            backgroundColor={colors.primary[400]}
            overflow="auto"
            maxHeight="600px">
            <Box ml="20px" mr="40px" mt="20px" width="250px"> */}
        <Autocomplete
          multiple
          // limitTags={5}
          id="checkboxes-tags-demo"
          disableCloseOnSelect
          //   disableClearable={next === null}
          options={unassignedCourses}
          onFocus={() => updateCourses()}
          value={next}
          onChange={(event, value) => (value ? setNext(value) : setNext([]))}
          loading={sending}
          getOptionLabel={(option) => option?.courseName}
          sx={{
            gridColumn: "span 8",
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />

              {option.courseCode + "  " + option.courseName}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              name="course"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {sending ? (
                      <CircularProgress color="info" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              label="Enter Course Name"
              placeholder="Course Name"
              sx={{
                //   gridColumn: "span 6",
                "& .Mui-focused": {
                  color: "#f2f0f0 !important",
                  input: {
                    color: "#f2f0f0 !important",
                  },
                },
                "& .Mui-focused.Mui-error": {
                  color: "#f44336 !important",
                },
                "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root::before, .css-n88uca::before":
                  {
                    borderBottom: "2px solid #0ba2de !important",
                  },
                "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root::after, .css-n88uca::after":
                  {
                    borderBottom: "2px solid #f5079e !important",
                  },
                "& .Mui-error.css-1rv476z-MuiInputBase-input-MuiFilledInput-input":
                  {
                    color: "#f5079e !important",
                  },
                "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                  {
                    borderBottom: "#f44336 !important",
                  },
              }}
            />
          )}
        />
        {next && (
          <Box sx={{ gridColumn: "span 1" }}>
            <Button
              // disabled={loading}
              onClick={submitingCourseClass}
              sx={{
                gridColumn: "span 8",
                ml: "1px",
                backgroundColor: colors.greenAccent[500],
                color: colors.grey[100],
                "&:hover": {
                  borderBottom: 1,
                  borderColor: "red",
                  backgroundColor: colors.blueAccent[500],
                },
              }}>
              SUBMIT
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
