import * as React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import { useValue } from "../../../context/contextProvider";
import { recordFeePayment } from "../../../actions/fees";

const BankForm = (props) => {
  const { id, courseName, fullNames, setNext } = props;
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {
    dispatch,
    state: { currentUser },
  } = useValue();

  const updateConfirmDialogWithList = (act) => {
    const message =
      "You are about to submit the following record, Please confirm before submiting!";
    const title = "Record Fees Payment";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG_WITH_LIST",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: submitFeePayment,
        formData: initialValues,
      },
    });
  };
  const submitFeePayment = async (newFeePayment) => {
    await recordFeePayment(
      currentUser.token,
      newFeePayment,
      dispatch
    );
    setNext();
  };
  const handleFormSubmit = (values) => {
    const newValues = Object.assign(values, {
      studentAdm: id,
      methodOfPayment: "bank",
      staffId: currentUser.staffId,
    });
    updateConfirmDialogWithList(newValues);
  };
  const initialValues = {
    studentName: fullNames,
    courseName: courseName,
    accountNo: "",
    amount: "",
    receiptNo: "",
    paymentStatus: "asigned",
  };
  return (
    <Box ml="20px" mr="40px" mt="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(6, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {" "}
                <DatePicker
                  disableFuture
                  label="Date Of Payment"
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={values.dateOfPayment ? values.dateOfPayment : null}
                  onChange={(newValue) => {
                    setFieldValue(
                      "dateOfPayment",
                      moment(newValue.$d).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="dateOfPayment"
                      {...params}
                      error={!!touched.dateOfPayment && !!errors.dateOfPayment}
                      helperText={touched.dateOfPayment && errors.dateOfPayment}
                      sx={{
                        gridColumn: "span 2",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Account Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.accountNo}
                name="accountNo"
                error={!!touched.accountNo && !!errors.accountNo}
                helperText={touched.accountNo && errors.accountNo}
                sx={{
                  gridColumn: "span 2",
                  "& .Mui-focused": {
                    color: "#f2f0f0 !important",
                    input: {
                      color: "#f2f0f0 !important",
                    },
                  },
                  "& .Mui-focused.Mui-error": {
                    color: "#f44336 !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                    {
                      borderBottom: "2px solid #0ba2de !important",
                    },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
                    borderBottom: "2px solid #f5079e !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                    {
                      borderBottom: "#f44336 !important",
                    },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Amount (Kshs)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={!!touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
                sx={{
                  gridColumn: "span 2",
                  "& .Mui-focused": {
                    color: "#f2f0f0 !important",
                    input: {
                      color: "#f2f0f0 !important",
                    },
                  },
                  "& .Mui-focused.Mui-error": {
                    color: "#f44336 !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                    {
                      borderBottom: "2px solid #0ba2de !important",
                    },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
                    borderBottom: "2px solid #f5079e !important",
                  },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                    {
                      borderBottom: "#f44336 !important",
                    },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Receipt Number"
                multiline
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.receiptNo}
                name="receiptNo"
                error={!!touched.receiptNo && !!errors.receiptNo}
                helperText={touched.receiptNo && errors.receiptNo}
                sx={{
                  gridColumn: "span 2",
                  "& .Mui-focused": {
                    color: "#f2f0f0 !important",
                    input: {
                      color: "#f2f0f0 !important",
                    },
                  },
                  "& .Mui-focused.Mui-error": {
                    color: "#f44336 !important",
                  },
                  "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:before":
                    {
                      borderBottom: "2px solid #0ba2de !important",
                    },
                  "& .css-g7eipk-MuiInputBase-root-MuiFilledInput-root:after": {
                    borderBottom: "2px solid #f5079e !important",
                  },
                  "& .Mui-error.css-1rv476z-MuiInputBase-input-MuiFilledInput-input":
                    {
                      color: "#f5079e !important",
                    },
                  "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                    {
                      borderBottom: "#f44336 !important",
                    },
                }}
              />
            </Box>
            <Box display="flex" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                SUBMIT
              </Button>
            </Box>
            <br />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  accountNo: yup.string().required("required"),
  amount: yup.string().required("required"),
  receiptNo: yup.string().required("Required"),
  paymentStatus: yup.string().required("Required"),
});

export default BankForm;
