import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "staff";
export const addStaff = async (token, staff, dispatch, navigate) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData({ url, body: staff, token: token }, dispatch);
  if (result) {
    getStaffs(token, dispatch);
    navigate("/staff/" + result.insertId);
    dispatch({
      type: "RESET_STAFF_FORM",
      payload: 0,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Staff registered successfully!",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const getStaffs = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_STAFFS",
      payload: result,
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const getStaff = async (sid, dispatch, token, navigate) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${sid}`, method: "GET", token: token },
    dispatch
  );
  if (result) {
    if (result.staffId) {
      dispatch({
        type: "UPDATE_STAFF",
        payload: result,
      });
    } else if (!result[0]) {
      dispatch({
        type: "UPDATE_STAFF",
        payload: {},
      });
      navigate("/staff/" + sid + "/notFound");
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "User not found!",
        },
      });
    }
  }

  dispatch({ type: "END_LOADING" });
};
export const deleteStaff = async (sid, dispatch, token, navigate) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${sid}`, method: "DELETE", token: token },
    dispatch
  );
  if (result) {
    navigate("/staff");
    dispatch({
      type: "DELETE_STAFF",
      payload: sid,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Staff deleted successfully",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};

export const searchStaff = async (sid, dispatch, token) => {
  const result = await fetchData(
    {
      url: `${url}/search/${sid}`,
      method: "GET",
      token: token,
    },
    dispatch
  );
  return result;
};
export const updateStaff = async (sid, dispatch, token, staffInfo) => {
  const result = await fetchData(
    {
      url: `${url}/${sid}`,
      body: staffInfo,
      method: "PUT",
      token: token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_STAFF_STATUS",
      payload: {
        id: sid,
        data: result,
      },
    });

    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Staff updated Successfully",
      },
    });
  }
};
export const updateStaffUploads = async (sid, dispatch, token, doc, act) => {
  dispatch({ type: "START_FILE_UPLOAD_LOADING" });
  const result = await fetchData(
    {
      url: `${url}/Uploads/${sid}`,
      body: doc,
      method: "PUT",
      token: token,
    },
    dispatch
  );
  if (result) {
    const message =
      act === "delete"
        ? "File deleted successfully"
        : "File uploaded Successfully";
    if (act === "add")
      dispatch({
        type: "UPDATE_STUDENT_FORM_UPLOADS",
        payload: { file: "", fileTitle: "" },
      });

    dispatch({
      type: "UPDATE_STUDENT_STATUS",
      payload: {
        id: sid,
        data: { docInfo: result },
      },
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: message,
      },
    });
  }
  dispatch({ type: "END_FILE_UPLOAD_LOADING" });
};
