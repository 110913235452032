import {
  TextField,
  // ListItem,
  Button,
  ListItemText,
  // Box,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import { useValue } from "../../../context/contextProvider";
import { Cancel, Edit, Save } from "@mui/icons-material";
import { validateStudent } from "../../../actions/utils/validator";

function renderItem({
  optionalProps,
  formLabel,
  handleEditing,
  value,
  editableFieldOpen,
  key,
  loading,
}) {
  return (
    <>
      {/* <ListItem sx={{ }}> */}
        <ListItemText
          primary={
            <Typography sx={optionalProps.primary}>{formLabel}</Typography>
          }
          secondary={
            optionalProps.editable ? (
              <Button
                disabled={editableFieldOpen === key || loading}
                sx={{
                  maxWidth: "100%",
                  color: "white",
                }}
                endIcon={
                  <Edit titleAccess="Edit" onClick={() => handleEditing()} />
                }>
                <Typography sx={optionalProps.secondary} noWrap>
                  {value}
                </Typography>
              </Button>
            ) : (
              <Typography
                title={formLabel === "Title" ? value : ""}
                sx={{ ml: 2, ...optionalProps.secondary }}
                noWrap>
                {value}
              </Typography>
            )
          }
        />
      {/* </ListItem> */}
      <Divider variant="fullWidth" sx={{ opacity: 0.1 }} />
    </>
  );
}
export default function EditableDisplayField({
  mainProps,
  optionalProps = {},
  formLabel = "",
  saveChanges,
}) {
  const { name, value, type, key, initialData } = mainProps;
  const {
    state: { editableFieldOpen, alert },
    dispatch,
  } = useValue();

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changes, setChanges] = useState(null);

  const handleChange = (e) => {
    setChanges({ [e.target.name]: e.target.value });
    const newErr = validateStudent({ [e.target.name]: e.target.value });
    if (newErr) setError(newErr);
    if (!newErr && error !== null) setError(null);
  };
  const handleCloseEdit = () => {
    setEditing(false);
    setChanges(null);
    dispatch({ type: "SET_EDITABLE_FIELD_OPEN", payload: null });
  };
  const updateConfirmDialog = (act) => {
    if (!changes || (changes && changes[name] === value)) {
      handleCloseEdit();
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "info",
          message: "No changes !",
        },
      });
      return;
    }
    const message =
      act === "save"
        ? "You are about to save changes. Are you sure you want to proceed?"
        : "You are about to cancel changes. Are you sure you want to proceed?";
    const title = act === "save" ? "Save changes" : "Cancel changes";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: handleConfirmedAction,
      },
    });
  };
  const handleConfirmedAction = async (act) => {
    if (act === "cancel") handleCloseEdit();
    if (act === "save") {
      if (name === "email" && changes[name] === "")
        setChanges({ email: "N/A" });
      setLoading(true);
      await saveChanges(changes, initialData);
      if (alert.severity !== "error") {
        handleCloseEdit();
      }

      setLoading(false);
    }
    return;
  };

  const handleEditing = () => {
    setEditing(true);
    dispatch({ type: "SET_EDITABLE_FIELD_OPEN", payload: key });
  };

  return (
    <>
      {!editing &&
        renderItem({
          optionalProps,
          formLabel,
          handleEditing,
          value,
          editableFieldOpen,
          key,
          loading,
        })}
      {editing && optionalProps.editable && (
        <>
          <TextField
            sx={{ width: 190, mt: 3 }}
            size="small"
            type={type || "text"}
            disabled={loading}
            name={name}
            value={!changes ? value : changes[name]}
            {...optionalProps}
            label={formLabel}
            autoFocus
            error={error && error.fieldName === mainProps.name}
            helperText={
              error && error.fieldName === mainProps.name && error.message
            }
            color="info"
            variant="outlined"
            onChange={handleChange}
            // required
            InputProps={{
              endAdornment: (
                <InputAdornment postion="end">
                  {loading ? <CircularProgress size={22} color="info" /> : ""}
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            sx={{ ml: 2, mt: 3 }}
            title={
              !changes || changes[name] === value
                ? "No changes"
                : "Save changes"
            }
            disabled={!changes || editableFieldOpen !== key || loading || error}
            type="submit"
            color="info"
            onClick={() => updateConfirmDialog("save")}>
            <Save />
          </IconButton>
          <IconButton
            sx={{ mt: 3 }}
            disabled={loading}
            onClick={() => updateConfirmDialog("cancel")}
            title={
              !changes || changes[name] === value
                ? "Cancel edit mode"
                : "Cancel changes"
            }
            color="error">
            <Cancel />
          </IconButton>
        </>
      )}
    </>
  );
}
