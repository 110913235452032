import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import LinearColor from "../../progress";
import CircularProgress from "@mui/material/CircularProgress";
import { useValue } from "../../../context/contextProvider";
import { getSalaryHistry } from "../../../actions/salary";

const SalaryViewTbl = ({ staffNo, setSalaryViewReceiptData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    state: { currentUser, loading, salaryHistry },
    dispatch,
  } = useValue();
  const salaryViewTransaction = (id) => {
    const viewedSalary = salaryHistry.find((dt) => dt.id === id);
    setSalaryViewReceiptData(viewedSalary);
  };
  const fetchStaffHistry = useCallback(async () => {
    if (staffNo) {
      if (currentUser !== null && currentUser.token) {
        await getSalaryHistry(staffNo, dispatch, currentUser.token);
      }
    }
  }, [staffNo, currentUser, dispatch]);
  useEffect(() => {
    fetchStaffHistry();
  }, [fetchStaffHistry]);

  const columns = [
    { field: "staffNumber", headerName: "StaffNumber", flex: 0.5 },
    { field: "fullNames", headerName: "Full Names", flex: 1.2 },

    {
      field: "paymentMode",
      headerName: "PaymentMode",
      headerAlign: "left",
      flex: 0.5,
      align: "left",
    },
    {
      field: "amount",
      headerName: "Amount(Kshs)",
      headerAlign: "left",
      flex: 0.5,
      align: "left",
    },
    {
      field: "receiptNo",
      headerName: "Receipt No",
      headerAlign: "left",
      flex: 1,
      align: "left",
    },
    {
      field: "dateCreated",
      headerName: "Date Recorded",
      headerAlign: "left",
      flex: 0.7,
      align: "left",
    },
  ];

  return (
    <Box m="20px">
      <Box
        m="25px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        {loading && <LinearColor />}

        <DataGrid
          rows={salaryHistry}
          columns={columns}
          loading={loading}
          getRowId={(row) => row.id}
          components={{ Toolbar: GridToolbar }}
          onRowClick={(rows) => salaryViewTransaction(rows.id)}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="info" size={20} /> : null}
              </React.Fragment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default SalaryViewTbl;
