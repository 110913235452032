import { Box, CircularProgress } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const SalaryHistoryList = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const salaryHistoryViewPage = (id) => {
    navigate("/salary/" + id, {
      replace: true,
    });
  };

  const columns = [
    { field: "idNumber", headerName: "Staff ID", flex: 0.5 },
    { field: "fullNames", headerName: "Full Names", flex: 1.2 },
    {
      field: "paymentMode",
      headerName: "PaymentMode",
      headerAlign: "left",
      flex: 0.5,
      align: "left",
    },
    {
      field: "amount",
      headerName: "Amount(Kshs)",
      headerAlign: "left",
      flex: 0.5,
      align: "left",
    },
    {
      field: "receiptNo",
      headerName: "Receipt No",
      headerAlign: "left",
      flex: 0.7,
      align: "left",
    },
    {
      field: "dateCreated",
      headerName: "Date Recorded",
      headerAlign: "left",
      flex: 0.7,
      align: "left",
    },
  ];

  return (
    <Box>
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <DataGrid
          rows={props.salaryHistry}
          columns={columns}
          loading={props.loading}
          getRowId={(row) => row.id}
          components={{ Toolbar: GridToolbar }}
          onRowClick={(rows) => salaryHistoryViewPage(rows.id)}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {props.loading ? (
                  <CircularProgress color="info" size={20} />
                ) : null}
              </React.Fragment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default SalaryHistoryList;
