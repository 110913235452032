import fetchData from "./utils/fetchData";

const url = process.env.REACT_APP_SERVER_PROURL + "courses";

export const addCourse = async (token, course, dispatch, navigate) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData({ url, body: course, token: token }, dispatch);
  if (result) {
    dispatch({
      type: "ADD_COURSE",
      payload: result,
    });
    navigate("/course/" + result.id);
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Course added successfully!",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const getCourses = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_COURSES",
      payload: result,
    });
  }
  dispatch({ type: "END_LOADING" });
  return result;
};
export const getCourse = async (cid, dispatch, token, navigate) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${cid}`, method: "GET", token: token },
    dispatch
  );
  dispatch({ type: "END_LOADING" });
  if (result) {
    if (result[0]) {
      dispatch({
        type: "UPDATE_COURSE",
        payload: result[0],
      });
      return result[0];
    }
    if (!result[0]) {
      dispatch({
        type: "UPDATE_COURSE",
        payload: {},
      });
      navigate("/courses/" + cid + "/notFound");
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Resource not found!",
        },
      });
    }
  }
};
export const deleteCourse = async (cid, dispatch, token, navigate) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${cid}`, method: "DELETE", token: token },
    dispatch
  );
  if (result) {
    navigate("/course");
    dispatch({
      type: "DELETE_COURSE",
      payload: cid,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "COURSE deleted successfully",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};

export const updateCourseInfo = async (id, dispatch, token, courseInfo) => {
  const result = await fetchData(
    {
      url: `${url}/${id}`,
      body: courseInfo,
      method: "PUT",
      token: token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_COURSE_INFO",
      payload: {
        id: id,
        data: result,
      },
    });

    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Course updated Successfully",
      },
    });
  }
};
