import Joi from "joi";

const validator = (schema) => (payload) =>
  schema.validate(payload, { abortEarly: false });

const new_schema = {
  // personal info
  fullNames: {
    fullNames: Joi.string().min(4).required().messages({
      "string.empty": "Please provide your full names",
    }),
  },
  mobileNo: {
    mobileNo: Joi.string().min(10).required().messages({
      "string.empty": "Please provide your full mobile number",
      "string.min": "mobile number must be not less than 10 characters",
    }),
  },
  phoneNumber: {
    phoneNumber: Joi.string().min(10).required().messages({
      "string.empty": "Please provide your full mobile number",
      "string.min": "mobile number must be not less than 10 characters",
    }),
  },
  email: {
    email: Joi.alternatives().conditional("email", {
      is: "",
      then: Joi.any().optional(),
      otherwise: Joi.string().email({ tlds: { allow: false } }),
      // .messages({
      //   "email.base": "Please provide your full names",
      // }),
    }),
  },
  gender: { gender: Joi.string().valid("male", "female").required() },
  nationality: { nationality: Joi.string().required() },
  idNo: {
    idNo: Joi.string().min(7).required().messages({
      "string.empty": "Please provide your full ID/Passport number",
      "string.min": " ID/Passport number must be not less than 7 characters",
    }),
  },
  idNumber: {
    idNumber: Joi.string().min(7).required().messages({
      "string.empty": "Please provide your full ID/Passport number",
      "string.min": " ID/Passport number must be not less than 7 characters",
    }),
  },
  DOB: {
    DOB: Joi.date().iso().required().messages({
      "date.empty": "Date of birth is required",
      // "date.base": "Date of birth is required",
    }),
  },
  guardian: {
    guardian: Joi.string().required().messages({
      "string.empty": "Please provide guardian/kin full names",
    }),
  },
  relationShip: {
    relationShip: Joi.string().required().messages({
      "string.empty":
        "Please provide your relationship with the above guardian/kin",
    }),
  },
  maritalStatus: {
    maritalStatus: Joi.string().required().messages({
      "string.empty": "Please provide your marital Status",
    }),
  },
  tel: {
    tel: Joi.number().integer().required().messages({
      "number.empty": "Please provide guardian/kin mobile number",
      "number.base": "guardian/kin mobile number must be a number",
    }),
  },

  // courseInfo
  courseLevel: {
    courseLevel: Joi.string().required().messages({
      "string.empty": "Level is required",
      "string.base": "Level is required",
    }),
  },
  courseName: {
    courseName: Joi.string().required().messages({
      "string.empty": "Title is required",
      "string.base": "Title is required",
    }),
  },
  courseCode: {
    courseCode: Joi.string().required().messages({
      "string.empty": "Code is required",
      "string.base": "Code is required",
    }),
  },
  // courseCode: {
  //   courseCode: Joi.string().required().messages({
  //     "string.empty": "Course code is required",
  //   }),
  // },
  // courseName: {
  //   courseName: Joi.string().required().messages({
  //     "string.empty": "Please select the course applied",
  //   }),
  // },
  // courseLevel: {
  //   courseLevel: Joi.any()
  //     .valid("artisan", "certificate", "diploma", "shortCourse")
  //     .required()
  //     .messages({
  //       "any.required": "Please select  course level",
  //       "any.invalid": "Please select  course level",
  //       "any.default": "Please select  course level",
  //       "any.only": "Please select course level",
  //     }),
  // },
  sponsorship: {
    sponsorship: Joi.string()
      .valid("self", "parent", "organization")
      .required()
      .messages({
        "any.only": "Please select one of the buttons",
        "string.base": "Please select one of the buttons",
      }),
  },
  tc: {
    tc: Joi.boolean().truthy("yes").valid(true).required().messages({
      "any.only": "Please accept the terms and conditions",
      "string.base": "Please accept the terms and conditions",
    }),
  },
  nameOfSponsor: {
    nameOfSponsor: Joi.alternatives().conditional("sponsorship", {
      is: Joi.string().valid("parent", "organization"),
      then: Joi.string().required().messages({
        "string.empty": "Please provide the name of the sponsor",
      }),
      otherwise: Joi.any().optional(),
    }),
  },
  telOfSponsor: {
    telOfSponsor: Joi.alternatives().conditional("sponsorship", {
      is: Joi.string().valid("parent", "organization"),
      then: Joi.number().required().messages({
        "number.empty": "Please provide sponsor telephone number",
        "number.base": "Sponsor telephone number must be a number",
      }),
      otherwise: Joi.any().optional(),
    }),
  },
  modeOfStudy: {
    modeOfStudy: Joi.string()
      .valid("morning", "fullTime", "evening", "online")
      .required()
      .messages({
        "any.only": "Please select mode of study",
        "string.base": "Please select mode of study",
      }),
  },

  /////////////////////////////////
  salary: {
    salary: Joi.number().integer().required().messages({
      "number.empty": "Salary is required",
      "number.base": "Salary must be a number",
    }),
  },
  userName: {
    userName: Joi.string().required().messages({
      "string.empty": "User name is required",
      "string.base": "User name is required",
    }),
  },
  /////////////////////////////////
  period: {
    period: Joi.number().integer().required().messages({
      "number.empty": "Duration is required",
      "number.base": "Duration must be a number",
    }),
  },

  // confirmPassword: Joi.ref("password"),userName
};
function generateFieldsSchema(formFields) {
  let obj = {};
  Object.keys(formFields).forEach((value) => {
    const schm = new_schema[`${value}`];
    obj = { ...obj, ...schm };
  });
  return obj;
}

export function validateStudent(value) {
  const schemaProp = generateFieldsSchema(value);
  const studentSchema = Joi.object().keys(schemaProp);
  const stSchema = validator(studentSchema);
  const { error } = stSchema(value);
  if (error) {
    let newError = [];
    error.details.map((err) => {
      let fieldName = err.context.key;
      let message = err.message;
      return (newError = [...newError, { fieldName, message }]);
    });

    return newError[0];
  } else {
    return null;
  }
}
