import { CircularProgress, Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import React from "react";

export default function StudentFinance({ student, loading }) {
  return (
    <Stack>
      Student Finance
      {student?.studentStatus === "admitted" && loading && (
        <CircularProgress color="info" size={20} />
      )}
      {student?.studentStatus === "admitted" && !loading && (
        <>
          <Typography mt={3}>
            Outstanding fee balance as on{" "}
            {moment(Date.now()).format("YYYY-DD-MM HH:mm:ss")}
          </Typography>
          {student?.feeBalance !== null
            ? "Kshs " + student?.feeBalance
            : "There is no fee payment record for this student."}
        </>
      )}
      {student?.studentStatus !== "admitted" && (
        <Typography mt={3}>
          This section is only for Admitted Student
        </Typography>
      )}
    </Stack>
  );
}
