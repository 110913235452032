import * as React from "react";
import { Box, Button, Stack, TextField, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useValue } from "../../../context/contextProvider";
import { addExpenditure } from "../../../actions/expenditure";
import moment from "moment";
import { tokens } from "../../../theme";

const RecordExpForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [submit, setSubmit] = useState("");
  let resetFormState = null;
  const {
    dispatch,
    state: { currentUser },
  } = useValue();
  const handleFormSubmit = (values) => {
    updateConfirmDialogWithList(values);
  };
  const recordExpenditure = async (expenditureData) => {
    setSubmit("submiting");
    const result = await addExpenditure(
      currentUser.token,
      dispatch,
      expenditureData
    );
    if (result === 1) handleFormReset("expeSubmited");
  };
  const handleFormReset = (resetForm) => {
    if (resetForm === "expeSubmited") {
      resetFormState();
    } else {
      resetForm();
    }
    setSubmit("");
    resetFormState = null;
  };
  const updateConfirmDialog = (act) => {
    const message =
      "You are about to reset this form all information will be deleted. Are you sure you want to proceed? Be Careful !";
    const title = "Form Reset";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: handleFormReset,
      },
    });
  };
  const updateConfirmDialogWithList = (act) => {
    const message =
      "You are about to submit the following record, Please confirm before submiting!";
    const title = "Record Expenditure";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG_WITH_LIST",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: recordExpenditure,
        formData: initialValues,
      },
    });
  };
  return (
    <Box
      py={4}
      px={1}
      backgroundColor={colors.primary[400]}
      borderRadius={1}
      height="600px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          resetForm,
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {isSubmitting && resetFormState === null
              ? (resetFormState = resetForm)
              : ""}
            <Stack
              direction="column"
              spacing={4}
              sx={{
                px: 2,
                overflow: "auto",
                maxHeight: "500px",
                backgroundColor: colors.primary[400],
                "& .Mui-focused": {
                  color: "#f5079e !important",
                  input: {
                    color: "#0ba2de !important",
                  },
                },
                "& .Mui-focused.Mui-error": {
                  color: "#f44336 !important",
                },
                "& .css-uc0k7-MuiInputBase-root-MuiFilledInput-root:before": {
                  borderBottom: "2px solid #0ba2de !important",
                },
                "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
                  borderBottom: "2px solid #f5079e !important",
                },
                "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                  {
                    borderBottom: "#f44336 !important",
                  },
              }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {" "}
                <DatePicker
                  disableFuture
                  label="Date Spent"
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={values.dateSpent ? values.dateSpent : null}
                  onChange={(newValue) => {
                    setFieldValue(
                      "dateSpent",
                      moment(newValue.$d).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      color="info"
                      {...params}
                      error={!!touched.dateSpent && !!errors.dateSpent}
                      helperText={touched.dateSpent && errors.dateSpent}
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                fullWidth
                type="number"
                label="Amount Spent (Kshs)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={!!touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
                // sx={{
                //   gridColumn: "span 2",
                //   "& .Mui-focused": {
                //     color: "#f2f0f0 !important",
                //     input: {
                //       color: "#f2f0f0 !important",
                //     },
                //   },
                //   "& .Mui-focused.Mui-error": {
                //     color: "#f44336 !important",
                //   },
                //   "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                //     {
                //       borderBottom: "2px solid #0ba2de !important",
                //     },
                //   "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
                //     borderBottom: "2px solid #f5079e !important",
                //   },
                //   "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                //     {
                //       borderBottom: "#f44336 !important",
                //     },
                // }}
              />

              <TextField
                fullWidth
                color="info"
                type="text"
                label="Spent For"
                multiline
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.spentFor}
                name="spentFor"
                error={!!touched.spentFor && !!errors.spentFor}
                helperText={touched.spentFor && errors.spentFor}
              />

              <FormControl
                sx={{
                  "& .css-w3lmqu-MuiFormLabel-root.Mui-focused": {
                    color: "#f2f0f0 !important",
                  },
                  "& .css-1a9y42x-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                    { color: "#0ba2de !important" },
                }}
                error={!!touched.methodOfPayment && !!errors.methodOfPayment}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Method Of Payment
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="methodOfPayment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.methodOfPayment}>
                  <FormControlLabel
                    value="cash"
                    control={<Radio />}
                    label="Cash"
                  />
                  <FormControlLabel
                    value="mpesa"
                    control={<Radio />}
                    label="M-pesa"
                  />
                  <FormControlLabel
                    value="bank"
                    control={<Radio />}
                    label="Bank"
                  />
                </RadioGroup>
                <FormHelperText>
                  {touched.methodOfPayment && errors.methodOfPayment}
                </FormHelperText>
              </FormControl>

              {values.methodOfPayment !== "" && (
                <TextField
                  fullWidth
                  type="text"
                  label={`Enter The Receipt No (${values.methodOfPayment})`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.receiptNo}
                  name="receiptNo"
                  error={!!touched.receiptNo && !!errors.receiptNo}
                  helperText={touched.receiptNo && errors.receiptNo}
                />
              )}
              {values.methodOfPayment !== "" && (
                <TextField
                  fullWidth
                  type="number"
                  label={`The recipient phone number`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.recipientPhone}
                  name="recipientPhone"
                  error={!!touched.recipientPhone && !!errors.recipientPhone}
                  helperText={touched.recipientPhone && errors.recipientPhone}
                />
              )}
              <TextField
                fullWidth
                type="text"
                multiline
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                color="info"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />

              <Stack direction="row" justifyContent="space-between" mt="35px">
                <Button
                  color="error"
                  variant="contained"
                  disabled={submit === "submiting"}
                  onClick={() => updateConfirmDialog(resetForm)}>
                  RESET FORM
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={submit === "submiting"}>
                  SUBMIT
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  dateSpent: yup.string().required("required"),
  amount: yup.string().required("required"),
  spentFor: yup.string().required("required"),
  methodOfPayment: yup.string().required("Select one of the buttons above"),
  receiptNo: yup.string().required("Required"),
  recipientPhone: yup.string().required("Required"),
  // description: yup.string().required("Required"),
});

const initialValues = {
  dateSpent: "",
  amount: "",
  spentFor: "",
  methodOfPayment: "",
  receiptNo: "",
  recipientPhone: "",
  description: "",
};

export default RecordExpForm;
