import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import EmailIcon from "@mui/icons-material/Email";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useValue } from "../../context/contextProvider";
import { useEffect } from "react";
import { getStudents } from "../../actions/student";
import { ViewList } from "@mui/icons-material";
import useCheckToken from "../../hooks/useCheckToken";
import { useState } from "react";
import { getStaffs } from "../../actions/staff";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [fetched, setFetched] = useState(false);

  const navigate = useNavigate();
  const dashboardLinkView = useCallback(
    (location) => navigate(`/${location}`, { replace: true }),
    [navigate]
  );
  const {
    state: { students, currentUser, staffs },
    dispatch,
  } = useValue();
  useCheckToken();
  const fetchStudents = useCallback(async () => {
    if (currentUser && !fetched) {
      if (students.length === 0) await getStudents(currentUser.token, dispatch);
      if (staffs.length === 0) await getStaffs(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [currentUser, dispatch, students, fetched, staffs]);
  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  const navigateStudentsPage = (index) => {
    dispatch({
      type: "UPDATE_STUDENTS_PAGE",
      payload: index,
    });
    dashboardLinkView("students");
  };
  const navigateStaffsPage = (index) => {
    dispatch({
      type: "UPDATE_STAFFS_PAGE",
      payload: index,
    });
    dashboardLinkView("staff");
  };
  return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="DASHBOARD" subtitle="Welcome to Pitps dashboard" />

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px">
        {/* ROW 1 */}
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: colors.primary[400],
          }}
          onClick={() => navigateStudentsPage(2)}>
          <StatBox
            // title="12,361"
            subtitle="Student Registration Form"
            // progress="0.75"
            // increase="+14%"

            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Paper>
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: colors.primary[400],
          }}
          onClick={() => navigateStaffsPage(1)}>
          <StatBox
            title={staffs && staffs.length}
            subtitle="Staff Members"
            progress="0.50"
            increase="+21%"
            icon={
              <PeopleOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Paper>
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: colors.primary[400],
          }}
          onClick={() => navigateStudentsPage(0)}>
          <StatBox
            title={
              students &&
              students.filter((student) => student.studentStatus === "admitted")
                .length
            }
            subtitle="Admitted Students"
            progress="0.30"
            increase="+5%"
            icon={
              <GroupAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Paper>
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: colors.primary[400],
          }}
          onClick={() => navigateStudentsPage(1)}>
          <StatBox
            title={
              students &&
              students.filter((student) => student.studentStatus !== "admitted")
                .length
            }
            subtitle="Students Awaiting Admission"
            progress="0.80"
            increase="+43%"
            icon={
              <GroupAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Paper>

        {/* ROW 2 */}
        {/* <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}>
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center">
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}>
                Revenue Generated
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}>
                $59,342.32
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box> */}
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 4",
            gridRow: "span 3",

            bgcolor: colors.primary[400],
            overflow: "auto",
          }}>
          <List
            // disablePadding
            sx={{ overflow: "auto", maxHeight: 300 }}
            subheader={
              <ListSubheader
                sx={{ bgcolor: colors.primary[400] }}
                component="div"
                id="nested-list-subheader">
                Recent Online Applicantion
              </ListSubheader>
            }>
            {students &&
              students
                .filter((student) => student.studentStatus !== "admitted")
                .slice(0, 10)
                .map((student, i) => (
                  <>
                    <ListItem
                      key={student.studentId}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          color="info"
                          title="View"
                          component="a"
                          href={`/students/${student.studentId}`}>
                          <ViewList />
                        </IconButton>
                      }>
                      <ListItemText
                        primary={
                          <Typography
                            color={colors.greenAccent[500]}
                            variant="h5"
                            fontWeight="600">
                            {student.mobileNo}
                          </Typography>
                        }
                        secondary={
                          <Typography color={colors.grey[100]}>
                            {student.fullNames}
                          </Typography>
                        }
                      />
                      <ListItemText
                        primary={
                          <Typography color={colors.grey[100]}>
                            {student.studentStatus}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider sx={{ opacity: 0.5 }} />
                  </>
                ))}
          </List>
        </Paper>

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px">
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Expenditures
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px">
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600">
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px">
                Kshs {transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}>
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px">
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px">
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}>
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
