import * as React from "react";
import { Box, useTheme, Typography, Stack } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useState } from "react";
import AddUnitsForm from "../../components/unit/addUnitsForm";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { useValue } from "../../context/contextProvider";
import { useEffect } from "react";
import { useCallback } from "react";
import { getUnits } from "../../actions/unit";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const UnitsPage = () => {
  const {
    state: { currentUser, units, loading },
    dispatch,
  } = useValue();
  const [expanded, setExpanded] = useState(false);
  const [fetched, setFetched] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [chipData, setChipData] = React.useState([]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = (data) => {
    alert("You clicked the Chip.");
  };
  const fetchUnits = useCallback(async () => {
    if (currentUser && units.length === 0 && !fetched) {
      await getUnits(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [currentUser, units, dispatch, fetched]);

  useEffect(() => {
    fetchUnits();
  }, [fetchUnits]);
  return (
    <Box m="20px">
      {/* HEADER */}

      <Header title="UNITS" subtitle="Add & View Units" />

      <Box
        display="grid"
        gap="20px"
        px={4}
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="180px">
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 4",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "450px",
            p: 1,
          }}>
          {" "}
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
              Unit Form
            </Typography>
          </Stack>{" "}
          <Box backgroundColor={colors.primary[400]}>
            <AddUnitsForm />
          </Box>
        </Paper>
        <Paper
          elevation={4}
          sx={{
            gridColumn: "span 8",
            bgcolor: colors.primary[400],
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "630px",
            p: 1,
          }}>
          {" "}
          <Stack sx={{ bgcolor: colors.primary[400], borderRadius: 1, py: 1 }}>
            {" "}
            <Typography textAlign="center" fontSize="20px">
              List Of Units
            </Typography>
          </Stack>{" "}
          <Box backgroundColor={colors.primary[400]} height="570px" py={4}>
            <Box
              width="100%"
              overflow="auto"
              maxHeight="500px"
              sx={{
                "&:: -webkit-scrollbar": {
                  display: "none",
                },
              }}>
              {units.length
                ? units.map((dt) => {
                    return (
                      <Box
                        key={dt.id}
                        sx={{
                          pl: "60px",
                          pr: "60px",
                          "& :: -webkit-scrollbar": {
                            display: "none",
                          },
                        }}>
                        <Accordion
                          expanded={expanded === `panel${dt.id}`}
                          onChange={handleChange(`panel${dt.id}`)}
                          sx={{ bgcolor: colors.primary[400] }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Box display="flex" gap="97px" pl="15px">
                              <Box pl="15px" pr="15px">
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  color={colors.blueAccent[700]}>
                                  Unit Code
                                </Typography>

                                <Typography
                                  variant="h6"
                                  color={colors.grey[200]}>
                                  {dt.unitCode}
                                </Typography>
                              </Box>
                              <Box pr="15px">
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  color={colors.blueAccent[700]}>
                                  Unit Name
                                </Typography>
                                <Box display="flex" gap="10px">
                                  <Typography
                                    variant="h6"
                                    color={colors.grey[200]}>
                                    {dt.unitName}
                                  </Typography>
                                  <ModeEditOutlineOutlinedIcon
                                    fontSize="small"
                                    color="secondary"
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box display="flex" gap="115px">
                              <Box mt="-15px" pl="15px">
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  color={colors.blueAccent[700]}>
                                  Courses
                                </Typography>
                                <Box display="flex" gap="10px">
                                  <Paper
                                    sx={{
                                      bgcolor: colors.primary[400],
                                      display: "flex",
                                      justifyContent: "center",
                                      flexWrap: "wrap",
                                      listStyle: "none",
                                      p: 0.5,
                                      m: 0,
                                    }}
                                    component="ul">
                                    {chipData.map((data) => {
                                      let icon;

                                      if (data.label === "React") {
                                        icon = <TagFacesIcon />;
                                      }

                                      return (
                                        <ListItem key={data.key}>
                                          <Chip
                                            icon={icon}
                                            label={data.label}
                                            onClick={() => handleClick(data)}
                                            onDelete={
                                              data.label === "React"
                                                ? undefined
                                                : handleDelete(data)
                                            }
                                          />
                                        </ListItem>
                                      );
                                    })}
                                  </Paper>
                                </Box>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <br />
                      </Box>
                    );
                  })
                : !loading
                ? "No Record Found"
                : ""}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default UnitsPage;
