import { Box, CircularProgress } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const CourseList = ({ loading, courses }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const courseViewPage = useCallback(
    (id) => navigate("/course/" + id, { replace: true }),
    [navigate]
  );

  const columns = [
    { field: "courseCode", headerName: "Course Code", flex: 0.2 },
    { field: "courseLevel", headerName: "Level" },
    {
      field: "courseName",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      type: "number",
      headerAlign: "left",
      flex: 0.3,
      align: "left",
    },
  ];

  return (
    <Box
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}>
      <DataGrid
        rows={courses}
        getRowId={(row) => row.id}
        columns={columns}
        loading={loading}
        components={{ Toolbar: GridToolbar }}
        onRowClick={(rows) => courseViewPage(rows.id)}
        InputProps={{
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="info" size={20} /> : null}
            </React.Fragment>
          ),
        }}
      />
    </Box>
  );
};

export default CourseList;
