import React from "react";
import {
  useTheme,
  TextField,
  IconButton,
  Tooltip,
  Stack,
  Paper,
  List,
  ListItemText,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../../theme";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PrintIcon from "@mui/icons-material/Print";
import CircularProgress from "@mui/material/CircularProgress";
import { useValue } from "../../../context/contextProvider";
import { studentSearch } from "../../../actions/student";
import {
  getFeePaymentHistry,
  getFeeStatementPDF,
  getPayment,
} from "../../../actions/fees";
import { DownloadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export default function TransactionHistory(props) {
  const { setLoading, loading } = props;
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchedStudents, setSearchedStudents] = React.useState([]);
  const [searching, setSearching] = React.useState(false);
  const [downloadId, setDownloadId] = React.useState(null);
  const [downloading, setDownloading] = React.useState(false);
  const {
    dispatch,
    state: { currentUser, feesHistry },
  } = useValue();

  const fetchIndFeeHistry = async (sid) => {
    setLoading(true);
    await getPayment(sid, dispatch, currentUser.token);
    setLoading(false);
  };
  const fetchAllFeeHistry = async () => {
    setLoading(true);
    await getFeePaymentHistry(dispatch, currentUser.token);
    setLoading(false);
  };
  const fetchFees = (value) => {
    const id = value?.id;
    if (id) {
      setDownloadId(id);
      fetchIndFeeHistry(id);
    } else {
      setDownloadId(null);
      fetchAllFeeHistry();
    }
  };
  const requestFeeStatement = async (id) => {
    setDownloading(true);
    if (id) {
      await getFeeStatementPDF(id, dispatch, currentUser.token);
      setDownloading(false);
    } else {
      setDownloading(false);
      return;
    }
  };
  const searchStudents = async (e) => {
    const studentIdNo = e.target.value;
    if (!studentIdNo || studentIdNo === "") return setSearchedStudents([]);
    setSearching(true);
    const students = await studentSearch(
      studentIdNo,
      dispatch,
      currentUser.token
    );
    if (students && students.length) {
      setSearchedStudents(students);
    } else {
      setSearchedStudents([]);
    }

    setSearching(false);
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Stack direction="column" spacing={4}>
      <Typography variant="h4">Fees Transaction History</Typography>

      <Autocomplete
        limitTags={10}
        id="checkboxes-tags-demo"
        options={searchedStudents}
        onChange={(event, value) => fetchFees(value)}
        loading={searching}
        getOptionLabel={(option) => option.idNo}
        sx={{
          width: "300px",
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />

            {option.fullNames}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            color="info"
            name="studentAdm"
            {...params}
            onChange={(event) => searchStudents(event)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {searching ? (
                    <CircularProgress color="info" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label="Filter by Student Id/Admission Number"
            placeholder="Student Id/Admission Number"
          />
        )}
      />

      <Paper
        elevation={2}
        sx={{
          p: 2,
          width: "70%",
          height: "500px",
          // overflow: "auto",
          bgcolor: colors.primary[600],
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}>
        {loading && <CircularProgress color="info" size={20} />}
        {downloadId && !loading && feesHistry.length > 0 && (
          <>
            {" "}
            <Typography variant="h4" textTransform="capitalize">
              {feesHistry[0]?.studentFullNames}
            </Typography>{" "}
            <LoadingButton
              size="small"
              loading={downloading}
              color="info"
              title="Download feeStatement"
              variant="contained"
              sx={{ width: 150 }}
              loadingPosition="start"
              startIcon={<DownloadOutlined size="small" />}
              onClick={() => requestFeeStatement(downloadId)}>
              {downloading ? "DOWNLOADING..." : "FEE STATEMENT"}
            </LoadingButton>
          </>
        )}
        <List
          sx={{
            p: 2,
           
            maxHeight: "90%",
            overflow: "auto",
            bgcolor: colors.primary[600],
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}>
          {" "}
          {!loading &&
            feesHistry.length > 0 &&
            feesHistry.map((dt) => {
              return (
                <Accordion
                  key={dt.id}
                  expanded={expanded === `panel${dt.id}`}
                  onChange={handleChange(`panel${dt.id}`)}
                  sx={{ bgcolor: colors.primary[500] }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Typography
                      noWrap
                      sx={{
                        width: "40%",
                        flexShrink: 0,
                        textTransform: "capitalize",
                      }}>
                      {dt.studentFullNames}
                    </Typography>
                    <Typography sx={{ width: "40%", color: "text.secondary" }}>
                      {"Kshs: " + dt.amount}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{
                        width: "40%",
                        flexShrink: 0,
                      }}>
                      {"Payed on: " + dt.dateOfPayment}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Card
                      sx={{
                        maxWidth: 650,

                        // mt: 1,
                        backgroundColor: colors.primary[600],
                        maxHeight: 250,
                      }}>
                      <CardContent>
                        <Typography color="secondary" textAlign="center">
                          Transaction Receipt Number {dt.receiptNo}
                        </Typography>
                        <Stack direction="row" spacing={15} sx={{ pl: 3 }}>
                          <List>
                            {" "}
                            <ListItemText
                              primary={
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  color={colors.blueAccent[700]}>
                                  Date Of Payment
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="h6"
                                  color={colors.grey[200]}>
                                  {dt.dateOfPayment}
                                </Typography>
                              }
                            />
                            <ListItemText
                              primary={
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  color={colors.blueAccent[700]}>
                                  Amount Paid (Kshs)
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="h6"
                                  color={colors.grey[200]}>
                                  {dt.amount}
                                </Typography>
                              }
                            />
                            <ListItemText
                              primary={
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  color={colors.blueAccent[700]}>
                                  {dt.methodOfPayment === "bank"
                                    ? "Account Number"
                                    : "Phone Number"}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="h6"
                                  color={colors.grey[200]}>
                                  {dt.accountNo}
                                </Typography>
                              }
                            />
                          </List>
                          <List>
                            {" "}
                            <ListItemText
                              primary={
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  color={colors.blueAccent[700]}>
                                  Method Of Payment
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="h6"
                                  textTransform="capitalize"
                                  color={colors.grey[200]}>
                                  {dt.methodOfPayment}
                                </Typography>
                              }
                            />
                            <ListItemText
                              primary={
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  color={colors.blueAccent[700]}>
                                  Transaction Completed By
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="h6"
                                  textTransform="capitalize"
                                  color={colors.grey[200]}>
                                  {dt.staffFullNames}
                                </Typography>
                              }
                            />
                            <ListItemText
                              primary={
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  color={colors.blueAccent[700]}>
                                  Status
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="h6"
                                  color={colors.grey[200]}>
                                  {dt.paymentStatus}
                                </Typography>
                              }
                            />
                          </List>
                        </Stack>

                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          sx={{ pl: 3 }}>
                          <Typography variant="body2" color="text.secondary">
                            This Transaction was recorded on {dt.dateCreated}
                          </Typography>
                          <Tooltip title="REPRINT RECEIPT">
                            <IconButton>
                              <PrintIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </CardContent>
                    </Card>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </List>

        {!loading && feesHistry.length === 0 && "No Record Found"}
      </Paper>
    </Stack>
  );
}
