import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  Button,
  ListItemText,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import { useValue } from "../context/contextProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationWithList() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    state: { confirmDialogWithList },
    dispatch,
  } = useValue();

  const handleClose = () => {
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG_WITH_LIST",
      payload: {
        open: false,
        title: "",
        message: "",
        action: "",
        func: null,
        formData: null,
      },
    });
  };
  const handleProceed = () => {
    handleClose();
    confirmDialogWithList.func(confirmDialogWithList.action);
  };
  const style = { backgroundColor: colors.primary[500], color: "#fff" };

  return (
    <div>
      <Dialog
        open={confirmDialogWithList.open}
        TransitionComponent={Transition}
        scroll="paper"
        keepMounted
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={style}>{confirmDialogWithList.title}</DialogTitle>
        <DialogContent sx={{ ...style, p: 4, boxSizing: "border-box" }}>
          <DialogContentText id="alert-dialog-slide-description">
            {confirmDialogWithList.message}
          </DialogContentText>
          <Paper
            elevation={5}
            sx={{ p: 2, background: "inherit", m: "10px 30px" }}>
            {confirmDialogWithList.open && confirmDialogWithList.formData && (
              <List>
                {Object.keys(confirmDialogWithList.formData)
                  .filter((value) => value !== "id")
                  .map((val, i) => {
                    return (
                      <Box key={i}>
                        <ListItem>
                          <ListItemText
                            primary={val}
                            secondary={confirmDialogWithList.action[`${val}`]}
                            sx={{
                              textTransform:
                                val !== "Email" ? "capitalize" : undefined,
                            }}
                          />
                        </ListItem>
                        {i !==
                          Object.keys(confirmDialogWithList.formData).length -
                            1 && <Divider variant="middle" />}
                      </Box>
                    );
                  })}
              </List>
            )}
          </Paper>
        </DialogContent>
        <DialogActions
          sx={{ ...style, px: 10, justifyContent: "space-between" }}>
          <Button onClick={handleClose}>
            <Typography color="white !important">Cancel</Typography>
          </Button>
          <Button onClick={handleProceed}>
            <Typography color="white !important">Submit</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
