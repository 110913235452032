import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "salary";

export const getSalary = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_SALARY",
      payload: result,
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const addSalary = async (token, dispatch, salary) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData({ url, body: salary, token: token }, dispatch);
  if (result) {
    getSalary(token, dispatch);
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Salary submited successfully!",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const getStaffSalaryHistry = async (id, dispatch, token, navigate) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/staffSalaryView/${id}`, method: "GET", token: token },
    dispatch
  );
  if (result) {
    if (result[0]) {
      dispatch({
        type: "UPDATE_STAFF_SALARY",
        payload: result[0],
      });
    }
    if (!result[0]) {
      dispatch({
        type: "UPDATE_STAFF_SALARY",
        payload: {},
      });
      navigate("/salary/" + id + "/notFound");
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Resource not found!",
        },
      });
    }
  }

  dispatch({ type: "END_LOADING" });
};
export const getSalaryHistry = async (staffNo, dispatch, token) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/salaryViewStaff/${staffNo}`, method: "GET", token: token },
    dispatch
  );
  if (result) {
    if (result) {
      dispatch({
        type: "UPDATE_SALARY_HISTRY",
        payload: result,
      });
    }
  }

  dispatch({ type: "END_LOADING" });
};
