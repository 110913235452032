import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "feeStructure";

export const getFeeStructure = async (cid, token, dispatch) => {
  const result = await fetchData(
    { url: `${url}/${cid}`, method: "GET", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_FEESTRUCTURE",
      payload: { ...result },
    });
    return { ...result };
  }
};
export const addFeeStructure = async (token, dispatch, feeStructure) => {
  const result = await fetchData(
    { url, body: feeStructure, token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_FEESTRUCTURE",
      payload: { ...result },
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "FeeStructure submited successfully!",
        act: "expeSubmited",
      },
    });
    return { ...result };
    // getIntakeClasses(token, dispatch);
  }
};

export const updateFeeStructure = async (dispatch, token,feeStructure) => {
  const result = await fetchData(
    {
      url: `${url}/${feeStructure.id}`,
      body: feeStructure,
      method: "PUT",
      token: token,
    },
    dispatch
  );
  if (result) {
   dispatch({
     type: "UPDATE_FEESTRUCTURE",
     payload: { ...result },
   });
   dispatch({
     type: "UPDATE_ALERT",
     payload: {
       open: true,
       severity: "success",
       message: "FeeStructure submited successfully!",
       act: "expeSubmited",
     },
   });
   return { ...result };
  }
};


