import { Box, List, Paper, Stack } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React from "react";
import ProfileModal from "../../components/profileModal";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useValue } from "../../context/contextProvider";
import EditableDisplayField from "../../components/utils/textFields/editableDisplayField";
import { updateUser, updateUserProfile } from "../../actions/user";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "30%",
  left: "30%",
  width: "40%",
  height: "42%",
  border: "1px solid #0ba2de",
  boxShadow: 14,
  p: 6,
};

const ProfilePage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    state: { currentUser, loading },
    dispatch,
  } = useValue();
  const [file, setFile] = React.useState(null);

  const uploadProfilePic = async () => {
    const userProfilePic = new FormData();
    userProfilePic.append("doc", file);
    const result = await updateUserProfile(
      currentUser,
      userProfilePic,
      dispatch
    );
    if (result?.profilePic) setFile(null);
  };

  const saveChanges = async (changes, initialData) => {
    const newUser = { ...currentUser, ...changes };
    await updateUser(newUser, dispatch);
  };
  return (
    <Box m="20px">
      <Header title="PROFILE PAGE" subtitle="Manage Your Profile Info" />

      <Paper elevation={4} sx={{ ...style, bgcolor: colors.primary[400] }}>
        <Stack direction="row" gap={10}>
          <Stack direction="column" gap={4}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <IconButton
                  color="primary"
                  title="Change profile pic"
                  aria-label="upload picture"
                  component="label">
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    name="profilePic"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <PhotoCamera
                    sx={{
                      color: colors.primary[700],
                      "&:hover": {
                        color: colors.blueAccent[500],
                      },
                    }}
                  />
                </IconButton>
              }>
              <Avatar
                alt="Travis Howard"
                src={
                  file
                    ? URL.createObjectURL(file)
                    : currentUser && currentUser?.profilePic
                    ? process.env.REACT_APP_PROFILE_PROURL +
                      currentUser?.profilePic
                    : process.env.REACT_APP_DEFAULT_PROFILE_PROURL
                }
                sx={{ width: 90, height: 90 }}
              />
            </Badge>

            {file && (
              <LoadingButton
                onClick={uploadProfilePic}
                sx={{
                  backgroundColor: colors.greenAccent[500],
                  color: colors.grey[100],
                  "&:hover": {
                    borderBottom: 1,
                    borderColor: "red",
                    backgroundColor: colors.blueAccent[500],
                  },
                }}
                size="large"
                fullWidth
                loading={loading}
                color="success"
                startIcon=""
                loadingPosition="start"
                variant="contained">
                <span>{loading ? "Saving.." : " SAVE IMAGE"} </span>
              </LoadingButton>
            )}
          </Stack>
          <Stack direction="column" gap={3}>
            <List>
              {" "}
              <EditableDisplayField
                formLabel="User Name"
                saveChanges={saveChanges}
                mainProps={{
                  value: currentUser && currentUser.userName,
                  name: "userName",
                  key: currentUser && currentUser.token,
                }}
                optionalProps={{
                  editable: "true",
                  primary: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: colors.blueAccent[700],
                  },
                  secondary: {
                    variant: "h6",
                    color: colors.grey[200],
                    textTransform: "capitalize",
                  },
                }}
              />
              <EditableDisplayField
                formLabel="Email Address"
                saveChanges={saveChanges}
                mainProps={{
                  value: currentUser && currentUser.email,
                  name: "email",
                  type: "email",
                  key: currentUser && currentUser.token,
                }}
                optionalProps={{
                  editable: "true",
                  primary: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: colors.blueAccent[700],
                  },
                  secondary: {
                    variant: "h6",
                    color: colors.grey[200],
                    textTransform: "lowercase",
                  },
                }}
              />
            </List>

            <ProfileModal user={currentUser} />
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default ProfilePage;
