import * as React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import CollectFeesForm from "../../components/fees/collectFees";
import TransactionHistory from "../../components/fees/transactionHistory";
import { useValue } from "../../context/contextProvider";
import { getFeePaymentHistry } from "../../actions/fees";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const FeesPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const {
    dispatch,
    state: { currentUser, feesHistry },
  } = useValue();

  const fetchAllFeeHistry = async (value) => {
    if (feesHistry.length === 0) {
      setLoading(true);
      await getFeePaymentHistry(dispatch, currentUser.token);
      setLoading(false);
    }

  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
       fetchAllFeeHistry();
    }
   
  };

  const handleChangeIndex = (index) => {
    setValue(index);
   
  };

  return (
    <Box m="20px">
      <Header title="FEES" subtitle="Manage Fees & Fee Statement" />
      <Box m="20px">
        <AppBar
          position="static"
          sx={{ bgcolor: colors.primary[400], width: 750, ml: 10 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example">
            <Tab
              label="Collect Fees"
              {...a11yProps(0)}
              sx={{
                color:
                  value === 0 ? "#0ba2de !important" : "#f1f1f1 !important",
                fontWeight: value === 0 ? "bold !important" : undefined,
              }}
            />

            <Tab
              label="Transaction History"
              {...a11yProps(1)}
              sx={{
                color:
                  value === 1 ? "#0ba2de !important" : "#f1f1f1 !important",
                fontWeight: value === 1 ? "bold !important" : undefined,
              }}
            />
          </Tabs>
        </AppBar>
        <Box
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box
              m="0 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}>
              <Typography color="#0ba2de" fontWeight="bold" fontSize="17px">
                COLLECT SCHOOL FEES
              </Typography>
              <CollectFeesForm />
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <Box
              m="0 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}>
              <TransactionHistory {...{ setLoading, loading }} />
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default FeesPage;
