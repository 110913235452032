import { Box, Button, Typography } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import AccountMenu from "../global/ColoredTab";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import LinearColor from "../../components/progress";
import { useValue } from "../../context/contextProvider";
import { useEffect } from "react";
import { getStudents } from "../../actions/student";
import OnlineApplicants from "../../components/students/studentList/pendingStudents/onlineApplicants";
import AdmittedStudents from "../../components/students/studentList/admittedStudents/admittedStudents";
import StudentFormTab from "../../components/students/studentRegistration";
import useCheckToken from "../../hooks/useCheckToken";
import { useState } from "react";
import { useCallback } from "react";

const Student = () => {
  useCheckToken();
  const [fetched, setFetched] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const header = [
    {
      subtitle: "Admitted Students",
      title: "LIST OF ADMITTED STUDENTS",
    },
    {
      subtitle: "Sudents Awaiting Admission",
      title: "LIST OF REGISTERED STUDENTS",
    },
    {
      subtitle: "Register Student",
      title: "STUDENT REGISTRATION FORM",
    },
  ];
  const {
    state: {
      studentsPage: { index },
      students,
      loading,
      currentUser,
    },
    dispatch,
  } = useValue();
  const ref = useRef();
  const navigate = useNavigate();
  const studentViewPage = (id) => navigate("/students/" + id);

  const fetchStudents = useCallback(async () => {
    if (
      (index === 0 || index === 1) &&
      students.length === 0 &&
      currentUser !== null &&
      currentUser.token &&
      !fetched
    ) {
      await getStudents(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [currentUser, dispatch, students, fetched, index]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);
  useEffect(() => {
     setFetched(false);
  }, [index]);
  const columns = [
    { field: "fullNames", headerName: "FullNames", flex: 1.1 },
    { field: "idNo", headerName: "ID/PassPort", flex: 0.6 },
    {
      field: "mobileNo",
      headerName: "Mobile No",
      flex: 0.6,
      cellClassName: "name-column--cell",
    },
    {
      field: "gender",
      headerName: "Gender",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
    },
    {
      field: "nationality",
      headerName: "Nationality",
      flex: 0.5,
    },
    {
      field: "courseName",
      headerName: "Course",
      flex: 2,
    },
    {
      field: "modeOfStudy",
      headerName: "ModeOfStudy",
      flex: 0.5,
    },

    {
      field: "dateCreated",
      headerName: "Date",
      flex: 0.76,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Status",
      flex: 0.7,
      cellClassName: "actions",
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            width="50px"
            mr="2px"
            color={params.row.studentStatus === "new" ? "warning" : "success"}
            sx={{ fontSize: "10px" }}>
            {params.row.studentStatus === "admitted" &&
            params.row.admissionNumber !== "N/A"
              ? params.row.admissionNumber
              : params.row.studentStatus}
          </Button>
        );
      },
    },
    // {
    //   field: "city",
    //   headerName: "City",
    //   flex: 1,
    // },
    // {
    //   field: "idNo",
    //   headerName: "ID Number",
    //   flex: 1,
    // },
  ];
  const admColums = [...columns].map((col) =>
    col.field === "dateCreated"
      ? { ...col, headerName: "Date admitted", field: "dateAdmitted" }
      : col.field === "actions"
      ? { ...col, headerName: "Admission no" }
      : col
  );
  return (
    <Box m="20px">
      <Header title="STUDENTS" subtitle={header[index].subtitle} />
      <AccountMenu />
      <Box
        ref={ref}
        m="25px 0 0 0"
        px={7}
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <Typography color="#0ba2de" fontWeight="bold" fontSize="16px">
          {header[index].title}
        </Typography>

        {loading && index !== 2 && <LinearColor />}
        {
          {
            0: (
              <AdmittedStudents
                students={students.filter(
                  (student) =>
                    student.studentStatus === "admitted" &&
                    student.admissionNumber !== "N/A"
                )}
                columns={admColums}
                loading={loading}
                GridToolbar={GridToolbar}
                studentViewPage={studentViewPage}
              />
            ),
            1: (
              <OnlineApplicants
                students={students.filter(
                  (student) =>
                    student.studentStatus !== "admitted" &&
                    student.admissionNumber === "N/A"
                )}
                columns={columns}
                loading={loading}
                GridToolbar={GridToolbar}
                studentViewPage={studentViewPage}
              />
            ),
            2: <StudentFormTab />,
          }[index]
        }
      </Box>
    </Box>
  );
};

export default Student;
