import React from "react";
import {
  Box,
  useTheme,
  TextField,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ClassCourseStudentList from "./classCourseStudentList";
import { tokens } from "../../../theme";
// import ClassCourseUnitsList from "./classCourseUnits";
import { useValue } from "../../../context/contextProvider";
import { useState } from "react";
import { useEffect } from "react";

export default function CourseViewClasses({ searching }) {
  const {
    state: {
      //  currentUser,
      courseClassSessions,
    },
    // dispatch,
  } = useValue();
  const [expanded, setExpanded] = useState(false);
  const [classSessions, setClassSessions] = useState(courseClassSessions);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleFilterClassSessions = (e) => {
    const value = e.target.value;
    if (value === "all") {
      setClassSessions(courseClassSessions);
    } else {
      setClassSessions(
        courseClassSessions.filter((cs) => cs.id === Number(value))
      );
    }
  };
  useEffect(() => {
    setClassSessions(courseClassSessions);
  }, [courseClassSessions]);
  return (
    <Box>
      <TextField
        variant="filled"
        select
        label="Select Class"
        onChange={handleFilterClassSessions}
        InputProps={{
          endAdornment: (
            <React.Fragment>
              {searching ? <CircularProgress color="info" size={20} /> : null}
            </React.Fragment>
          ),
        }}
        name="month"
        SelectProps={{
          native: true,
        }}
        sx={{
          width: 300,
          pb: 3,
          "& .Mui-focused": {
            color: "#f2f0f0 !important",
            input: {
              color: "#f2f0f0 !important",
            },
          },
          "& .Mui-focused.Mui-error": {
            color: "#f44336 !important",
          },
          "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before": {
            borderBottom: "2px solid #0ba2de !important",
          },
          "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
            borderBottom: "2px solid #f5079e !important",
          },
          "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
            {
              borderBottom: "#f44336 !important",
            },
        }}>
        <option value="all">ALL</option>
        {courseClassSessions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.classTitle}
          </option>
        ))}
      </TextField>
      {classSessions.length > 0 && (
        <Box
          maxHeight="500px"
          overflow="auto"
          backgroundColor={colors.primary[500]}
          p={1}>
          {classSessions.map((cs, i) => (
            <Accordion
              key={cs.id}
              expanded={expanded === "panel1" + cs.id}
              onChange={handleChange("panel1" + cs.id)}
              sx={{
                bgcolor: colors.primary[500],
                mb: classSessions?.length - i !== 1 && 2,
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {cs.classTitle}
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    width: "33%",
                    flexShrink: 0,
                  }}>
                  Status : {cs.sessionStatus}
                </Typography>
                <Typography sx={{ color: "text.secondary", width: "33%" }}>
                  Created on : {cs.dateCreated}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" spacing={20}  justifyContent='center'>
                  <Button color="info" size="large" variant="outlined">
                    Students
                  </Button>
                  <Button color="info" size="large" variant="outlined">
                    Units
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  );
}
