import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "intakeClass";

export const getIntakeClasses = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_INTAKECLASSES",
      payload: result,
    });
  }
  dispatch({ type: "END_LOADING" });
};
export const addIntakeClass = async (token, dispatch, intakeClass) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, body: intakeClass, token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Data submited successfully!",
        act: "expeSubmited",
      },
    });
    getIntakeClasses(token, dispatch);
  }
  dispatch({ type: "END_LOADING" });
  if (result === 1) return result;
};

export const getIntakeClass = async (cid, dispatch, token) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${cid}`, method: "GET", token: token },
    dispatch
  );
    if (result) {
      dispatch({
        type: "UPDATE_INTAKECLASS",
        payload: result,
      });
    }

  dispatch({ type: "END_LOADING" });
};

export const assignClassCourses = async (token, dispatch, course) => {
  const result = await fetchData(
    { url: url + "/addCourse", body: course, token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Data submited successfully!",
        act: "expeSubmited",
      },
    });
    return result;
  }
};
