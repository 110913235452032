import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import { Box, Paper, Stack, useTheme } from "@mui/material";
import Header from "../../components/Header";
import DesignationModal from "../../components/designationModal";
import SimpleBackdrop from "../../components/progress/backdrop";
import { useValue } from "../../context/contextProvider";
import { getDesignations, updateDesignation } from "../../actions/designation";
import useCheckToken from "../../hooks/useCheckToken";
import EditableDisplayField from "../../components/utils/textFields/editableDisplayField";
import { useCallback } from "react";
import { useEffect } from "react";

export default function DesignationAccordions() {
  useCheckToken();
  const [expanded, setExpanded] = React.useState(false);
  const [fetched, setFetched] = React.useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    state: { designations, loading, currentUser },
    dispatch,
  } = useValue();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const saveChanges = (changes, initialData) => {
    const newDesignation = { ...initialData, ...changes };
    updateDesignation(
      initialData.id,
      dispatch,
      currentUser.token,
      newDesignation
    );
  };

  const fetchDesignations = useCallback(async () => {
    if (
      designations.length === 0 &&
      currentUser !== null &&
      currentUser.token &&
      !fetched
    ) {
      await getDesignations(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [designations, currentUser, dispatch, fetched]);

  useEffect(() => {
    fetchDesignations();
  }, [fetchDesignations]);

  return (
    <>
      {loading && <SimpleBackdrop />}
      <Box display="flex" flexDirection="column" sx={{ mt: 1, ml: 5 }} gap={3}>
        <Header
          title="DESIGNATION"
          subtitle="Add, Edit and Manage Staff Designation"
        />
        <DesignationModal />
        <Typography fontWeight="bold" variant="h4" color={colors.grey[200]}>
          TOTAL RECORDS: {designations.length}
        </Typography>
        <Paper
          elevation={4}
          sx={{
            overflow: "auto",
            bgcolor: colors.primary[400],
            maxHeight: 700,
            p: 2,
            mx: 35,
          }}>
          {designations.length > 0
            ? designations.map((designation) => {
                return (
                  <Accordion
                    key={designation.id}
                    expanded={expanded === `panel${designation.id}`}
                    onChange={handleChange(`panel${designation.id}`)}
                    sx={{ bgcolor: colors.primary[400] }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack direction="row" spacing={8}>
                        <EditableDisplayField
                          formLabel="Title"
                          mainProps={{ value: designation.nameTitle }}
                          optionalProps={{
                            primary: {
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: colors.blueAccent[700],
                            },
                            secondary: {
                              variant: "h6",
                              color: colors.grey[200],
                              textTransform: "capitalize",
                            },
                          }}
                        />

                        <EditableDisplayField
                          formLabel="Salary"
                          saveChanges={saveChanges}
                          mainProps={{
                            type: "number",
                            value: designation.salary,
                            name: "salary",
                            initialData: designation,
                          }}
                          optionalProps={{
                            editable: "true",
                            primary: {
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: colors.blueAccent[700],
                            },
                            secondary: {
                              variant: "h6",
                              color: colors.grey[200],
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack
                        direction="column"
                        spacing={3}
                        sx={{ pl: 4, pb: 4 }}>
                        <Box>
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            color={colors.blueAccent[700]}>
                            Qualification
                          </Typography>

                          <Typography
                            display="flex"
                            variant="h6"
                            color={colors.grey[200]}>
                            1. Above 25 years <br />
                            2. Atleast 2 years experience <br />
                            3. Kenyan Citizen <br />
                          </Typography>
                        </Box>

                        <Box>
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            color={colors.blueAccent[700]}>
                            Responsibilities
                          </Typography>
                          <Typography variant="h6" color={colors.grey[200]}>
                            1. Above 25 years <br />
                            2. Atleast 2 years experience <br />
                            3. Kenyan Citizen <br />
                          </Typography>
                        </Box>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : !loading
            ? "No Record Found"
            : ""}
        </Paper>
      </Box>
    </>
  );
}
