import jwtDecode from "jwt-decode";
const fetchData = async (
  { url, method = "POST", token = "", body = null, type = "" },
  dispatch
) => {
  const headers =
    type === "file"
      ? { authorization: `Bearer ${token}` }
      : type === "login"
      ? { "Content-Type": "application/json" }
      : {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        };
  // const headers = {
  //   "Content-Type": "application/json",
  //   authorization: `Bearer ${token}`,
  // };
  body =
    body && type !== "file"
      ? { body: JSON.stringify(body) }
      : body && type === "file"
      ? { body: body }
      : {};
  try {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        dispatch({ type: "LOGOUT_USER", payload: null });
        throw new Error("Session expired!!");
      }
    }
    const response = await fetch(url, { method, headers, ...body });
    // if (type === "file") return response;
    const data = await response.json();
    if (!data.success) {
      if (response.status === 401 || response.status === 404) {
        dispatch({ type: "LOGOUT_USER", payload: null });
      }
      throw new Error(data.message);
    }
    return data.result;
  } catch (error) {
    if (error.message === "Failed to fetch")
      error.message = "Error! Please check on your connection";
    dispatch({
      type: "UPDATE_ALERT",
      payload: { open: true, severity: "error", message: error.message },
    });
    return null;
  }
};
export default fetchData;
