import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "auth/";
const userUrl = process.env.REACT_APP_SERVER_PROURL + "user";

export const logOut = async (dispatch) => {
  await fetchData(
    { url: url + "logout", method: "POST", type: "login" },
    dispatch
  );
  localStorage.removeItem("currentUser");
  dispatch({ type: "LOGOUT_USER", payload: null });
  dispatch({ type: "UPDATE_STUDENTS", payload: [] });
  dispatch({ type: "UPDATE_STUDENT", payload: {} });
};

export const login = async (inputs, dispatch) => {
  dispatch({ type: "START_LOGIN_LOADING" });
  const result = await fetchData(
    { url: url + "login", method: "POST", body: inputs, type: "login" },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_USER",
      payload: result,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Login successfully",
      },
    });
  }
  dispatch({ type: "END_LOGIN_LOADING" });
};

export const updateUser = async (userInfo, dispatch) => {
  const result = await fetchData(
    {
      url: `${userUrl}/${userInfo.id}`,
      body: userInfo,
      method: "PUT",
      token: userInfo.token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_USER",
      payload: userInfo,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Account updated successfully",
      },
    });
  }
};

export const updateUserPsd = async (user, userInfo, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    {
      url: `${userUrl}/updateUserPsd/${user.id}`,
      body: userInfo,
      token: user.token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Password changed successfully",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};

export const updateUserProfile = async (user, userProfilePic, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    {
      url: `${userUrl}/updateProfilePic/${user.id}`,
      body: userProfilePic,
      token: user.token,
      type: "file",
    },
    dispatch
  );
    dispatch({ type: "END_LOADING" });
  if (result) {
    dispatch({
      type: "UPDATE_USER",
      payload: { ...user, ...result },
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Profile picture updated successfully",
      },
    });
    return result;
  }


};
