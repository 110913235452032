import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "intakeClassSession";

// export const getIntakeClassSess = async (token, dispatch) => {
//   dispatch({ type: "START_LOADING" });
//   const result = await fetchData(
//     { url, method: "GET", token: token },
//     dispatch
//   );
//   if (result) {
//     dispatch({
//       type: "UPDATE_INTAKECLASSES",
//       payload: result,
//     });
//   }
//   dispatch({ type: "END_LOADING" });
// };
// export const addIntakeClassS = async (token, dispatch, intakeClass) => {
//   dispatch({ type: "START_LOADING" });
//   const result = await fetchData(
//     { url, body: intakeClass, token: token },
//     dispatch
//   );
//   if (result) {
//     dispatch({
//       type: "UPDATE_ALERT",
//       payload: {
//         open: true,
//         severity: "success",
//         message: "Data submited successfully!",
//         act: "expeSubmited",
//       },
//     });
//     getIntakeClassSess(token, dispatch);
//   }
//   dispatch({ type: "END_LOADING" });
//   if (result === 1) return result;
// };

export const getIntakeClassSesCourses = async (cid, dispatch, token) => {
  const result = await fetchData(
    { url: `${url}/getClassCourses/${cid}`, method: "GET", token: token },
    dispatch
  );

  if (result) {
    dispatch({
      type: "UPDATE_INTAKECLASS_SESSION",
      payload: result,
    });
    return result;
  }
};

export const getIntakeClassSes = async (cid, dispatch, token) => {
  const result = await fetchData(
    { url: `${url}/${cid}`, method: "GET", token: token },
    dispatch
  );

  if (result) {
    dispatch({
      type: "UPDATE_INTAKECLASS_SESSION",
      payload: result,
    });
    return result;
  }
};
export const getAvailableCourseIntakeClassSes = async (
  cid,
  dispatch,
  token
) => {
  const result = await fetchData(
    { url: `${url}/availableClass/${cid}`, method: "GET", token: token },
    dispatch
  );

  if (result) {
    // dispatch({
    //   type: "UPDATE_INTAKECLASS_SESSION",
    //   payload: result,
    // });
    return result;
  }
};

export const getCourseClassSessions = async (cid, token, dispatch) => {
  const result = await fetchData(
    {
      url: `${url}/getCourseClassSessions/${cid}`,
      method: "GET",
      token: token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_COURSECLASSSES",
      payload: [...result],
    });
    return [...result];
  }
};
