import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";
import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { useValue } from "../../../context/contextProvider";
import { addFeeStructure } from "../../../actions/feestructure";
// import { Box, Typography } from "@mui/material";

export default function AddFeeStructureDialog({ open, setOpen, course }) {
  const {
    state: { currentUser },
    dispatch,
  } = useValue();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [feeStructure, setFeeStructure] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleFeeStructure = (e) => {
    const opt = [
      [{ year: 1, fees: [], total: 0, next: 0, status: "current" }],
      [
        { year: 1, fees: [], total: 0, next: 2, status: "current" },
        { year: 2, fees: [], total: 0, next: 0, status: "upcoming" },
      ],
      [
        { year: 1, fees: [], total: 0, next: 2, status: "current" },
        { year: 2, fees: [], total: 0, next: 3, status: "upcoming" },
        { year: 3, fees: [], total: 0, next: 0, status: "upcoming" },
      ],
    ];
    const value = e.target.value;
    setFeeStructure(opt[value]);
  };
  const terms = [
    [{ term: 1, amount: 0, next: 0, status: "current" }],
    [
      { term: 1, amount: 0, next: 2, status: "current" },
      { term: 2, amount: 0, next: 0, status: "upcoming" },
    ],
    [
      { term: 1, amount: 0, next: 2, status: "current" },
      { term: 2, amount: 0, next: 3, status: "upcoming" },
      { term: 3, amount: 0, next: 0, status: "upcoming" },
    ],
  ];
  const handleFeeSubmit = async (e) => {
    e.preventDefault();
    const values = { feeStructure: feeStructure, courseId: course.id };
    setLoading(true);
    await addFeeStructure(currentUser.token, dispatch, values);
    handleClose();
    setLoading(false);
  };
  const style = { backgroundColor: colors.primary[500] };
  const years = [
    {
      value: 0,
      label: 1,
    },
    {
      value: 1,

      label: 2,
    },
    {
      value: 2,
      label: 3,
    },
  ];
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        keepMounted
        open={open && currentUser}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle sx={style} id="responsive-dialog-title">
          ADD FEE STRUCTURE
        </DialogTitle>
        <form onSubmit={handleFeeSubmit} method="POST">
          <DialogContent sx={style}>
            {/* <DialogContentText>ADD FEE STRUCTURE</DialogContentText> */}
            <TextField
              color="info"
              required
              variant="filled"
              select
              label="Select Number of years"
              disabled={loading}
              onChange={(e) => handleFeeStructure(e)}
              // value={feeStructure.year}
              name="year"
              SelectProps={{
                native: true,
              }}
              // error={!!touched.month && !!errors.month}
              // helperText={touched.month && errors.month}
              sx={{
                width: 200,
                // gridColumn: "span 2",
                "& .Mui-focused": {
                  color: "#f2f0f0 !important",
                  input: {
                    color: "#f2f0f0 !important",
                  },
                },
                "& .Mui-focused.Mui-error": {
                  color: "#f44336 !important",
                },
                "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before": {
                  borderBottom: "2px solid #0ba2de !important",
                },
                "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after": {
                  borderBottom: "2px solid #f5079e !important",
                },
                "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                  {
                    borderBottom: "#f44336 !important",
                  },
              }}>
              <option></option>
              {years.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            {feeStructure.length > 0 && (
              <Box display="flex" flexDirection="column" gap={3} mt={3}>
                {feeStructure.map((option) => (
                  <Box key={option.year}>
                    {" "}
                    <span>Year {option.year} : </span>
                    <TextField
                      color="info"
                      disabled={loading}
                      required
                      select
                      name="term"
                      onChange={(e) =>
                        setFeeStructure(
                          feeStructure.map((val) =>
                            val.year === option.year
                              ? { ...val, fees: terms[e.target.value] }
                              : val
                          )
                        )
                      }
                      sx={{ width: 150 }}
                      label="Number of terms"
                      SelectProps={{
                        native: true,
                      }}>
                      <option></option>
                      <option value={0}>1</option>
                      <option value={1}>2</option>
                      <option value={2}>3</option>
                    </TextField>
                  </Box>
                ))}
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={style}>
            <Button
              disabled={loading}
              autoFocus
              onClick={handleClose}
              sx={{ color: "#fff" }}>
              Cancel
            </Button>
            <Button
              disabled={loading}
              type="submit"
              autoFocus
              sx={{ color: "#fff" }}>
              SUBMIT
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
