import fetchData from "./utils/fetchData";
const url = process.env.REACT_APP_SERVER_PROURL + "field";

export const getFields = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  dispatch({ type: "END_LOADING" });
  if (result) {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: result,
    });
    return result;
  }
};
export const addField = async (token, dispatch, field) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData({ url, body: field, token: token }, dispatch);
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Data submited successfully!",
        act: "expeSubmited",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
  if (result.id) return result;
};
export const deleteField = async (token, dispatch, id) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${id}`, method: "DELETE", token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Field deleted successfully!",
        act: "expeSubmited",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
  if (result === 1) return result;
};
export const updateField = async (token, dispatch, field) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url: `${url}/${field.id}`, method: "PUT", body: field, token: token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Field updated successfully!",
        act: "expeSubmited",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
  if (result.id) return result;
};
export const searchField = async (token, dispatch, fieldName) => {
  const result = await fetchData(
    { url: `${url}/search/${fieldName}`, method: "GET", token: token },
    dispatch
  );
  if (result) return result;
};
