import * as React from "react";
import { Button, Stack, TextField, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useValue } from "../../../../context/contextProvider";
import { addIntakeClass } from "../../../../actions/intakeClass";
import moment from "moment";
import { tokens } from "../../../../theme";

const AddClassesForm1 = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    dispatch,
    state: { currentUser },
  } = useValue();
  let resetFormState = null;
  const recordExpenditure = async (Data) => {
    const result = await addIntakeClass(currentUser.token, dispatch, Data);
    if (result === 1) handleFormReset("classSubmited");
  };
  const handleFormReset = (resetForm) => {
    if (resetForm === "classSubmited") {
      resetFormState();
    } else {
      resetForm();
    }
    resetFormState = null;
  };
  // const updateConfirmDialog = (act) => {
  //   const message =
  //     "You are about to reset this form all information will be deleted. Are you sure you want to proceed? Be Careful !";
  //   const title = "Form Reset";
  //   dispatch({
  //     type: "UPDATE_CONFIRM_DIALOG",
  //     payload: {
  //       open: true,
  //       title: title,
  //       message: message,
  //       action: act,
  //       func: handleFormReset,
  //     },
  //   });
  // };
  const updateConfirmDialogWithList = (act) => {
    const message =
      "You are about to submit the following record, Please confirm before submiting!";
    const title = "Record Intake Class";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG_WITH_LIST",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: recordExpenditure,
        formData: initialValues,
      },
    });
  };

  const handleFormSubmit = (values) => {
    updateConfirmDialogWithList(values);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            {isSubmitting && resetFormState === null
              ? (resetFormState = resetForm)
              : ""}
            <Stack
              direction="column"
              spacing={4}
              sx={{
                p: 2,
             
                height: "370px",
                backgroundColor: colors.primary[400],
              }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                color="info"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {" "}
                <DatePicker
                  label="Reporting Date"
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={values.reportingDate ? values.reportingDate : null}
                  onChange={(newValue) => {
                    setFieldValue(
                      "reportingDate",
                      moment(newValue.$d).format("YYYY-MM-DD")
                    );
                  }}
                  name="reportingDate"
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="filled"
                      color="info"
                      {...params}
                      error={!!touched.reportingDate && !!errors.reportingDate}
                      helperText={touched.reportingDate && errors.reportingDate}
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                multiline
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                color="info"
              />
              <Button type="submit" color="secondary" variant="contained">
                SUBMIT
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  // description: yup.string().required("required"),
  title: yup.string().required("required"),
  reportingDate: yup.string().required("required"),
});

const initialValues = {
  title: "",
  reportingDate: "",
  description: "",
};

export default AddClassesForm1;
