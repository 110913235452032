import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { tokens } from "../../../theme";
import { Box, List, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useValue } from "../../../context/contextProvider";
import EditableDisplayField from "../../utils/textFields/editableDisplayField";
import { updateStudentPersonalInfo } from "../../../actions/student";
import StudentFinance from "./studentFinance";
import { getFeeStatement } from "../../../actions/fees";
import FileUploads from "../../utils/comps/FileUploads";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StudentProfileTab({ student }) {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 3 && !loading && student?.feeBalance === null) {
      fetchFeeBalance();
    }
  };
  const {
    state: { currentUser, attachedCert },
    dispatch,
  } = useValue();

  const fetchFeeBalance = async () => {
    setLoading(true);
    const result = await getFeeStatement(
      student.studentId,
      dispatch,
      currentUser.token
    );
    if (result) {
      dispatch({
        type: "UPDATE_STUDENT_FEEBALANCE",
        payload: result,
      });
    }
    setLoading(false);
  };

  const saveChanges = async (changes, initialData) => {
    const newStudent = { ...student, ...changes };
    await updateStudentPersonalInfo(
      student.studentId,
      dispatch,
      currentUser.token,
      newStudent
    );
  };

  const personalInfo = [
    { formLabel: "Date Of Birth", value: student.DOB },
    {
      formLabel: "ID/Passport",
      value: student.idNo,
      editable: true,
      name: "idNo",
    },
    { formLabel: "Gender", value: student.gender },
    {
      formLabel: "Mobile Number",
      value: student.mobileNo,
      editable: true,
      name: "mobileNo",
    },
    {
      formLabel: "Email",
      value: student.email,
      editable: true,
      name: "email",
      type: "email",
    },
  ];
  const personalInfo2 = [
    { formLabel: "Guardian/Kin", value: student.guardian },
    { formLabel: "Relationship", value: student.relationShip },
    { formLabel: "Guardian/Kin Telephone", value: student.tel },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="basic tabs example">
          <Tab
            label="Personal Info"
            {...a11yProps(0)}
            sx={{
              color: value === 0 ? "#0ba2de !important" : "#f1f1f1 !important",
              fontWeight: value === 0 ? "bold !important" : undefined,
            }}
          />
          <Tab
            label="Education Background"
            {...a11yProps(1)}
            sx={{
              color: value === 1 ? "#0ba2de !important" : "#f1f1f1 !important",
              fontWeight: value === 1 ? "bold !important" : undefined,
            }}
          />
          <Tab
            label="Uploads"
            {...a11yProps(2)}
            sx={{
              color: value === 2 ? "#0ba2de !important" : "#f1f1f1 !important",
              fontWeight: value === 2 ? "bold !important" : undefined,
            }}
          />
          <Tab
            label="Student Finance"
            {...a11yProps(3)}
            sx={{
              color: value === 3 ? "#0ba2de !important" : "#f1f1f1 !important",
              fontWeight: value === 3 ? "bold !important" : undefined,
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{ p: 1 }}>
        <Stack direction="row" spacing={15} sx={{ pl: 1 }}>
          <List>
            {personalInfo.map((info, i) => (
              <EditableDisplayField
                key={i}
                formLabel={info.formLabel}
                saveChanges={saveChanges}
                mainProps={{
                  value: info.value,
                  name: info.name || "",
                  type: info.type || "",
                  key: student && "student" + student.studentId,
                }}
                optionalProps={{
                  editable: info.editable || false,
                  primary: {
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: colors.blueAccent[700],
                  },
                  secondary: {
                    variant: "h6",
                    color: colors.grey[200],
                    textTransform:
                      info.type === "email" ? "lowercase" : "capitalize",
                  },
                }}
              />
            ))}
          </List>
          <List>
            {" "}
            {personalInfo2.map((info, i) => (
              <EditableDisplayField
                key={i}
                formLabel={info.formLabel}
                mainProps={{ value: info.value }}
                optionalProps={{
                  primary: {
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: colors.blueAccent[700],
                  },
                  secondary: {
                    variant: "h6",
                    color: colors.grey[200],
                    textTransform: "capitalize",
                  },
                }}
              />
            ))}
          </List>
        </Stack>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Box>
          {student &&
            student.studyInfo &&
            student.studyInfo !== "N/A" &&
            JSON.parse(student.studyInfo).map((value, index) => {
              return (
                <Paper key={index} elevation={3} sx={{ mb: 2, p: 2 }}>
                  <Box display="flex" gap="10px" flexDirection="column">
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        color={colors.blueAccent[700]}>
                        School Attended
                      </Typography>

                      <Typography
                        variant="h6"
                        color={colors.grey[200]}
                        textTransform="capitalize">
                        {value.schAttended}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        color={colors.blueAccent[700]}>
                        Period (Year)
                      </Typography>
                      <Typography
                        variant="h6"
                        color={colors.grey[200]}
                        textTransform="capitalize">
                        {`${value.yearFrom} - ${value.yearTo}`}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        color={colors.blueAccent[700]}>
                        Qualification
                      </Typography>
                      <Typography
                        variant="h6"
                        color={colors.grey[200]}
                        textTransform="capitalize">
                        {value.qualification}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              );
            })}
          {student &&
            student.studyInfo &&
            student.studyInfo === "N/A" &&
            "No Record"}
        </Box>
      </TabPanel>

      {/* next of kin */}

      <TabPanel value={value} index={2} pt={0}>
        <FileUploads
          {...{
            data: student,
            id: student.studentId,
            user: "students",
            attachedDoc: attachedCert,
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={3} pt={0}>
        <StudentFinance {...{ student, loading }} />
      </TabPanel>
    </Box>
  );
}
