import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { useEffect } from "react";
import { useCallback } from "react";
import { useValue } from "../../../context/contextProvider";
import {
  addField,
  deleteField,
  getFields,
  updateField,
} from "../../../actions/field";

function EditToolbar(props) {
  const { updateRows, setRowModesModel, fieldsData } = props;

  const handleClick = () => {
    const field = fieldsData.find((fld) => fld.isNew === true);
    if (field) return;
    const id = randomId();
    updateRows([...fieldsData, { id, fieldName: "", descr: "", isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "fieldName" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add New Field
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  updateRows: PropTypes.func.isRequired,
  fieldsData: PropTypes.array.isRequired,
};

export default function Schools({ value }) {
  const {
    state: { currentUser, fieldsData },
    dispatch,
  } = useValue();
  const [fetched, setFetched] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const updateRows = (newRows) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: newRows,
    });
  };
  const handleDeleteClick = (id) => async () => {
    const res = await deleteField(currentUser.token, dispatch, id);
    if (res === 1) {
      const newRows = fieldsData.filter((row) => row.id !== id);
      updateRows(newRows);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = fieldsData.find((row) => row.id === id);
    if (editedRow.isNew) {
      const newRows = fieldsData.filter((row) => row.id !== id);
      updateRows(newRows);
    }
  };

  const processRowUpdate = async (newRow) => {
    const res = newRow.isNew
      ? await addField(currentUser.token, dispatch, newRow)
      : await updateField(currentUser.token, dispatch, newRow);
    if (!res) return newRow;
    const updatedRow = { ...newRow, isNew: false, ...res };
    const newRows = fieldsData.map((row) =>
      row.id === newRow.id ? updatedRow : row
    );
    updateRows(newRows);
    return updatedRow;
  };
  const fetchFields = useCallback(async () => {
    if (fieldsData.length) {
      const id = fieldsData.find((dept) => dept.isNew === true)?.id;
      if (id) {
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: "fieldName" },
        }));
      }
    }
    if (currentUser && fieldsData.length === 0 && value === 0 && !fetched) {
      setFetched(true);
      await getFields(currentUser.token, dispatch);
    }
    return;
  }, [currentUser, fieldsData, value, dispatch, fetched]);

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);
  const columns = [
    { field: "fieldName", headerName: "Name", width: 200, editable: true },
    { field: "descr", headerName: "Description", width: 350, editable: true },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 180,
      editable: false,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "secondary",
        },
        "& .textPrimary": {
          color: "primary",
        },
      }}>
      <DataGrid
        rows={fieldsData}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => {
          return console.log(error);
        }}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { updateRows, setRowModesModel, fieldsData },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
