import jwtDecode from "jwt-decode";
import { axiosReq } from "../axiosReq";
import { saveAs } from "file-saver";
import axios from "axios";
const url = process.env.REACT_APP_SERVER_PROURL;
export const uploadingFiles = async (
  id,
  token,
  file,
  dispatch,
  setProgress,
  path,
  user
) => {
  const UPDATE_FORM =
    user === "staff"
      ? "UPDATE_STAFF_FORM_UPLOADS"
      : user === "students"
      ? "UPDATE_STUDENT_FORM_UPLOADS"
      : "";
  const UPDATE_STATUS =
    user === "staff"
      ? "UPDATE_STAFF_STATUS"
      : user === "students"
      ? "UPDATE_STUDENT_STATUS"
      : "";
  dispatch({ type: "START_FILE_UPLOAD_LOADING" });
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      dispatch({ type: "LOGOUT_USER", payload: null });
      throw new Error("Session expired!!");
    }
    const upload = await axiosReq.post(path, file, {
      headers: { Authorization: `Bearer ${token}` },
      onUploadProgress: (progressEvent) => {
        setProgress(
          parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        );
      },
    });
    if (upload && upload.data.result) {
      dispatch({
        type: UPDATE_FORM,
        payload: { file: "", fileTitle: "" },
      });

      dispatch({
        type: UPDATE_STATUS,
        payload: {
          id: id,
          data: { docInfo: upload.data.result },
        },
      });
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "success",
          message: "File uploaded successful!",
        },
      });
    }
    dispatch({ type: "END_FILE_UPLOAD_LOADING" });
  } catch (error) {
    dispatch({ type: "END_FILE_UPLOAD_LOADING" });
    if (error?.name === "AxiosError") {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: error?.message,
        },
      });
    } else {
      dispatch({ type: "LOGOUT_USER", payload: null });
    }
    return error;
  }
};

export const fileDownloadingFunc = async (token, doc, path, dispatch) => {
  try {
    const result = await axios.get(
      url + path,

      {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      }
    );
    if (result) {
      saveAs(result.data, doc.originalname);
    }
  } catch (error) {
    if (error?.name === "AxiosError") {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: error?.message,
        },
      });
    } else {
      dispatch({ type: "LOGOUT_USER", payload: null });
    }
    return error;
  }
};
export const fileDeletingdingFunc = async (token, path, user, id, dispatch) => {
  const UPDATE_STATUS =
    user === "staff"
      ? "UPDATE_STAFF_STATUS"
      : user === "students"
      ? "UPDATE_STUDENT_STATUS"
      : "";
  try {
    const result = await axios.delete(
      url + path,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (result) {
      dispatch({
        type: UPDATE_STATUS,
        payload: {
          id: id,
          data: { docInfo: result.data.result },
        },
      });
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "success",
          message: "File deleted successful!",
        },
      });
    }
  } catch (error) {
    if (error?.name === "AxiosError") {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: error?.message,
        },
      });
    } else {
      dispatch({ type: "LOGOUT_USER", payload: null });
    }
    return error;
  }
};
