import * as React from "react";
import { Stack, Typography } from "@mui/material";
// import { tokens } from "../../../theme";
// import { useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { TransitionGroup } from "react-transition-group";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useValue } from "../../../context/contextProvider";

function renderItem({ data, handleRemoveCourse }) {
  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          title="Delete"
          color="error"
          onClick={() => handleRemoveCourse(data)}>
          <DeleteIcon />
        </IconButton>
      }>
      <ListItemText
        primary={
          <Typography sx={{ width: "450px" }} noWrap>
            {data.courseCode}
          </Typography>
        }
        secondary={
          <Typography sx={{ width: "50px" }} noWrap>
            {" "}
            {data.courseName}
          </Typography>
        }
      />
    </ListItem>
  );
}

const ClassCourseList = ({ loading }) => {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  // const [loading, setLoading] = React.useState(false);
  const {
    state: { intakeClassSession },
  } = useValue();

  const handleRemoveCourse = (item) => {
    // setDeletedResourceId(item.id);
    // setOpen(true);
  };

  return (
    <Stack>
      {loading && "Loading..."}

      {!loading && (
        <List sx={{ maxWidth: "500px", maxHeight: "500px", overflow: "auto" }}>
          <TransitionGroup>
            {intakeClassSession.length &&
              intakeClassSession.map((data) => (
                <Collapse key={data.id}>
                  {renderItem({ data, handleRemoveCourse })}
                </Collapse>
              ))}
          </TransitionGroup>
        </List>
      )}
    </Stack>
  );
};

export default ClassCourseList;
