import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import StaffMenu from "../global/staffMenu";
import { useState } from "react";
import { useEffect } from "react";
import { getStaffs } from "../../actions/staff";
import { useValue } from "../../context/contextProvider";
import LinearColor from "../../components/progress";
import CircularProgress from "@mui/material/CircularProgress";
import StaffFormTab from "../../components/staff/staffRegistration";

const Staff = () => {
  const {
    state: {
      staffsPage: { index },
      staffs,
      loading,
      currentUser,
    },
    dispatch,
  } = useValue();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [fetched, setFetched] = useState(false);

  const navigate = useNavigate();
  const studentViewPage = useCallback(
    (id) => navigate("/staff/" + id, { replace: true }),
    [navigate]
  );

  const fetchStaffs = useCallback(async () => {
    if (
      staffs.length === 0 &&
      currentUser !== null &&
      currentUser.token &&
      !fetched
    ) {
      await getStaffs(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [currentUser, dispatch, staffs, fetched]);

  useEffect(() => {
    fetchStaffs();
  }, [fetchStaffs]);
  const columns = [
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "idNumber",
      headerName: "Id Number",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "nameTitle",
      headerName: "Designation",
      flex: 0.5,
    },
    {
      field: "fieldName",
      headerName: "Field",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 0.5,
    },
    {
      field: "county",
      headerName: "County",
      flex: 0.5,
    },
    {
      field: "regDate",
      headerName: "regDate",
      flex: 0.8,
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="STAFF"
        subtitle={index === 2 ? "Register Staff" : "Manage Staffs"}
      />
      <StaffMenu />
      <Box
        m="25px 0 0 0"
        px={7}
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <Typography color="#0ba2de" fontWeight="bold" fontSize="16px">
          {index === 2 ? " STAFF REGISTRATION FORM" : "  LIST OF STAFFS"}
        </Typography>

        {
          {
            1: (
              <>
                {" "}
                {loading && <LinearColor />}
                <DataGrid
                  rows={staffs}
                  columns={columns}
                  loading={loading}
                  getRowId={(row) => row.id}
                  components={{ Toolbar: GridToolbar }}
                  onRowClick={(rows) => studentViewPage(rows.id)}
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="secondary" size={20} />
                        ) : null}
                      </React.Fragment>
                    ),
                  }}
                />
              </>
            ),
            2: <StaffFormTab />,
          }[index]
        }
      </Box>
    </Box>
  );
};

export default Staff;
