import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Staff from "./scenes/staff";
import Student from "./scenes/student/student";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import DesignationAccordions from "./scenes/designation";
import Course from "./scenes/course";
import CourseView from "./scenes/courseView";
import Emails from "./scenes/emails";
import Salary from "./scenes/salary";
import SalaryHistoryView from "./scenes/salaryHistoryView";
import Expenditure from "./scenes/expenditure";
import UnitsPage from "./scenes/units";
import ClassessPage from "./scenes/classes";
import DepartmentsPage from "./scenes/department";
import StaffView from "./scenes/staffView";
import FeesPage from "./scenes/fees";
import Examinations from "./scenes/examination";
import Login from "./scenes/auth/login";
import Resource from "./scenes/resource";
import ProfilePage from "./scenes/profile";
import ClassView from "./scenes/classView";
import Notification from "./components/Notification";
import StudentProfile from "./scenes/student/studentProfile";
import { useValue } from "./context/contextProvider";
import Confirmation from "./components/Confirmation";
import useCheckToken from "./hooks/useCheckToken";
import ConfirmationWithList from "./components/confirmationWithList";
//
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const {
    state: { currentUser },
  } = useValue();

  useCheckToken();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {currentUser && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            <Notification />
            <Confirmation />
            <ConfirmationWithList />
            <Login />
            {currentUser && <Topbar setIsSidebar={setIsSidebar} />}

            <Routes>
              <Route path="*" element={<Dashboard />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/staff" element={<Staff />} />
              <Route path="/staff/:id" element={<StaffView />} />
              <Route path="/students" element={<Student />} />
              <Route path="/students/:id" element={<StudentProfile />} />
              <Route path="/designation" element={<DesignationAccordions />} />
              <Route path="/resource" element={<Resource />} />
              <Route path="/emails" element={<Emails />} />
              <Route path="/course" element={<Course />} />
              <Route path="/course/:id" element={<CourseView />} />
              <Route path="/class" element={<ClassessPage />} />
              <Route path="/class/:id" element={<ClassView />} />
              <Route path="/salary" element={<Salary />} />
              <Route path="/salary/:id" element={<SalaryHistoryView />} />
              <Route path="/fees" element={<FeesPage />} />
              <Route path="/calendar" element={<Calendar />} />

              <Route path="/expenditure" element={<Expenditure />} />
              <Route path="/unit" element={<UnitsPage />} />

              <Route path="/department" element={<DepartmentsPage />} />

              <Route path="/examination" element={<Examinations />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
