import React from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link, useLocation } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PrintIcon from "@mui/icons-material/Print";
import SalaryViewTbl from "../../components/salary/salaryViewTbl";
import { CancelSharp } from "@mui/icons-material";
import { useCallback, useEffect } from "react";
import { useValue } from "../../context/contextProvider";
import { useNavigate } from "react-router-dom";
import { getStaffSalaryHistry } from "../../actions/salary";

const SalaryHistoryView = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [salaryViewReceiptData, setSalaryViewReceiptData] = React.useState();
    const navigate = useNavigate();
  const sid = useLocation().pathname.split("/")[2];
  const {
    state: { currentUser, staffSalary },
    dispatch,
  } = useValue();
    const fetchStaffSalary = useCallback(async () => {
      if (sid) {
        if (currentUser !== null && currentUser.token) {
          await getStaffSalaryHistry(sid, dispatch, currentUser.token, navigate);
        }
      }
    }, [sid, currentUser, dispatch, navigate]);
    useEffect(() => {
      fetchStaffSalary();
    }, [fetchStaffSalary]);
  return (
    <Box m="20px">
      <Tooltip title="Back">
        <Link
          to="/salary"
          style={{ textDecoration: "none", color: colors.grey[100] }}>
          <ArrowBackOutlinedIcon sx={{ fontSize: 20, mt: -4 }} />
        </Link>
      </Tooltip>

      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="SALARY HISTORY"
          subtitle="View Salary Payment History For A Staff"
        />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="240px"
        gap="1px">
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          alignItems="center"
          justifyContent="center">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="17px">
            <img
              alt="profile-user"
              width="190px"
              height="140px"
              src={`../../assets/user.png`}
              style={{ cursor: "pointer", borderRadius: "50%" }}
            />
          </Box>
          <Box
            mt="20px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column">
            <Tooltip title="View More Details">
              <Link
                target="_blank"
                to={`/staffView/${staffSalary.staffID}`}
                style={{ textDecoration: "none", color: colors.grey[100] }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  textTransform="capitalize"
                  color={colors.grey[100]}>
                  {staffSalary.staffTitle + " " + staffSalary.fullNames}
                </Typography>
              </Link>
            </Tooltip>
            <Typography
              variant="h5"
              fontWeight="bold"
              textTransform="capitalize"
              color={colors.greenAccent[500]}>
              {staffSalary.county + ", " + staffSalary.snationality}
            </Typography>
          </Box>
        </Box>
        <Box gridColumn="span 8" backgroundColor={colors.primary[400]}>
          {salaryViewReceiptData && (
            <Card
              sx={{
                maxWidth: 550,
                ml: 5,
                mt: 1,
                backgroundColor: colors.primary[600],
                height: 220,
              }}>
              <CardContent>
                <Typography color="secondary" textAlign="center">
                  Transaction Receipt Number {salaryViewReceiptData.receiptNo}
                </Typography>
                <Box display="flex" gap="150px" m="2px">
                  <Box>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={colors.blueAccent[700]}>
                      Paid For The Month Of
                    </Typography>
                    <Box display="flex" gap="10px">
                      <Typography variant="h6" color={colors.grey[200]}>
                        {salaryViewReceiptData.month}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={colors.blueAccent[700]}>
                      Amount Paid (Kshs)
                    </Typography>
                    <Box display="flex" gap="10px">
                      <Typography variant="h6" color={colors.grey[200]}>
                        {salaryViewReceiptData.amount}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={colors.blueAccent[700]}>
                      Date Of Transaction
                    </Typography>
                    <Box display="flex" gap="10px">
                      <Typography variant="h6" color={colors.grey[200]}>
                        {salaryViewReceiptData.dateCreated}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box mt="-30px" ml="190px" mb="-10px">
                      <Tooltip title="Clear">
                        <IconButton onClick={() => setSalaryViewReceiptData()}>
                          <CancelSharp color="error" />
                        </IconButton>
                      </Tooltip>
                    </Box>{" "}
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={colors.blueAccent[700]}>
                      Method Of Payment
                    </Typography>
                    <Box display="flex" gap="10px">
                      <Typography variant="h6" color={colors.grey[200]}>
                        {salaryViewReceiptData.paymentMode}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={colors.blueAccent[700]}>
                      Transaction Completed By
                    </Typography>
                    <Box display="flex" gap="10px">
                      <Typography variant="h6" color={colors.grey[200]}>
                        Mrs Karanja
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={colors.blueAccent[700]}>
                      Any Other Info
                    </Typography>
                    <Box display="flex" gap="10px">
                      <Typography variant="h6" color={colors.grey[200]}>
                        N/A
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  This Trasaction was recorded by Account: Admin on 29/Nov/2022
                </Typography>
                <Box mt="-10px" ml="460px">
                  <Tooltip title="REPRINT RECEIPT">
                    <IconButton>
                      <PrintIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          )}
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}>
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center">
            <Box sx={{ width: 260, ml: -1 }}>
              <Typography
                variant="h5"
                fontWeight="Bold"
                color={colors.grey[100]}>
                Basic Info
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  ml: 5,
                }}></Typography>
            </Box>
          </Box>
          <Box height="250px" m="10px 0 0 0">
            <Box>
              <Box ml="30px" display="flex" flexDirection="column" gap="8px">
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={colors.blueAccent[700]}>
                    Staff Number
                  </Typography>

                  <Typography
                    variant="h6"
                    color={colors.grey[200]}
                    textTransform="capitalize">
                    {staffSalary.staffNumber}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={colors.blueAccent[700]}>
                    ID Number
                  </Typography>

                  <Typography
                    variant="h6"
                    color={colors.grey[200]}
                    textTransform="capitalize">
                    {staffSalary.idNumber}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={colors.blueAccent[700]}>
                    Phone Number
                  </Typography>

                  <Typography
                    variant="h6"
                    color={colors.grey[200]}
                    textTransform="capitalize">
                    {staffSalary.phoneNumber}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={colors.blueAccent[700]}>
                    Designation
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.grey[200]}
                    textTransform="capitalize">
                    {staffSalary.designation}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={colors.blueAccent[700]}>
                    Last Paid
                  </Typography>
                  <Typography variant="h6" color={colors.grey[200]}>
                    2/9/2022
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={colors.blueAccent[700]}>
                    Salary Balance (Kshs)
                  </Typography>
                  <Typography variant="h6" color={colors.grey[200]}>
                    25000
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}>
          <Box textAlign="center" mt="10px">
            <Typography variant="h5" fontWeight="600">
              Jeremy's Salary Transaction History
            </Typography>
          </Box>
          <Box mt="10px" p="0 10px">
            {staffSalary.staffNo && (
              <SalaryViewTbl
                staffNo={staffSalary.staffNo}
                setSalaryViewReceiptData={setSalaryViewReceiptData}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SalaryHistoryView;
