import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { tokens } from "../../../theme";
import { Box, List, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useValue } from "../../../context/contextProvider";
import EditableDisplayField from "../../utils/textFields/editableDisplayField";
import { updateStaff } from "../../../actions/staff";
import FileUploads from "../../utils/comps/FileUploads";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StaffProfileTab({ staff }) {
  const [value, setValue] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    state: { currentUser, staffAttachedCert },
    dispatch,
  } = useValue();

  const saveChanges = async (changes, initialData) => {
    const newStaff = { ...staff, ...changes };
    await updateStaff(staff.staffId, dispatch, currentUser.token, newStaff);
  };

  const personalInfo = [
    { formLabel: "Date Of Birth", value: staff.dob },
    {
      formLabel: "ID/Passport",
      value: staff.idNumber,
      editable: true,
      name: "idNumber",
    },
    { formLabel: "Gender", value: staff.sgender },
    {
      formLabel: "Mobile Number",
      value: staff.phoneNumber,
      editable: true,
      name: "phoneNumber",
    },
    {
      formLabel: "Email",
      value: staff.email,
      editable: true,
      name: "email",
      type: "email",
    },
  ];
  const personalInfo2 = [
    { formLabel: "Place Of Birth", value: staff.placeOfBirth },
    { formLabel: "County", value: staff.county },
    { formLabel: "Location", value: staff.location },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="basic tabs example">
          <Tab
            label="Personal Info"
            {...a11yProps(0)}
            sx={{
              color: value === 0 ? "#0ba2de !important" : "#f1f1f1 !important",
              fontWeight: value === 0 ? "bold !important" : undefined,
            }}
          />
          <Tab
            label="Uploads"
            {...a11yProps(1)}
            sx={{
              color: value === 1 ? "#0ba2de !important" : "#f1f1f1 !important",
              fontWeight: value === 1 ? "bold !important" : undefined,
            }}
          />
          {/*  <Tab
            label="Uploads"
            {...a11yProps(2)}
            sx={{
              color: value === 2 ? "#0ba2de !important" : "#f1f1f1 !important",
              fontWeight: value === 2 ? "bold !important" : undefined,
            }}
          />
          <Tab
            label="Student Finance"
            {...a11yProps(3)}
            sx={{
              color: value === 3 ? "#0ba2de !important" : "#f1f1f1 !important",
              fontWeight: value === 3 ? "bold !important" : undefined,
            }}
          /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Stack direction="row" spacing={15} sx={{ pl: 1 }}>
          <List>
            {personalInfo.map((info, i) => (
              <EditableDisplayField
                key={i}
                formLabel={info.formLabel}
                saveChanges={saveChanges}
                mainProps={{
                  value: info.value,
                  name: info.name || "",
                  type: info.type || "",
                  key: staff && "staff" + staff.staffId,
                }}
                optionalProps={{
                  editable: info.editable || false,
                  primary: {
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: colors.blueAccent[700],
                  },
                  secondary: {
                    variant: "h6",
                    color: colors.grey[200],
                    textTransform:
                      info.type === "email" ? "lowercase" : "capitalize",
                  },
                }}
              />
            ))}
          </List>
          <List>
            {" "}
            {personalInfo2.map((info, i) => (
              <EditableDisplayField
                key={i}
                formLabel={info.formLabel}
                mainProps={{ value: info.value }}
                optionalProps={{
                  primary: {
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: colors.blueAccent[700],
                  },
                  secondary: {
                    variant: "h6",
                    color: colors.grey[200],
                    textTransform: "capitalize",
                  },
                }}
              />
            ))}
          </List>
        </Stack>
      </TabPanel>

      <TabPanel value={value} index={1}>
        {" "}
        <Box height={400}>
          {" "}
          <FileUploads
            {...{
              data: staff,
              id: staff.staffId,
              user: "staff",
              attachedDoc: staffAttachedCert,
            }}
          />
        </Box>
      </TabPanel>

      {/* next of kin */}

      {/* <TabPanel value={value} index={2} pt={0}></TabPanel> */}
      {/* <TabPanel value={value} index={3} pt={0}> */}
      {/* <StudentFinance {...{ staff }} /> */}
      {/* </TabPanel> */}
    </Box>
  );
}
