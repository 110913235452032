import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { useValue } from "../../context/contextProvider";

const styles = {
  minWidth: 90,
  fontSize: 13,
  "&:hover": {
    color: "#0ba2de",
  },
};

export default function AccountMenu() {
  const {
    state: {
      studentsPage: { index },
    },
    dispatch,
  } = useValue();

  const updateStudentsPage = (index) => {
    dispatch({
      type: "UPDATE_STUDENTS_PAGE",
      payload: index,
    });
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          gap: 4,
          alignItems: "center",
          textAlign: "center",
          maxWidth:"600px"
        }}>
        <Tooltip title="Register Student">
          <Link
            onClick={() => updateStudentsPage(2)}
            style={{
              textDecoration: "none",
              borderBottom: index === 2 ? "2px solid #f5079e" : undefined,
            }}>
            <Typography
              sx={styles}
              color={index === 2 ? "#0ba2de" : "#f1f1f1"}
              fontWeight={index === 2 ? "bold" : undefined}>
              REGISTRATION FORM
            </Typography>
          </Link>
        </Tooltip>
        <Tooltip title="Online/Local Unadmitted Applicants">
          <Link
            onClick={() => updateStudentsPage(1)}
            style={{
              textDecoration: "none",
              borderBottom: index === 1 ? "2px solid #f5079e" : undefined,
            }}>
            <Typography
              sx={styles}
              color={index === 1 ? "#0ba2de" : "#f1f1f1"}
              fontWeight={index === 1 ? "bold" : undefined}>
              STUDENTS AWAITING ADMISSION
            </Typography>
          </Link>
        </Tooltip>
        <Tooltip title="Admitted Students">
          <Link
            onClick={() => updateStudentsPage(0)}
            style={{
              textDecoration: "none",
              borderBottom: index === 0 ? "2px solid #f5079e" : undefined,
            }}>
            <Typography
              sx={styles}
              color={index === 0 ? "#0ba2de" : "#f1f1f1"}
              fontWeight={index === 0 ? "bold" : undefined}>
              ADMITTED STUDENTS
            </Typography>
          </Link>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
}
