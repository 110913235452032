import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, Autocomplete, CircularProgress } from "@mui/material";
import { tokens } from "../../../theme";
import FormField from "../../utils/textFields/FormField";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useValue } from "../../../context/contextProvider";
import { getAvailableCourseIntakeClassSes } from "../../../actions/intakeClassSession";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import { updateStudentAdmission } from "../../../actions/student";

export default function StudentAdmitDialog({
  setOpenStudentAdmDialog,
  openStudentAdmDialog,
  student,
}) {
  const {
    state: { currentUser },
    dispatch,
  } = useValue();
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableClass, setAvailableClass] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const style = { backgroundColor: colors.primary[500] };
  const handleClose = () => {
    setOpenStudentAdmDialog(false);
  };
  const fetchAvailableCourseIntakeClassSes = async (value) => {
    if (value && value.courseId !== "") {
      setSearching(true);
      const availableClasses = await getAvailableCourseIntakeClassSes(
        value.courseId,
        dispatch,
        currentUser.token
      );
      if (availableClasses?.length === 0) {
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message:
              "No available intake Class for this course, please go to the class menu to add one!",
          },
        });
      }
      setAvailableClass(availableClasses);
      setSearching(false);
    }
  };
  const handleFormSubmit = async (values) => {
    setLoading(true);
    const intakeClass =
      values.classTitle === "change"
        ? { classTitle: values.tempClassTitle }
        : { intakeClassSes: student.intakeClassSes };
    const result = await updateStudentAdmission(
      student.studentId,
      dispatch,
      currentUser.token,
      {
        ...values,
        ...intakeClass,
      }
    );
    if (result) handleClose();
    setLoading(false);
  };
  const initialValues = {
    admissionNumber: "",
    classTitle: "",
    intakeClassSes: "",
    dateAdmitted: "",
    tempClassTitle: "",
  };
  return (
    <>
      <Dialog open={openStudentAdmDialog && currentUser} fullWidth>
        <DialogTitle sx={style}>Student Admission</DialogTitle>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValidating,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                sx={{
                  ...style,
                  px: 8,
                  pt: 0,
                  gap: 3,
                  display: "flex",
                  flexDirection: "column",
                }}>
                {loading && (
                  <DialogContentText sx={{ color: colors.blueAccent[700] }}>
                    Submitting please wait....
                  </DialogContentText>
                )}
                <TextField
                  disabled={loading}
                  margin="dense"
                  id="admNo"
                  label="Admission Number"
                  type="text"
                  fullWidth
                  name="admissionNumber"
                  value={values.admissionNumber}
                  variant="standard"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="info"
                  error={!!touched.admissionNumber && !!errors.admissionNumber}
                  helperText={touched.admissionNumber && errors.admissionNumber}
                />
                <FormField
                  err={!!touched.classTitle && !!errors.classTitle}
                  errorMessage={touched.classTitle && errors.classTitle}
                  mainProps={{
                    name: "classTitle",
                    value: values.classTitle,
                  }}
                  formLabel="Intake Class"
                  optionalProps={{
                    disabled: loading,
                    onChange: handleChange,
                    color: "info",
                  }}
                  radioBtn={[
                    {
                      value: student?.classTitle,
                      label: `Use current class : ( ${student?.classTitle} )`,
                    },
                    { value: "change", label: "Change class" },
                  ]}
                />
                {values.classTitle === "change" && (
                  <Autocomplete
                    id="combo-box-demo"
                    loading={searching}
                    onFocus={() => fetchAvailableCourseIntakeClassSes(student)}
                    onChange={(event, value) => {
                      setFieldValue(
                        "tempClassTitle",
                        value?.classTitle ?? "",
                        true
                      );
                      setFieldValue("intakeClassSes", value?.id ?? "", true);
                    }}
                    disabled={values.classTitle !== "change" || loading}
                    options={availableClass}
                    // isOptionEqualToValue={(option, value) =>
                    //   option.value === value.value
                    // }
                    // value={selectedValue[1]}
                    getOptionLabel={(option) => option.classTitle}
                    sx={{
                      gridColumn: "span 7",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Change Intake Class"
                        onBlur={handleBlur}
                        name="intakeClassSes"
                        color="info"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {searching ? (
                                <CircularProgress color="info" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        error={
                          !!touched.intakeClassSes && !!errors.intakeClassSes
                        }
                        helperText={
                          touched.intakeClassSes && errors.intakeClassSes
                        }
                      />
                    )}
                  />
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {" "}
                  <DatePicker
                    disabled={loading}
                    disableFuture
                    label="Date of admission"
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={values.dateAdmitted ? values.dateAdmitted : null}
                    onChange={(newValue) => {
                      setFieldValue(
                        "dateAdmitted",
                        moment(newValue.$d).format("YYYY-MM-DD")
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!touched.dateAdmitted && !!errors.dateAdmitted}
                        helperText={touched.dateAdmitted && errors.dateAdmitted}
                      />
                    )}
                  />
                </LocalizationProvider>
              </DialogContent>
              <DialogActions
                sx={{ ...style, px: 10, justifyContent: "space-between" }}>
                <Button
                  disabled={loading}
                  onClick={handleClose}
                  sx={{ color: "#fff" }}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  loading={loading}
                  // color="secondary"
                  // loadingIndicator="Loading…"
                  sx={{ color: "#fff" }}>
                  <span> Admit</span>
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
const checkoutSchema = yup.object().shape({
  admissionNumber: yup.string().required("required"),
  intakeClassSes: yup.number().when("classTitle", {
    is: "change",
    then: yup.number().required("required"),
    otherwise: yup.number().optional(),
  }),
  tempClassTitle: yup.string().optional(),
  classTitle: yup.string().required("required"),
  dateAdmitted: yup.string().required("required"),
});
