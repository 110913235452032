import {
  // Avatar,
  // InputAdornment,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@mui/material";
import React from "react";
// import { useValue } from "../../../context/contextProvider";
// import pendingIcon from "./icons/progress1.svg";
// import { Check } from "@mui/icons-material";

// let timer;
const FormField = ({
  mainProps,
  optionalProps = {},
  // minLength,
  radioBtn = [],
  formLabel = "",
  err,
  errorMessage,
}) => {
  // const { name, value } = mainProps;
  // const {
  //   state: { errors, currentForm },
  //   dispatch,
  // } = useValue();
  // const [editing, setEditing] = useState(false);
  // const [error, setError] = useState(false);
  // const [success, setSuccess] = useState(false);

  // const handleChange = (e) => {
  // const index = currentForm.index;
  // const action =
  //   index === 0 ? "UPDATE_DETAILS" : index === 1 ? "UPDATE_COURSEINFO" : null;
  // if (!editing) setEditing(true);
  // const v = { [e.target.name]: e.target.value };
  // const newErrors = validateStudent(v, dispatch, e.target.name);
  // if (
  //   (e.target.name === "mobileNo" || e.target.name === "tel") &&
  //   e.target.value.length > minLength
  // ) {
  //   return;
  // }
  // dispatch({
  //   type: action,
  //   payload: { [e.target.name]: e.target.value },
  // });
  // clearTimeout(timer);
  // timer = setTimeout(() => {
  //   setEditing(false);
  //   if (newErrors) {
  //     setError(newErrors);
  //     if (success) setSuccess(false);
  //   } else {
  //     setError(null);
  //     if (!success) setSuccess(true);
  //   }
  // }, 1000);
  // };
  //   useEffect(() => {
  //     if (!editing && value === "" && success) {
  //       setSuccess(false);
  //     }
  //   }, [value]);
  //   useEffect(() => {
  //     const new_error = errors.filter((err) => err.fieldName === name);
  //     if (!editing && new_error.length !== 0) {
  //       setError(...new_error);
  //     } else {
  //       setError(null);
  //     }
  //   }, [errors, editing]);
  return (
    <>
      {radioBtn.length === 0 ? (
        <TextField
          {...mainProps}
          {...optionalProps}
          //   error={error && error.fieldName === mainProps.name}
          //   helperText={
          //     error && error.fieldName === mainProps.name && error.message
          //   }
          //   color={success ? "success" : "primary"}
          variant="outlined"
          // onChange={handleChange}
          // required
        />
      ) : (
        <FormControl
          sx={optionalProps.style}
          error={err}
          disabled={optionalProps.disabled}>
          <FormLabel color={optionalProps.color || ""} sx={{}}>
            {formLabel}
          </FormLabel>
          <RadioGroup
            {...mainProps}
            row
            required
            onChange={optionalProps.onChange}>
            {radioBtn.map((btn) => {
              return (
                <FormControlLabel
                  key={btn.label}
                  value={btn.value}
                  control={<Radio />}
                  label={btn.label}
                />
              );
            })}
          </RadioGroup>
          {err && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
      )}
    </>
  );
};

export default FormField;
