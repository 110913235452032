import * as React from "react";
import { Box, Button, Chip, Stack, TextField, Typography } from "@mui/material";
// import { tokens } from "../../theme";
import Header from "../../components/Header";
// import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { TransitionGroup } from "react-transition-group";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useValue } from "../../context/contextProvider";
import {
  deleteResource,
  getResources,
  uploadResource,
} from "../../actions/resource";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { DownloadOutlined } from "@mui/icons-material";
import { fileDownloadingFunc } from "../../actions/fileUploading";

function renderItem({ resource, updateConfirmDialog, action, handleDownload }) {
  return (
    <ListItem
      secondaryAction={
        <LoadingButton
          size="small"
          loading={action === resource.id}
          color="error"
          title={`Delete ${resource.docTitle}`}
          variant="outlined"
          onClick={() => updateConfirmDialog(resource.id)}>
          <DeleteIcon sx={{ fontSize: 15 }} />
        </LoadingButton>
      }>
      <ListItemText
        // sx={{ pr: 18 }}
        primary={
          <Chip
            disabled={action && action === resource.id}
            title={`Download ${resource.docTitle}`}
            size="small"
            color="info"
            variant="outlined"
            onClick={() => handleDownload(resource)}
            icon={<DownloadOutlined size="small" />}
            label={resource.docTitle}
            sx={{
              flexWrap: "noWrap",
              width: "170px",
              cursor: "pointer",
              justifyContent: "flex-start",
              px: 1,
            }}
          />
        }
      />
    </ListItem>
  );
}

const Resource = () => {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const [file, setFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [action, setAction] = useState(undefined);
  const [fetched, setFetched] = useState(false);
  let formReset;

  const {
    state: { currentUser, resources, loading },
    dispatch,
  } = useValue();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const checkoutSchema = yup.object().shape({
    title: yup.string().required("required"),
  });

  const handleFileChange = (e) => {
    // const allowedFile = new RegExp(/[^\s]+(.*?).(pdf)$/);
    // if (!allowedFile.test(e.target.files[0].name)) {
    //   dispatch({
    //     type: "UPDATE_ALERT",
    //     payload: {
    //       open: true,
    //       severity: "error",
    //       message: "Error: Only pdf file is allowed",
    //     },
    //   });
    // } else {
    setFile(e.target.files[0]);
    // }
  };

  const handleFormSubmit = async (values) => {
    if (!file)
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Error: Please select a file",
        },
      });

    // try {
    setUploadLoading(true);
    const resourceFormData = new FormData();
    resourceFormData.append("doc", file);
    resourceFormData.append("docTitle", values.title);
    const resource = await uploadResource(
      currentUser.token,
      resourceFormData,
      dispatch
    );
    if (resource) {
      removeFile(formReset);
    }
    setUploadLoading(false);
  };
  const removeFile = (resetForm) => {
    setFile("");
    resetForm();
  };

  const handleDownload = async (resource) => {
    const path = `resource/download/${resource.id}`;
    await fileDownloadingFunc(currentUser.token, resource, path, dispatch);
  };
  const updateConfirmDialog = (act) => {
    if (action === act) return;
    const message =
      "You are about to delete this file. Are you sure you want to proceed?";
    const title = "File deleting";
    dispatch({
      type: "UPDATE_CONFIRM_DIALOG",
      payload: {
        open: true,
        title: title,
        message: message,
        action: act,
        func: handleRemoveResource,
      },
    });
  };

  const handleRemoveResource = async (item) => {
    setAction(item);
    await deleteResource(currentUser.token, item, dispatch);
    setAction(null);
  };
  const fetchResources = useCallback(async () => {
    if (currentUser && currentUser.token && !resources.length && !fetched) {
      await getResources(currentUser.token, dispatch);
      setFetched(true);
    }
  }, [currentUser, resources, dispatch, fetched]);
  useEffect(() => {
    fetchResources();
  }, [fetchResources]);
  return (
    <Box m="20px">
      <Header title="RESOURCES" subtitle="Manage Resources" />
      <Box m="20px">
        {" "}
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={Object.assign(initialValues)}
          validationSchema={checkoutSchema}>
          {({
            resetForm,
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="25px"
                gridTemplateColumns="repeat(12, 1fr)"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                  mb: "70px",
                }}>
                {(formReset = resetForm)}
                <TextField
                  fullWidth
                  color="info"
                  multiline
                  variant="filled"
                  type="text"
                  label="title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  name="title"
                  error={!!touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                  sx={{
                    gridColumn: "span 3",
                    "& .Mui-focused": {
                      color: "#f2f0f0 !important",
                      input: {
                        color: "#f2f0f0 !important",
                      },
                    },
                    "& .Mui-focused.Mui-error": {
                      color: "#f44336 !important",
                    },
                    "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                      {
                        borderBottom: "2px solid #0ba2de !important",
                      },
                    "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after":
                      {
                        borderBottom: "2px solid #f5079e !important",
                      },
                    "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                      {
                        borderBottom: "#f44336 !important",
                      },
                  }}
                />

                <TextField
                  disabled
                  color="info"
                  fullWidth
                  variant="filled"
                  label="Upload doc"
                  value={file ? file.name : ""}
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <IconButton
                          color="secondary"
                          aria-label="upload doc"
                          component="label">
                          <input
                            disabled={uploadLoading}
                            name="doc"
                            value={file ? file.filename : ""}
                            hidden
                            type="file"
                            onChange={handleFileChange}
                          />
                          <UploadFileOutlinedIcon />
                        </IconButton>
                      </React.Fragment>
                    ),
                  }}
                  sx={{
                    gridColumn: "span 3",
                    "& .Mui-focused": {
                      color: "#f5079e !important",
                      input: {
                        color: "#0ba2de !important",
                      },
                    },
                    "& .Mui-focused.Mui-error": {
                      color: "#f44336 !important",
                    },
                    "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:before":
                      {
                        borderBottom: "2px solid #0ba2de !important",
                      },
                    "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root:after":
                      {
                        borderBottom: "2px solid #f5079e !important",
                      },
                    "& .Mui-error.css-1rv476z-MuiInputBase-input-MuiFilledInput-input":
                      {
                        color: "#f5079e !important",
                      },
                    "& .css-u7c0k7-MuiInputBase-root-MuiFilledInput-root.Mui-error:after":
                      {
                        borderBottom: "#f44336 !important",
                      },
                  }}
                />
                <Box
                  display="flex"
                  sx={{
                    pb: 3,
                    gap: 3,
                    gridColumn: "span 2",
                  }}>
                  <Button
                    size="small"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    // onClick={(e) => handleFormSubmit(e, values.title)}
                    disabled={uploadLoading || !values.title || !file}>
                    {uploadLoading ? "UPLOADING" : "UPLOAD"}
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => removeFile(resetForm)}
                    disabled={uploadLoading || (!values.title && !file)}>
                    CANCEL
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <Typography
        color="#0ba2de"
        fontWeight="bold"
        fontSize="16px"
        mb="30px"
        ml="20px">
        LIST OF UPLOADED RESOURCES
      </Typography>
      <Stack sx={{ ml: 10 }} direction="row" spacing={31}>
        <Typography color="#0ba2de" fontStyle="italic" variant="h6">
          Title
        </Typography>

        <Typography color="#0ba2de" fontStyle="italic" variant="h6">
          Action
        </Typography>
      </Stack>
      {/* {loading && !resources.id && "Loading..."} */}
      <Box maxHeight="500px" overflow="auto" maxWidth="650px">
        {!loading && (
          <List sx={{ maxWidth: "370px", ml: 10 }}>
            {resources.length ? (
              <TransitionGroup>
                {resources.map((resource) => (
                  <Collapse key={resource.id}>
                    {renderItem({
                      resource,
                      updateConfirmDialog,
                      action,
                      handleDownload,
                    })}
                  </Collapse>
                ))}
              </TransitionGroup>
            ) : (
              "No data"
            )}
          </List>
        )}
      </Box>
    </Box>
  );
};
const initialValues = {
  title: "",
  // password: "",
};
export default Resource;
