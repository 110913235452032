import fetchData from "./utils/fetchData";

const url = process.env.REACT_APP_SERVER_PROURL + "designation";
export const getDesignations = async (token, dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, method: "GET", token: token },
    dispatch
  );
  dispatch({ type: "END_LOADING" });
  if (result) {
    dispatch({
      type: "UPDATE_DESIGNATIONS",
      payload: result,
    });
    return result;
  }
};

export const addDesignations = async (
  token,
  designation,
  dispatch,
  setSuccess
) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData(
    { url, body: designation, token: token },
    dispatch
  );
  if (result) {
    getDesignations(token, dispatch);
    setSuccess(true);
    dispatch({
      type: "RESET_DESIGNATION_FORM_FIELD",
      payload: 0,
    });
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Data added successfully!",
      },
    });
  }
  dispatch({ type: "END_LOADING" });
};

export const updateDesignation = async (id, dispatch, token, designation) => {
  const result = await fetchData(
    {
      url: `${url}/${id}`,
      body: designation,
      method: "PUT",
      token: token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_DESIGNATION",
      payload: {
        id: id,
        data: result,
      },
    });

    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Data updated Successfully",
      },
    });
  }
};
